@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

.background {
  background-image: url('~@Styles/images/main-page-background.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}
