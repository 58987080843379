@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .titleLoading {
    .fakeWord {
      height: 4.5rem;
    }
  }
}

.titleLoading {
  display: flex;
  flex-wrap: wrap;

  .fakeWord {
    @include fakeDivCommons;

    height: 2rem;
    margin: 0.25rem 1rem 0.25rem 0;
  }
}
