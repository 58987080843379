@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .description {
    p {
      font-size: 1.25rem;

      &.firstParagraph {
        font-size: 1.5rem;
      }
    }
  }
}

.description {
  @include topBottomMargins;
  color: $descriptionBrick_textColor;
  margin: 1rem 0;

  p {
    font-weight: $default_fontWeight;
    hyphens: auto;
    line-height: 1.5;
    margin-bottom: 2rem;
    text-align: justify;

    &.firstParagraph {
      font-size: 1.25rem;
      font-weight: $default_fontBoldWeight;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

:global(.dark) {
  .description {
    color: $descriptionBrick_textColorDark;
  }
}
