@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';

.value {
  color: $reusable_placeFont;
  font-size: 0.875rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: $reusable_placeLink;
  }
}

.fakePlace {
  @include fakeDivCommons();
  height: 0.875rem;
  margin: 0 0 1.5rem;
  width: 80%;
}
