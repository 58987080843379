@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop),
:global(.bigDesktop) {
  .simpleCheckbox {
    flex-direction: row;

    .button {
      width: 8rem;
    }
  }
}

.simpleCheckbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  .label {
    @include checkbox;

    font-family: $default_font;
    font-size: 1.5rem;
    font-weight: bolder;
    line-height: 1.7rem;
    margin: auto 0;
    overflow: hidden;
  }

  .button {
    @include primaryButton;
    font-weight: bolder;
    line-height: 1.7rem;
    margin: auto 1.5rem auto 0;
    padding: 0.625rem 1.5rem;
    width: 100%;
  }

  .whiteButton {
    background: $happening_spaces_buttonBackgrond;
    border-color: $happening_spaces_buttonBorder;
    color: $happening_spaces_buttonText;

    &:hover {
      color: $happening_spaces_buttonBackgrond;
    }
  }
}
