@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.formWrapper {
  min-height: 70vh;

  h2.pageTitle {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .fieldDescription {
    font-size: 2rem;
    font-weight: $default_fontBoldWeight;
  }

  .label {
    display: inline-block;
    font-size: 0.875rem;
    max-width: 22rem;
    position: relative;
    width: 100%;
  }

  .submitButton {
    @include primaryButton();
    @include disabled(0.5);

    font-size: 1.125rem;
    font-weight: bold;
    margin-top: 3.75rem;
    max-width: 22rem;
    width: 100%;
  }

  .tip {
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
}
