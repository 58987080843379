@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going),
:global(.sbm) {
  .eventDetails {
    .content {
      padding: 1.5rem;

      .title {
        font-weight: 600;
      }

      .infoBox {
        .info {
          font-weight: 500;
        }
      }
    }
  }
}

.eventDetails {
  border-radius: 1rem 0 0 0;
  margin-bottom: 2.25rem;

  & > div {
    margin-bottom: 0;
  }

  .content {
    padding: 1.5rem;

    .title {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .infoBox {
      font-size: 1rem;
      font-weight: bold;

      .info {
        font-size: inherit;
        margin-bottom: 0.625rem;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          border-bottom: $default_borderUnderline $event_locationOthersLinkFont;
          padding-bottom: 0.15rem;
        }
      }
    }
  }
}
