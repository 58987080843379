@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .content {
    border-radius: 0 0 0.5rem 0;
    box-shadow: $eventBox_summaryShadow;
    padding: 1.5rem 2rem;
  }
}

.fakeImage {
  @include fakeDivCommons();

  border-radius: 1rem 0 0 0;
  display: block;
  width: 100%;
}

.content {
  background: $eventBox_summaryContentBackground;
  padding: 1.5rem 2rem 0;

  .fakeTitle {
    @include fakeDivCommons();

    height: 1.75rem;
    margin-bottom: 1.5rem;
    width: 80%;
  }

  .infoBox {
    .fakeInfo {
      @include fakeDivCommons();

      height: 1.125rem;
      margin-bottom: 0.625rem;
      width: 50%;

      &:last-child {
        margin-bottom: 0;
        width: 60%;
      }
    }
  }
}
