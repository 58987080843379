@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .error {
    margin: 7.5rem 0;

    .content {
      margin-bottom: 3rem;
      margin-top: 15rem;

      h2 {
        font-size: 3.75rem;
      }
    }
  }

  .tryAgain {
    h3 {
      font-size: 2.25rem;
    }
  }
}

.error {
  box-shadow: $errors_errorShadow;
  margin: 3.5rem 0;
  overflow: hidden;
  width: 100%;

  &.withApostrophe {
    &:before {
      background-image: url('~@Styles/images/empik-apostrophe.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 5rem;
      left: 0;
      margin-top: -2.5rem;
      position: absolute;
      width: 4.125rem;
    }
  }

  .content {
    margin-bottom: 4rem;
    margin-top: 14rem;
    position: relative;
    text-align: center;
    width: 100%;

    h2 {
      font-size: 1.5rem;
      position: relative;
    }

    &:before {
      @if ($app == 'going') {
        background-image: url('~@Styles/images/going-sad-face.svg');
      } @else {
        background-image: url('~@Styles/images/empik-sad-face.svg');
      }
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      display: block;
      height: 6.875rem;
      left: 50%;
      position: absolute;
      top: -11rem;
      transform: translateX(-50%);
      width: 6.875rem;
    }
  }
}

.tryAgain {
  line-height: 1.5rem;
  margin: 3rem 0 6rem;
  text-align: center;
  width: 100%;

  h3 {
    font-size: 1.25rem;
  }
}
