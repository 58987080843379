@import '@Styles/defs/colors.scss';

.mainWrapper {
  align-items: center;
  display: flex;
}
.wrapperSlider {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wrapper {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 0 2rem;
  position: relative;
  width: 100%;

  & > div {
    background-color: transparent;
    height: 4rem;
    width: 100%;

    &:after {
      background: $slider_lineColor;
      content: '';
      display: block;
      height: 0.25rem;
      left: 0;
      margin-top: -0.125rem;
      position: absolute;
      right: 0;
      top: 50%;
    }

    &:hover {
      cursor: pointer;
    }

    & > div {
      background-color: transparent;
      height: 4rem;
      transform: translateX(-1.25rem);
      width: 4rem;

      &:after {
        background: $slider_lineColor;
        box-shadow: none;
        left: 1rem;
        top: 1rem;
      }
    }
  }
}

.slider {
  position: sticky;

  & > div:nth-child(even),
  & > div:nth-child(odd) {
    position: absolute;
  }

  & > div:first-child {
    background: transparent;
  }

  & > div:nth-child(4) {
    background: $slider_dotColor;
    border: none;
    border-radius: 1rem;
    height: 1rem;
    top: 1.5rem;
    width: 1rem;
    z-index: 3;
  }
}

.value {
  box-shadow: none;
  color: $slider_textColor;
  cursor: pointer;
  font-size: 3rem;
  font-weight: bold;
  height: 100%;
  line-height: 4rem;
  outline: none;
  padding-left: 1.5rem;
  text-align: right;
  width: 7rem;

  &:active {
    box-shadow: none;
  }
}

.additionalButton {
  background: $slider_dotColor;
  border-radius: 0.25rem;
  color: $slider_buttonsText;
  font-size: 2rem;
  font-weight: bold;
  height: 100%;
  line-height: 2rem;
  padding-bottom: 0.25rem;
  text-align: center;
  user-select: none;
  width: 3rem;

  &:hover {
    cursor: pointer;
    font-size: 2.5rem;
  }
}

:global(.mobile) {
  .slider {
    pointer-events: none;

    & > div:nth-child(4) {
      pointer-events: auto;
    }
  }
}
