@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .container {
    display: grid;
    grid-template-columns: 4fr 2fr 3fr;
    grid-template-rows: 100%;
    padding: 1.5rem 1.875rem;

    .productInfo {
      align-items: center;
      flex-direction: row;
      flex-grow: 2;

      .titleContainer {
        margin-bottom: 0;
        margin-left: 1.25rem;
      }
    }

    .productPrice {
      align-self: center;
      margin-bottom: 0;
      text-align: center;
    }

    .buttons {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
}

.container {
  background-color: $default_white;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding: 1.25rem;

  .productInfo {
    display: flex;
    flex-direction: column-reverse;

    .titleContainer {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 1.5rem;

      .productName {
        font-size: 1.25rem;
        font-weight: $default_fontBoldWeight;
        margin-bottom: 0.5rem;
      }
    }
  }
  .productImage {
    justify-content: flex-start;
    max-height: 3rem;
    max-width: 3rem;
    object-fit: contain;
  }

  .productPrice {
    font-size: 1.375rem;
    font-weight: $default_fontBoldWeight;
    margin-bottom: 1.5rem;

    .priceValue {
      font-size: 2rem;
    }
  }
}
