:global {
  .formio-form {
    :root {
      --blue: #007bff;
      --indigo: #6610f2;
      --purple: #6f42c1;
      --pink: #e83e8c;
      --red: #dc3545;
      --orange: #fd7e14;
      --yellow: #ffc107;
      --green: #28a745;
      --teal: #20c997;
      --cyan: #17a2b8;
      --white: #fff;
      --gray: #6c757d;
      --gray-dark: #343a40;
      --primary: #007bff;
      --secondary: #6c757d;
      --success: #28a745;
      --info: #17a2b8;
      --warning: #ffc107;
      --danger: #dc3545;
      --light: #f8f9fa;
      --dark: #343a40;
      --breakpoint-xs: 0;
      --breakpoint-sm: 576px;
      --breakpoint-md: 768px;
      --breakpoint-lg: 992px;
      --breakpoint-xl: 1200px;
      --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
      --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
        'Liberation Mono', 'Courier New', monospace;
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    [tabindex='-1']:focus:not(:focus-visible) {
      outline: 0 !important;
    }

    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    p {
      margin-bottom: 1rem;
      margin-top: 0;
    }

    abbr[title],
    abbr[data-original-title] {
      border-bottom: 0;
      cursor: help;
      text-decoration: underline;
      text-decoration: underline dotted;
      text-decoration-skip-ink: none;
    }

    address {
      font-style: normal;
      line-height: inherit;
      margin-bottom: 1rem;
    }

    ol,
    ul,
    dl {
      margin-bottom: 1rem;
      margin-top: 0;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
      margin-bottom: 0;
    }

    dt {
      font-weight: 700;
    }

    dd {
      margin-bottom: 0.5rem;
      margin-left: 0;
    }

    blockquote {
      margin: 0 0 1rem;
    }

    b,
    strong {
      font-weight: bolder;
    }

    small {
      font-size: 80%;
    }

    sub,
    sup {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }

    sub {
      bottom: -0.25em;
    }

    sup {
      top: -0.5em;
    }

    a {
      background-color: transparent;
      color: #007bff;
      text-decoration: none;
    }

    a:hover {
      color: #0056b3;
      text-decoration: underline;
    }

    a:not([href]):not([class]) {
      color: inherit;
      text-decoration: none;
    }

    a:not([href]):not([class]):hover {
      color: inherit;
      text-decoration: none;
    }

    pre,
    code,
    kbd,
    samp {
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
        'Courier New', monospace;
      font-size: 1em;
    }

    pre {
      margin-bottom: 1rem;
      margin-top: 0;
      overflow: auto;
      -ms-overflow-style: scrollbar;
    }

    figure {
      margin: 0 0 1rem;
    }

    img {
      border-style: none;
      vertical-align: middle;
    }

    svg {
      overflow: hidden;
      vertical-align: middle;
    }

    table {
      border-collapse: collapse;
    }

    caption {
      caption-side: bottom;
      color: #6c757d;
      padding-bottom: 0.75rem;
      padding-top: 0.75rem;
      text-align: left;
    }

    th {
      text-align: inherit;
      text-align: -webkit-match-parent;
    }

    label {
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    button {
      border-radius: 0;
    }

    button:focus {
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color;
    }

    input,
    button,
    select,
    optgroup,
    textarea {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      margin: 0;
    }

    button,
    input {
      overflow: visible;
    }

    button,
    select {
      text-transform: none;
    }

    [role='button'] {
      cursor: pointer;
    }

    select {
      word-wrap: normal;
    }

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
      -webkit-appearance: button;
    }

    button:not(:disabled),
    [type='button']:not(:disabled),
    [type='reset']:not(:disabled),
    [type='submit']:not(:disabled) {
      cursor: pointer;
    }

    button::-moz-focus-inner,
    [type='button']::-moz-focus-inner,
    [type='reset']::-moz-focus-inner,
    [type='submit']::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }

    input[type='radio'],
    input[type='checkbox'] {
      box-sizing: border-box;
      padding: 0;
    }

    textarea {
      overflow: auto;
      resize: vertical;
    }

    fieldset {
      border: 0;
      margin: 0;
      min-width: 0;
      padding: 0;
    }

    legend {
      color: inherit;
      display: block;
      font-size: 1.5rem;
      line-height: inherit;
      margin-bottom: 0.5rem;
      max-width: 100%;
      padding: 0;
      white-space: normal;
      width: 100%;
    }

    progress {
      vertical-align: baseline;
    }

    [type='number']::-webkit-inner-spin-button,
    [type='number']::-webkit-outer-spin-button {
      height: auto;
    }

    [type='search'] {
      -webkit-appearance: none;
      outline-offset: -2px;
    }

    [type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit;
    }

    output {
      display: inline-block;
    }

    summary {
      cursor: pointer;
      display: list-item;
    }

    template {
      display: none;
    }

    [hidden] {
      display: none !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }

    h1,
    .h1 {
      font-size: 2.5rem;
    }

    h2,
    .h2 {
      font-size: 2rem;
    }

    h3,
    .h3 {
      font-size: 1.75rem;
    }

    h4,
    .h4 {
      font-size: 1.5rem;
    }

    h5,
    .h5 {
      font-size: 1.25rem;
    }

    h6,
    .h6 {
      font-size: 1rem;
    }

    .lead {
      font-size: 1.25rem;
      font-weight: 300;
    }

    .display-1 {
      font-size: 6rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-2 {
      font-size: 5.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-3 {
      font-size: 4.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-4 {
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    hr {
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    small,
    .small {
      font-size: 80%;
      font-weight: 400;
    }

    mark,
    .mark {
      background-color: #fcf8e3;
      padding: 0.2em;
    }

    .list-unstyled {
      list-style: none;
      padding-left: 0;
    }

    .list-inline {
      list-style: none;
      padding-left: 0;
    }

    .list-inline-item {
      display: inline-block;
    }

    .list-inline-item:not(:last-child) {
      margin-right: 0.5rem;
    }

    .initialism {
      font-size: 90%;
      text-transform: uppercase;
    }

    .blockquote {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }

    .blockquote-footer {
      color: #6c757d;
      display: block;
      font-size: 80%;
    }

    .blockquote-footer::before {
      content: '— ';
    }

    .img-fluid {
      height: auto;
      max-width: 100%;
    }

    .img-thumbnail {
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 0.25rem;
      height: auto;
      max-width: 100%;
      padding: 0.25rem;
    }

    .figure {
      display: inline-block;
    }

    .figure-img {
      line-height: 1;
      margin-bottom: 0.5rem;
    }

    .figure-caption {
      color: #6c757d;
      font-size: 90%;
    }

    code {
      color: #e83e8c;
      font-size: 87.5%;
      word-wrap: break-word;
    }

    a > code {
      color: inherit;
    }

    kbd {
      background-color: #212529;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 87.5%;
      padding: 0.2rem 0.4rem;
    }

    kbd kbd {
      font-size: 100%;
      font-weight: 700;
      padding: 0;
    }

    pre {
      color: #212529;
      display: block;
      font-size: 87.5%;
    }

    pre code {
      color: inherit;
      font-size: inherit;
      word-break: normal;
    }

    .pre-scrollable {
      max-height: 340px;
      overflow-y: scroll;
    }

    .container,
    .container-fluid,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm {
      margin-left: auto;
      margin-right: auto;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
    }

    @media (min-width: 576px) {
      .container-sm,
      .container {
        max-width: 540px;
      }
    }
    @media (min-width: 768px) {
      .container-md,
      .container-sm,
      .container {
        max-width: 720px;
      }
    }
    @media (min-width: 992px) {
      .container-lg,
      .container-md,
      .container-sm,
      .container {
        max-width: 960px;
      }
    }
    @media (min-width: 1200px) {
      .container-xl,
      .container-lg,
      .container-md,
      .container-sm,
      .container {
        max-width: 1140px;
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }

    .no-gutters {
      margin-left: 0;
      margin-right: 0;
    }

    .no-gutters > .col,
    .no-gutters > [class*='col-'] {
      padding-left: 0;
      padding-right: 0;
    }

    .col-xl,
    .col-xl-auto,
    .col-xl-12,
    .col-xl-11,
    .col-xl-10,
    .col-xl-9,
    .col-xl-8,
    .col-xl-7,
    .col-xl-6,
    .col-xl-5,
    .col-xl-4,
    .col-xl-3,
    .col-xl-2,
    .col-xl-1,
    .col-lg,
    .col-lg-auto,
    .col-lg-12,
    .col-lg-11,
    .col-lg-10,
    .col-lg-9,
    .col-lg-8,
    .col-lg-7,
    .col-lg-6,
    .col-lg-5,
    .col-lg-4,
    .col-lg-3,
    .col-lg-2,
    .col-lg-1,
    .col-md,
    .col-md-auto,
    .col-md-12,
    .col-md-11,
    .col-md-10,
    .col-md-9,
    .col-md-8,
    .col-md-7,
    .col-md-6,
    .col-md-5,
    .col-md-4,
    .col-md-3,
    .col-md-2,
    .col-md-1,
    .col-sm,
    .col-sm-auto,
    .col-sm-12,
    .col-sm-11,
    .col-sm-10,
    .col-sm-9,
    .col-sm-8,
    .col-sm-7,
    .col-sm-6,
    .col-sm-5,
    .col-sm-4,
    .col-sm-3,
    .col-sm-2,
    .col-sm-1,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-10,
    .col-9,
    .col-8,
    .col-7,
    .col-6,
    .col-5,
    .col-4,
    .col-3,
    .col-2,
    .col-1 {
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      width: 100%;
    }

    .col {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .row-cols-1 > * {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .row-cols-2 > * {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .row-cols-3 > * {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    .row-cols-4 > * {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .row-cols-5 > * {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .row-cols-6 > * {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }

    .col-auto {
      flex: 0 0 auto;
      max-width: 100%;
      width: auto;
    }

    .col-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }

    .col-2 {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }

    .col-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    .col-5 {
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }

    .col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-7 {
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }

    .col-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }

    .col-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-10 {
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }

    .col-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }

    .col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .order-first {
      order: -1;
    }

    .order-last {
      order: 13;
    }

    .order-0 {
      order: 0;
    }

    .order-1 {
      order: 1;
    }

    .order-2 {
      order: 2;
    }

    .order-3 {
      order: 3;
    }

    .order-4 {
      order: 4;
    }

    .order-5 {
      order: 5;
    }

    .order-6 {
      order: 6;
    }

    .order-7 {
      order: 7;
    }

    .order-8 {
      order: 8;
    }

    .order-9 {
      order: 9;
    }

    .order-10 {
      order: 10;
    }

    .order-11 {
      order: 11;
    }

    .order-12 {
      order: 12;
    }

    .offset-1 {
      margin-left: 8.3333333333%;
    }

    .offset-2 {
      margin-left: 16.6666666667%;
    }

    .offset-3 {
      margin-left: 25%;
    }

    .offset-4 {
      margin-left: 33.3333333333%;
    }

    .offset-5 {
      margin-left: 41.6666666667%;
    }

    .offset-6 {
      margin-left: 50%;
    }

    .offset-7 {
      margin-left: 58.3333333333%;
    }

    .offset-8 {
      margin-left: 66.6666666667%;
    }

    .offset-9 {
      margin-left: 75%;
    }

    .offset-10 {
      margin-left: 83.3333333333%;
    }

    .offset-11 {
      margin-left: 91.6666666667%;
    }

    @media (min-width: 576px) {
      .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .row-cols-sm-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .row-cols-sm-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .row-cols-sm-3 > * {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
      }

      .row-cols-sm-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .row-cols-sm-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
      }

      .row-cols-sm-6 > * {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
      }

      .col-sm-auto {
        flex: 0 0 auto;
        max-width: 100%;
        width: auto;
      }

      .col-sm-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
      }

      .col-sm-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
      }

      .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .col-sm-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
      }

      .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
      }

      .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .col-sm-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
      }

      .col-sm-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
      }

      .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
      }

      .col-sm-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
      }

      .col-sm-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
      }

      .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .order-sm-first {
        order: -1;
      }

      .order-sm-last {
        order: 13;
      }

      .order-sm-0 {
        order: 0;
      }

      .order-sm-1 {
        order: 1;
      }

      .order-sm-2 {
        order: 2;
      }

      .order-sm-3 {
        order: 3;
      }

      .order-sm-4 {
        order: 4;
      }

      .order-sm-5 {
        order: 5;
      }

      .order-sm-6 {
        order: 6;
      }

      .order-sm-7 {
        order: 7;
      }

      .order-sm-8 {
        order: 8;
      }

      .order-sm-9 {
        order: 9;
      }

      .order-sm-10 {
        order: 10;
      }

      .order-sm-11 {
        order: 11;
      }

      .order-sm-12 {
        order: 12;
      }

      .offset-sm-0 {
        margin-left: 0;
      }

      .offset-sm-1 {
        margin-left: 8.3333333333%;
      }

      .offset-sm-2 {
        margin-left: 16.6666666667%;
      }

      .offset-sm-3 {
        margin-left: 25%;
      }

      .offset-sm-4 {
        margin-left: 33.3333333333%;
      }

      .offset-sm-5 {
        margin-left: 41.6666666667%;
      }

      .offset-sm-6 {
        margin-left: 50%;
      }

      .offset-sm-7 {
        margin-left: 58.3333333333%;
      }

      .offset-sm-8 {
        margin-left: 66.6666666667%;
      }

      .offset-sm-9 {
        margin-left: 75%;
      }

      .offset-sm-10 {
        margin-left: 83.3333333333%;
      }

      .offset-sm-11 {
        margin-left: 91.6666666667%;
      }
    }
    @media (min-width: 768px) {
      .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .row-cols-md-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .row-cols-md-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .row-cols-md-3 > * {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
      }

      .row-cols-md-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .row-cols-md-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
      }

      .row-cols-md-6 > * {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
      }

      .col-md-auto {
        flex: 0 0 auto;
        max-width: 100%;
        width: auto;
      }

      .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
      }

      .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
      }

      .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
      }

      .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
      }

      .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
      }

      .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
      }

      .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
      }

      .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
      }

      .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
      }

      .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .order-md-first {
        order: -1;
      }

      .order-md-last {
        order: 13;
      }

      .order-md-0 {
        order: 0;
      }

      .order-md-1 {
        order: 1;
      }

      .order-md-2 {
        order: 2;
      }

      .order-md-3 {
        order: 3;
      }

      .order-md-4 {
        order: 4;
      }

      .order-md-5 {
        order: 5;
      }

      .order-md-6 {
        order: 6;
      }

      .order-md-7 {
        order: 7;
      }

      .order-md-8 {
        order: 8;
      }

      .order-md-9 {
        order: 9;
      }

      .order-md-10 {
        order: 10;
      }

      .order-md-11 {
        order: 11;
      }

      .order-md-12 {
        order: 12;
      }

      .offset-md-0 {
        margin-left: 0;
      }

      .offset-md-1 {
        margin-left: 8.3333333333%;
      }

      .offset-md-2 {
        margin-left: 16.6666666667%;
      }

      .offset-md-3 {
        margin-left: 25%;
      }

      .offset-md-4 {
        margin-left: 33.3333333333%;
      }

      .offset-md-5 {
        margin-left: 41.6666666667%;
      }

      .offset-md-6 {
        margin-left: 50%;
      }

      .offset-md-7 {
        margin-left: 58.3333333333%;
      }

      .offset-md-8 {
        margin-left: 66.6666666667%;
      }

      .offset-md-9 {
        margin-left: 75%;
      }

      .offset-md-10 {
        margin-left: 83.3333333333%;
      }

      .offset-md-11 {
        margin-left: 91.6666666667%;
      }
    }
    @media (min-width: 992px) {
      .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .row-cols-lg-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .row-cols-lg-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .row-cols-lg-3 > * {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
      }

      .row-cols-lg-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .row-cols-lg-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
      }

      .row-cols-lg-6 > * {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
      }

      .col-lg-auto {
        flex: 0 0 auto;
        max-width: 100%;
        width: auto;
      }

      .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
      }

      .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
      }

      .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
      }

      .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
      }

      .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
      }

      .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
      }

      .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
      }

      .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
      }

      .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
      }

      .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .order-lg-first {
        order: -1;
      }

      .order-lg-last {
        order: 13;
      }

      .order-lg-0 {
        order: 0;
      }

      .order-lg-1 {
        order: 1;
      }

      .order-lg-2 {
        order: 2;
      }

      .order-lg-3 {
        order: 3;
      }

      .order-lg-4 {
        order: 4;
      }

      .order-lg-5 {
        order: 5;
      }

      .order-lg-6 {
        order: 6;
      }

      .order-lg-7 {
        order: 7;
      }

      .order-lg-8 {
        order: 8;
      }

      .order-lg-9 {
        order: 9;
      }

      .order-lg-10 {
        order: 10;
      }

      .order-lg-11 {
        order: 11;
      }

      .order-lg-12 {
        order: 12;
      }

      .offset-lg-0 {
        margin-left: 0;
      }

      .offset-lg-1 {
        margin-left: 8.3333333333%;
      }

      .offset-lg-2 {
        margin-left: 16.6666666667%;
      }

      .offset-lg-3 {
        margin-left: 25%;
      }

      .offset-lg-4 {
        margin-left: 33.3333333333%;
      }

      .offset-lg-5 {
        margin-left: 41.6666666667%;
      }

      .offset-lg-6 {
        margin-left: 50%;
      }

      .offset-lg-7 {
        margin-left: 58.3333333333%;
      }

      .offset-lg-8 {
        margin-left: 66.6666666667%;
      }

      .offset-lg-9 {
        margin-left: 75%;
      }

      .offset-lg-10 {
        margin-left: 83.3333333333%;
      }

      .offset-lg-11 {
        margin-left: 91.6666666667%;
      }
    }
    @media (min-width: 1200px) {
      .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .row-cols-xl-1 > * {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .row-cols-xl-2 > * {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .row-cols-xl-3 > * {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
      }

      .row-cols-xl-4 > * {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .row-cols-xl-5 > * {
        flex: 0 0 20%;
        max-width: 20%;
      }

      .row-cols-xl-6 > * {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
      }

      .col-xl-auto {
        flex: 0 0 auto;
        max-width: 100%;
        width: auto;
      }

      .col-xl-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
      }

      .col-xl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
      }

      .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .col-xl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
      }

      .col-xl-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
      }

      .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .col-xl-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
      }

      .col-xl-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
      }

      .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
      }

      .col-xl-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
      }

      .col-xl-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
      }

      .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .order-xl-first {
        order: -1;
      }

      .order-xl-last {
        order: 13;
      }

      .order-xl-0 {
        order: 0;
      }

      .order-xl-1 {
        order: 1;
      }

      .order-xl-2 {
        order: 2;
      }

      .order-xl-3 {
        order: 3;
      }

      .order-xl-4 {
        order: 4;
      }

      .order-xl-5 {
        order: 5;
      }

      .order-xl-6 {
        order: 6;
      }

      .order-xl-7 {
        order: 7;
      }

      .order-xl-8 {
        order: 8;
      }

      .order-xl-9 {
        order: 9;
      }

      .order-xl-10 {
        order: 10;
      }

      .order-xl-11 {
        order: 11;
      }

      .order-xl-12 {
        order: 12;
      }

      .offset-xl-0 {
        margin-left: 0;
      }

      .offset-xl-1 {
        margin-left: 8.3333333333%;
      }

      .offset-xl-2 {
        margin-left: 16.6666666667%;
      }

      .offset-xl-3 {
        margin-left: 25%;
      }

      .offset-xl-4 {
        margin-left: 33.3333333333%;
      }

      .offset-xl-5 {
        margin-left: 41.6666666667%;
      }

      .offset-xl-6 {
        margin-left: 50%;
      }

      .offset-xl-7 {
        margin-left: 58.3333333333%;
      }

      .offset-xl-8 {
        margin-left: 66.6666666667%;
      }

      .offset-xl-9 {
        margin-left: 75%;
      }

      .offset-xl-10 {
        margin-left: 83.3333333333%;
      }

      .offset-xl-11 {
        margin-left: 91.6666666667%;
      }
    }

    .table {
      color: #212529;
      margin-bottom: 1rem;
      width: 100%;
    }

    .table th,
    .table td {
      border-top: 1px solid #dee2e6;
      padding: 0.75rem;
      vertical-align: top;
    }

    .table thead th {
      border-bottom: 2px solid #dee2e6;
      vertical-align: bottom;
    }

    .table tbody + tbody {
      border-top: 2px solid #dee2e6;
    }

    .table-sm th,
    .table-sm td {
      padding: 0.3rem;
    }

    .table-bordered {
      border: 1px solid #dee2e6;
    }

    .table-bordered th,
    .table-bordered td {
      border: 1px solid #dee2e6;
    }

    .table-bordered thead th,
    .table-bordered thead td {
      border-bottom-width: 2px;
    }

    .table-borderless th,
    .table-borderless td,
    .table-borderless thead th,
    .table-borderless tbody + tbody {
      border: 0;
    }

    .table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }

    .table-hover tbody tr:hover {
      background-color: rgba(0, 0, 0, 0.075);
      color: #212529;
    }

    .table-primary,
    .table-primary > th,
    .table-primary > td {
      background-color: #b8daff;
    }

    .table-primary th,
    .table-primary td,
    .table-primary thead th,
    .table-primary tbody + tbody {
      border-color: #7abaff;
    }

    .table-hover .table-primary:hover {
      background-color: #9fcdff;
    }

    .table-hover .table-primary:hover > td,
    .table-hover .table-primary:hover > th {
      background-color: #9fcdff;
    }

    .table-secondary,
    .table-secondary > th,
    .table-secondary > td {
      background-color: #d6d8db;
    }

    .table-secondary th,
    .table-secondary td,
    .table-secondary thead th,
    .table-secondary tbody + tbody {
      border-color: #b3b7bb;
    }

    .table-hover .table-secondary:hover {
      background-color: #c8cbcf;
    }

    .table-hover .table-secondary:hover > td,
    .table-hover .table-secondary:hover > th {
      background-color: #c8cbcf;
    }

    .table-success,
    .table-success > th,
    .table-success > td {
      background-color: #c3e6cb;
    }

    .table-success th,
    .table-success td,
    .table-success thead th,
    .table-success tbody + tbody {
      border-color: #8fd19e;
    }

    .table-hover .table-success:hover {
      background-color: #b1dfbb;
    }

    .table-hover .table-success:hover > td,
    .table-hover .table-success:hover > th {
      background-color: #b1dfbb;
    }

    .table-info,
    .table-info > th,
    .table-info > td {
      background-color: #bee5eb;
    }

    .table-info th,
    .table-info td,
    .table-info thead th,
    .table-info tbody + tbody {
      border-color: #86cfda;
    }

    .table-hover .table-info:hover {
      background-color: #abdde5;
    }

    .table-hover .table-info:hover > td,
    .table-hover .table-info:hover > th {
      background-color: #abdde5;
    }

    .table-warning,
    .table-warning > th,
    .table-warning > td {
      background-color: #ffeeba;
    }

    .table-warning th,
    .table-warning td,
    .table-warning thead th,
    .table-warning tbody + tbody {
      border-color: #ffdf7e;
    }

    .table-hover .table-warning:hover {
      background-color: #ffe8a1;
    }

    .table-hover .table-warning:hover > td,
    .table-hover .table-warning:hover > th {
      background-color: #ffe8a1;
    }

    .table-danger,
    .table-danger > th,
    .table-danger > td {
      background-color: #f5c6cb;
    }

    .table-danger th,
    .table-danger td,
    .table-danger thead th,
    .table-danger tbody + tbody {
      border-color: #ed969e;
    }

    .table-hover .table-danger:hover {
      background-color: #f1b0b7;
    }

    .table-hover .table-danger:hover > td,
    .table-hover .table-danger:hover > th {
      background-color: #f1b0b7;
    }

    .table-light,
    .table-light > th,
    .table-light > td {
      background-color: #fdfdfe;
    }

    .table-light th,
    .table-light td,
    .table-light thead th,
    .table-light tbody + tbody {
      border-color: #fbfcfc;
    }

    .table-hover .table-light:hover {
      background-color: #ececf6;
    }

    .table-hover .table-light:hover > td,
    .table-hover .table-light:hover > th {
      background-color: #ececf6;
    }

    .table-dark,
    .table-dark > th,
    .table-dark > td {
      background-color: #c6c8ca;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #95999c;
    }

    .table-hover .table-dark:hover {
      background-color: #b9bbbe;
    }

    .table-hover .table-dark:hover > td,
    .table-hover .table-dark:hover > th {
      background-color: #b9bbbe;
    }

    .table-active,
    .table-active > th,
    .table-active > td {
      background-color: rgba(0, 0, 0, 0.075);
    }

    .table-hover .table-active:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }

    .table-hover .table-active:hover > td,
    .table-hover .table-active:hover > th {
      background-color: rgba(0, 0, 0, 0.075);
    }

    .table .thead-dark th {
      background-color: #343a40;
      border-color: #454d55;
      color: #fff;
    }

    .table .thead-light th {
      background-color: #e9ecef;
      border-color: #dee2e6;
      color: #495057;
    }

    .table-dark {
      background-color: #343a40;
      color: #fff;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th {
      border-color: #454d55;
    }

    .table-dark.table-bordered {
      border: 0;
    }

    .table-dark.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.05);
    }

    .table-dark.table-hover tbody tr:hover {
      background-color: rgba(255, 255, 255, 0.075);
      color: #fff;
    }

    @media (max-width: 575.98px) {
      .table-responsive-sm {
        display: block;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        width: 100%;
      }
      .table-responsive-sm > .table-bordered {
        border: 0;
      }
    }
    @media (max-width: 767.98px) {
      .table-responsive-md {
        display: block;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        width: 100%;
      }
      .table-responsive-md > .table-bordered {
        border: 0;
      }
    }
    @media (max-width: 991.98px) {
      .table-responsive-lg {
        display: block;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        width: 100%;
      }
      .table-responsive-lg > .table-bordered {
        border: 0;
      }
    }
    @media (max-width: 1199.98px) {
      .table-responsive-xl {
        display: block;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        width: 100%;
      }
      .table-responsive-xl > .table-bordered {
        border: 0;
      }
    }

    .table-responsive {
      display: block;
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;
      width: 100%;
    }

    .table-responsive > .table-bordered {
      border: 0;
    }

    .form-control {
      background-clip: padding-box;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      color: #495057;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      height: calc(1.5em + 0.75rem + 2px);
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 100%;
    }

    @media (prefers-reduced-motion: reduce) {
      .form-control {
        transition: none;
      }
    }

    .form-control::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    .form-control:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #495057;
    }

    .form-control:focus {
      background-color: #fff;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      color: #495057;
      outline: 0;
    }

    .form-control::placeholder {
      color: #6c757d;
      opacity: 1;
    }

    .form-control:disabled,
    .form-control[readonly] {
      background-color: #e9ecef;
      opacity: 1;
    }

    input[type='date'].form-control,
    input[type='time'].form-control,
    input[type='datetime-local'].form-control,
    input[type='month'].form-control {
      appearance: none;
    }

    select.form-control:focus::-ms-value {
      background-color: #fff;
      color: #495057;
    }

    .form-control-file,
    .form-control-range {
      display: block;
      width: 100%;
    }

    .col-form-label {
      font-size: inherit;
      line-height: 1.5;
      margin-bottom: 0;
      padding-bottom: calc(0.375rem + 1px);
      padding-top: calc(0.375rem + 1px);
    }

    .col-form-label-lg {
      font-size: 1.25rem;
      line-height: 1.5;
      padding-bottom: calc(0.5rem + 1px);
      padding-top: calc(0.5rem + 1px);
    }

    .col-form-label-sm {
      font-size: 0.875rem;
      line-height: 1.5;
      padding-bottom: calc(0.25rem + 1px);
      padding-top: calc(0.25rem + 1px);
    }

    .form-control-plaintext {
      background-color: transparent;
      border: solid transparent;
      border-width: 1px 0;
      color: #212529;
      display: block;
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 0;
      padding: 0.375rem 0;
      width: 100%;
    }

    .form-control-plaintext.form-control-sm,
    .form-control-plaintext.form-control-lg {
      padding-left: 0;
      padding-right: 0;
    }

    .form-control-sm {
      border-radius: 0.2rem;
      font-size: 0.875rem;
      height: calc(1.5em + 0.5rem + 2px);
      line-height: 1.5;
      padding: 0.25rem 0.5rem;
    }

    .form-control-lg {
      border-radius: 0.3rem;
      font-size: 1.25rem;
      height: calc(1.5em + 1rem + 2px);
      line-height: 1.5;
      padding: 0.5rem 1rem;
    }

    select.form-control[size],
    select.form-control[multiple] {
      height: auto;
    }

    textarea.form-control {
      height: auto;
    }

    .form-group {
      margin-bottom: 1rem;
    }

    .form-text {
      display: block;
      margin-top: 0.25rem;
    }

    .form-row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;
    }

    .form-row > .col,
    .form-row > [class*='col-'] {
      padding-left: 5px;
      padding-right: 5px;
    }

    .form-check {
      display: block;
      padding-left: 1.25rem;
      position: relative;
    }

    .form-check-input {
      margin-left: -1.25rem;
      margin-top: 0.3rem;
      position: absolute;
    }

    .form-check-input[disabled] ~ .form-check-label,
    .form-check-input:disabled ~ .form-check-label {
      color: #6c757d;
    }

    .form-check-label {
      margin-bottom: 0;
    }

    .form-check-inline {
      align-items: center;
      display: inline-flex;
      margin-right: 0.75rem;
      padding-left: 0;
    }

    .form-check-inline .form-check-input {
      margin-left: 0;
      margin-right: 0.3125rem;
      margin-top: 0;
      position: static;
    }

    .valid-feedback {
      color: #28a745;
      display: none;
      font-size: 80%;
      margin-top: 0.25rem;
      width: 100%;
    }

    .valid-tooltip {
      background-color: rgba(40, 167, 69, 0.9);
      border-radius: 0.25rem;
      color: #fff;
      display: none;
      font-size: 0.875rem;
      left: 0;
      line-height: 1.5;
      margin-top: 0.1rem;
      max-width: 100%;
      padding: 0.25rem 0.5rem;
      position: absolute;
      top: 100%;
      z-index: 5;
    }

    .was-validated :valid ~ .valid-feedback,
    .was-validated :valid ~ .valid-tooltip,
    .is-valid ~ .valid-feedback,
    .is-valid ~ .valid-tooltip {
      display: block;
    }

    .was-validated .form-control:valid,
    .form-control.is-valid {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-position: right calc(0.375em + 0.1875rem) center;
      background-repeat: no-repeat;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      border-color: #28a745;
      padding-right: calc(1.5em + 0.75rem);
    }

    .was-validated .form-control:valid:focus,
    .form-control.is-valid:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }

    .was-validated textarea.form-control:valid,
    textarea.form-control.is-valid {
      background-position: top calc(0.375em + 0.1875rem) right
        calc(0.375em + 0.1875rem);
      padding-right: calc(1.5em + 0.75rem);
    }

    .was-validated .custom-select:valid,
    .custom-select.is-valid {
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
          no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
          #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
          calc(0.75em + 0.375rem);
      border-color: #28a745;
      padding-right: calc(0.75em + 2.3125rem);
    }

    .was-validated .custom-select:valid:focus,
    .custom-select.is-valid:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }

    .was-validated .form-check-input:valid ~ .form-check-label,
    .form-check-input.is-valid ~ .form-check-label {
      color: #28a745;
    }

    .was-validated .form-check-input:valid ~ .valid-feedback,
    .was-validated .form-check-input:valid ~ .valid-tooltip,
    .form-check-input.is-valid ~ .valid-feedback,
    .form-check-input.is-valid ~ .valid-tooltip {
      display: block;
    }

    .was-validated .custom-control-input:valid ~ .custom-control-label,
    .custom-control-input.is-valid ~ .custom-control-label {
      color: #28a745;
    }

    .was-validated .custom-control-input:valid ~ .custom-control-label::before,
    .custom-control-input.is-valid ~ .custom-control-label::before {
      border-color: #28a745;
    }

    .was-validated
      .custom-control-input:valid:checked
      ~ .custom-control-label::before,
    .custom-control-input.is-valid:checked ~ .custom-control-label::before {
      background-color: #34ce57;
      border-color: #34ce57;
    }

    .was-validated
      .custom-control-input:valid:focus
      ~ .custom-control-label::before,
    .custom-control-input.is-valid:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }

    .was-validated
      .custom-control-input:valid:focus:not(:checked)
      ~ .custom-control-label::before,
    .custom-control-input.is-valid:focus:not(:checked)
      ~ .custom-control-label::before {
      border-color: #28a745;
    }

    .was-validated .custom-file-input:valid ~ .custom-file-label,
    .custom-file-input.is-valid ~ .custom-file-label {
      border-color: #28a745;
    }

    .was-validated .custom-file-input:valid:focus ~ .custom-file-label,
    .custom-file-input.is-valid:focus ~ .custom-file-label {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }

    .invalid-feedback {
      color: #dc3545;
      display: none;
      font-size: 80%;
      margin-top: 0.25rem;
      width: 100%;
    }

    .invalid-tooltip {
      background-color: rgba(220, 53, 69, 0.9);
      border-radius: 0.25rem;
      color: #fff;
      display: none;
      font-size: 0.875rem;
      left: 0;
      line-height: 1.5;
      margin-top: 0.1rem;
      max-width: 100%;
      padding: 0.25rem 0.5rem;
      position: absolute;
      top: 100%;
      z-index: 5;
    }

    .was-validated :invalid ~ .invalid-feedback,
    .was-validated :invalid ~ .invalid-tooltip,
    .is-invalid ~ .invalid-feedback,
    .is-invalid ~ .invalid-tooltip {
      display: block;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-position: right calc(0.375em + 0.1875rem) center;
      background-repeat: no-repeat;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
    }

    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }

    .was-validated textarea.form-control:invalid,
    textarea.form-control.is-invalid {
      background-position: top calc(0.375em + 0.1875rem) right
        calc(0.375em + 0.1875rem);
      padding-right: calc(1.5em + 0.75rem);
    }

    .was-validated .custom-select:invalid,
    .custom-select.is-invalid {
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
          no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
          #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
          calc(0.75em + 0.375rem);
      border-color: #dc3545;
      padding-right: calc(0.75em + 2.3125rem);
    }

    .was-validated .custom-select:invalid:focus,
    .custom-select.is-invalid:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }

    .was-validated .form-check-input:invalid ~ .form-check-label,
    .form-check-input.is-invalid ~ .form-check-label {
      color: #dc3545;
    }

    .was-validated .form-check-input:invalid ~ .invalid-feedback,
    .was-validated .form-check-input:invalid ~ .invalid-tooltip,
    .form-check-input.is-invalid ~ .invalid-feedback,
    .form-check-input.is-invalid ~ .invalid-tooltip {
      display: block;
    }

    .was-validated .custom-control-input:invalid ~ .custom-control-label,
    .custom-control-input.is-invalid ~ .custom-control-label {
      color: #dc3545;
    }

    .was-validated
      .custom-control-input:invalid
      ~ .custom-control-label::before,
    .custom-control-input.is-invalid ~ .custom-control-label::before {
      border-color: #dc3545;
    }

    .was-validated
      .custom-control-input:invalid:checked
      ~ .custom-control-label::before,
    .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
      background-color: #e4606d;
      border-color: #e4606d;
    }

    .was-validated
      .custom-control-input:invalid:focus
      ~ .custom-control-label::before,
    .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }

    .was-validated
      .custom-control-input:invalid:focus:not(:checked)
      ~ .custom-control-label::before,
    .custom-control-input.is-invalid:focus:not(:checked)
      ~ .custom-control-label::before {
      border-color: #dc3545;
    }

    .was-validated .custom-file-input:invalid ~ .custom-file-label,
    .custom-file-input.is-invalid ~ .custom-file-label {
      border-color: #dc3545;
    }

    .was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
    .custom-file-input.is-invalid:focus ~ .custom-file-label {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }

    .form-inline {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
    }

    .form-inline .form-check {
      width: 100%;
    }

    @media (min-width: 576px) {
      .form-inline label {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 0;
      }
      .form-inline .form-group {
        align-items: center;
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        margin-bottom: 0;
      }
      .form-inline .form-control {
        display: inline-block;
        vertical-align: middle;
        width: auto;
      }
      .form-inline .form-control-plaintext {
        display: inline-block;
      }
      .form-inline .input-group,
      .form-inline .custom-select {
        width: auto;
      }
      .form-inline .form-check {
        align-items: center;
        display: flex;
        justify-content: center;
        padding-left: 0;
        width: auto;
      }
      .form-inline .form-check-input {
        flex-shrink: 0;
        margin-left: 0;
        margin-right: 0.25rem;
        margin-top: 0;
        position: relative;
      }
      .form-inline .custom-control {
        align-items: center;
        justify-content: center;
      }
      .form-inline .custom-control-label {
        margin-bottom: 0;
      }
    }

    .btn {
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      color: #212529;
      display: inline-block;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
      text-align: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      user-select: none;
      vertical-align: middle;
    }

    @media (prefers-reduced-motion: reduce) {
      .btn {
        transition: none;
      }
    }

    .btn:hover {
      color: #212529;
      text-decoration: none;
    }

    .btn:focus,
    .btn.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      outline: 0;
    }

    .btn.disabled,
    .btn:disabled {
      opacity: 0.65;
    }

    .btn:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    a.btn.disabled,
    fieldset:disabled a.btn {
      pointer-events: none;
    }

    .btn-primary {
      background-color: #007bff;
      border-color: #007bff;
      color: #fff;
    }

    .btn-primary:hover {
      background-color: #0069d9;
      border-color: #0062cc;
      color: #fff;
    }

    .btn-primary:focus,
    .btn-primary.focus {
      background-color: #0069d9;
      border-color: #0062cc;
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
      color: #fff;
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
      background-color: #007bff;
      border-color: #007bff;
      color: #fff;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      background-color: #0062cc;
      border-color: #005cbf;
      color: #fff;
    }

    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }

    .btn-secondary {
      background-color: #6c757d;
      border-color: #6c757d;
      color: #fff;
    }

    .btn-secondary:hover {
      background-color: #5a6268;
      border-color: #545b62;
      color: #fff;
    }

    .btn-secondary:focus,
    .btn-secondary.focus {
      background-color: #5a6268;
      border-color: #545b62;
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
      color: #fff;
    }

    .btn-secondary.disabled,
    .btn-secondary:disabled {
      background-color: #6c757d;
      border-color: #6c757d;
      color: #fff;
    }

    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: #545b62;
      border-color: #4e555b;
      color: #fff;
    }

    .btn-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
    }

    .btn-success {
      background-color: #28a745;
      border-color: #28a745;
      color: #fff;
    }

    .btn-success:hover {
      background-color: #218838;
      border-color: #1e7e34;
      color: #fff;
    }

    .btn-success:focus,
    .btn-success.focus {
      background-color: #218838;
      border-color: #1e7e34;
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
      color: #fff;
    }

    .btn-success.disabled,
    .btn-success:disabled {
      background-color: #28a745;
      border-color: #28a745;
      color: #fff;
    }

    .btn-success:not(:disabled):not(.disabled):active,
    .btn-success:not(:disabled):not(.disabled).active,
    .show > .btn-success.dropdown-toggle {
      background-color: #1e7e34;
      border-color: #1c7430;
      color: #fff;
    }

    .btn-success:not(:disabled):not(.disabled):active:focus,
    .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
    }

    .btn-info {
      background-color: #17a2b8;
      border-color: #17a2b8;
      color: #fff;
    }

    .btn-info:hover {
      background-color: #138496;
      border-color: #117a8b;
      color: #fff;
    }

    .btn-info:focus,
    .btn-info.focus {
      background-color: #138496;
      border-color: #117a8b;
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
      color: #fff;
    }

    .btn-info.disabled,
    .btn-info:disabled {
      background-color: #17a2b8;
      border-color: #17a2b8;
      color: #fff;
    }

    .btn-info:not(:disabled):not(.disabled):active,
    .btn-info:not(:disabled):not(.disabled).active,
    .show > .btn-info.dropdown-toggle {
      background-color: #117a8b;
      border-color: #10707f;
      color: #fff;
    }

    .btn-info:not(:disabled):not(.disabled):active:focus,
    .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
    }

    .btn-warning {
      background-color: #ffc107;
      border-color: #ffc107;
      color: #212529;
    }

    .btn-warning:hover {
      background-color: #e0a800;
      border-color: #d39e00;
      color: #212529;
    }

    .btn-warning:focus,
    .btn-warning.focus {
      background-color: #e0a800;
      border-color: #d39e00;
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
      color: #212529;
    }

    .btn-warning.disabled,
    .btn-warning:disabled {
      background-color: #ffc107;
      border-color: #ffc107;
      color: #212529;
    }

    .btn-warning:not(:disabled):not(.disabled):active,
    .btn-warning:not(:disabled):not(.disabled).active,
    .show > .btn-warning.dropdown-toggle {
      background-color: #d39e00;
      border-color: #c69500;
      color: #212529;
    }

    .btn-warning:not(:disabled):not(.disabled):active:focus,
    .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
    }

    .btn-danger {
      background-color: #dc3545;
      border-color: #dc3545;
      color: #fff;
    }

    .btn-danger:hover {
      background-color: #c82333;
      border-color: #bd2130;
      color: #fff;
    }

    .btn-danger:focus,
    .btn-danger.focus {
      background-color: #c82333;
      border-color: #bd2130;
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
      color: #fff;
    }

    .btn-danger.disabled,
    .btn-danger:disabled {
      background-color: #dc3545;
      border-color: #dc3545;
      color: #fff;
    }

    .btn-danger:not(:disabled):not(.disabled):active,
    .btn-danger:not(:disabled):not(.disabled).active,
    .show > .btn-danger.dropdown-toggle {
      background-color: #bd2130;
      border-color: #b21f2d;
      color: #fff;
    }

    .btn-danger:not(:disabled):not(.disabled):active:focus,
    .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    }

    .btn-light {
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      color: #212529;
    }

    .btn-light:hover {
      background-color: #e2e6ea;
      border-color: #dae0e5;
      color: #212529;
    }

    .btn-light:focus,
    .btn-light.focus {
      background-color: #e2e6ea;
      border-color: #dae0e5;
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
      color: #212529;
    }

    .btn-light.disabled,
    .btn-light:disabled {
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      color: #212529;
    }

    .btn-light:not(:disabled):not(.disabled):active,
    .btn-light:not(:disabled):not(.disabled).active,
    .show > .btn-light.dropdown-toggle {
      background-color: #dae0e5;
      border-color: #d3d9df;
      color: #212529;
    }

    .btn-light:not(:disabled):not(.disabled):active:focus,
    .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
    }

    .btn-dark {
      background-color: #343a40;
      border-color: #343a40;
      color: #fff;
    }

    .btn-dark:hover {
      background-color: #23272b;
      border-color: #1d2124;
      color: #fff;
    }

    .btn-dark:focus,
    .btn-dark.focus {
      background-color: #23272b;
      border-color: #1d2124;
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
      color: #fff;
    }

    .btn-dark.disabled,
    .btn-dark:disabled {
      background-color: #343a40;
      border-color: #343a40;
      color: #fff;
    }

    .btn-dark:not(:disabled):not(.disabled):active,
    .btn-dark:not(:disabled):not(.disabled).active,
    .show > .btn-dark.dropdown-toggle {
      background-color: #1d2124;
      border-color: #171a1d;
      color: #fff;
    }

    .btn-dark:not(:disabled):not(.disabled):active:focus,
    .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
    }

    .btn-outline-primary {
      border-color: #007bff;
      color: #007bff;
    }

    .btn-outline-primary:hover {
      background-color: #007bff;
      border-color: #007bff;
      color: #fff;
    }

    .btn-outline-primary:focus,
    .btn-outline-primary.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }

    .btn-outline-primary.disabled,
    .btn-outline-primary:disabled {
      background-color: transparent;
      color: #007bff;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-primary.dropdown-toggle {
      background-color: #007bff;
      border-color: #007bff;
      color: #fff;
    }

    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }

    .btn-outline-secondary {
      border-color: #6c757d;
      color: #6c757d;
    }

    .btn-outline-secondary:hover {
      background-color: #6c757d;
      border-color: #6c757d;
      color: #fff;
    }

    .btn-outline-secondary:focus,
    .btn-outline-secondary.focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    }

    .btn-outline-secondary.disabled,
    .btn-outline-secondary:disabled {
      background-color: transparent;
      color: #6c757d;
    }

    .btn-outline-secondary:not(:disabled):not(.disabled):active,
    .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-secondary.dropdown-toggle {
      background-color: #6c757d;
      border-color: #6c757d;
      color: #fff;
    }

    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
    }

    .btn-outline-success {
      border-color: #28a745;
      color: #28a745;
    }

    .btn-outline-success:hover {
      background-color: #28a745;
      border-color: #28a745;
      color: #fff;
    }

    .btn-outline-success:focus,
    .btn-outline-success.focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    }

    .btn-outline-success.disabled,
    .btn-outline-success:disabled {
      background-color: transparent;
      color: #28a745;
    }

    .btn-outline-success:not(:disabled):not(.disabled):active,
    .btn-outline-success:not(:disabled):not(.disabled).active,
    .show > .btn-outline-success.dropdown-toggle {
      background-color: #28a745;
      border-color: #28a745;
      color: #fff;
    }

    .btn-outline-success:not(:disabled):not(.disabled):active:focus,
    .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
    }

    .btn-outline-info {
      border-color: #17a2b8;
      color: #17a2b8;
    }

    .btn-outline-info:hover {
      background-color: #17a2b8;
      border-color: #17a2b8;
      color: #fff;
    }

    .btn-outline-info:focus,
    .btn-outline-info.focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    }

    .btn-outline-info.disabled,
    .btn-outline-info:disabled {
      background-color: transparent;
      color: #17a2b8;
    }

    .btn-outline-info:not(:disabled):not(.disabled):active,
    .btn-outline-info:not(:disabled):not(.disabled).active,
    .show > .btn-outline-info.dropdown-toggle {
      background-color: #17a2b8;
      border-color: #17a2b8;
      color: #fff;
    }

    .btn-outline-info:not(:disabled):not(.disabled):active:focus,
    .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    }

    .btn-outline-warning {
      border-color: #ffc107;
      color: #ffc107;
    }

    .btn-outline-warning:hover {
      background-color: #ffc107;
      border-color: #ffc107;
      color: #212529;
    }

    .btn-outline-warning:focus,
    .btn-outline-warning.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    }

    .btn-outline-warning.disabled,
    .btn-outline-warning:disabled {
      background-color: transparent;
      color: #ffc107;
    }

    .btn-outline-warning:not(:disabled):not(.disabled):active,
    .btn-outline-warning:not(:disabled):not(.disabled).active,
    .show > .btn-outline-warning.dropdown-toggle {
      background-color: #ffc107;
      border-color: #ffc107;
      color: #212529;
    }

    .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
    .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
    }

    .btn-outline-danger {
      border-color: #dc3545;
      color: #dc3545;
    }

    .btn-outline-danger:hover {
      background-color: #dc3545;
      border-color: #dc3545;
      color: #fff;
    }

    .btn-outline-danger:focus,
    .btn-outline-danger.focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    }

    .btn-outline-danger.disabled,
    .btn-outline-danger:disabled {
      background-color: transparent;
      color: #dc3545;
    }

    .btn-outline-danger:not(:disabled):not(.disabled):active,
    .btn-outline-danger:not(:disabled):not(.disabled).active,
    .show > .btn-outline-danger.dropdown-toggle {
      background-color: #dc3545;
      border-color: #dc3545;
      color: #fff;
    }

    .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
    .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
    }

    .btn-outline-light {
      border-color: #f8f9fa;
      color: #f8f9fa;
    }

    .btn-outline-light:hover {
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      color: #212529;
    }

    .btn-outline-light:focus,
    .btn-outline-light.focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    }

    .btn-outline-light.disabled,
    .btn-outline-light:disabled {
      background-color: transparent;
      color: #f8f9fa;
    }

    .btn-outline-light:not(:disabled):not(.disabled):active,
    .btn-outline-light:not(:disabled):not(.disabled).active,
    .show > .btn-outline-light.dropdown-toggle {
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      color: #212529;
    }

    .btn-outline-light:not(:disabled):not(.disabled):active:focus,
    .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    }

    .btn-outline-dark {
      border-color: #343a40;
      color: #343a40;
    }

    .btn-outline-dark:hover {
      background-color: #343a40;
      border-color: #343a40;
      color: #fff;
    }

    .btn-outline-dark:focus,
    .btn-outline-dark.focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    }

    .btn-outline-dark.disabled,
    .btn-outline-dark:disabled {
      background-color: transparent;
      color: #343a40;
    }

    .btn-outline-dark:not(:disabled):not(.disabled):active,
    .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show > .btn-outline-dark.dropdown-toggle {
      background-color: #343a40;
      border-color: #343a40;
      color: #fff;
    }

    .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
    .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    }

    .btn-link {
      color: #007bff;
      font-weight: 400;
      text-decoration: none;
    }

    .btn-link:hover {
      color: #0056b3;
      text-decoration: underline;
    }

    .btn-link:focus,
    .btn-link.focus {
      text-decoration: underline;
    }

    .btn-link:disabled,
    .btn-link.disabled {
      color: #6c757d;
      pointer-events: none;
    }

    .btn-lg,
    .btn-group-lg > .btn {
      border-radius: 0.3rem;
      font-size: 1.25rem;
      line-height: 1.5;
      padding: 0.5rem 1rem;
    }

    .btn-sm,
    .btn-group-sm > .btn {
      border-radius: 0.2rem;
      font-size: 0.875rem;
      line-height: 1.5;
      padding: 0.25rem 0.5rem;
    }

    .btn-block {
      display: block;
      width: 100%;
    }

    .btn-block + .btn-block {
      margin-top: 0.5rem;
    }

    input[type='submit'].btn-block,
    input[type='reset'].btn-block,
    input[type='button'].btn-block {
      width: 100%;
    }

    .fade {
      transition: opacity 0.15s linear;
    }

    @media (prefers-reduced-motion: reduce) {
      .fade {
        transition: none;
      }
    }

    .fade:not(.show) {
      opacity: 0;
    }

    .collapse:not(.show) {
      display: none;
    }

    .collapsing {
      height: 0;
      overflow: hidden;
      position: relative;
      transition: height 0.35s ease;
    }

    @media (prefers-reduced-motion: reduce) {
      .collapsing {
        transition: none;
      }
    }

    .dropup,
    .dropright,
    .dropdown,
    .dropleft {
      position: relative;
    }

    .dropdown-toggle {
      white-space: nowrap;
    }

    .dropdown-toggle::after {
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      border-right: 0.3em solid transparent;
      border-top: 0.3em solid;
      content: '';
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
    }

    .dropdown-toggle:empty::after {
      margin-left: 0;
    }

    .dropdown-menu {
      background-clip: padding-box;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem;
      color: #212529;
      display: none;
      float: left;
      font-size: 1rem;
      left: 0;
      list-style: none;
      margin: 0.125rem 0 0;
      min-width: 10rem;
      padding: 0.5rem 0;
      position: absolute;
      text-align: left;
      top: 100%;
      z-index: 1000;
    }

    .dropdown-menu-left {
      left: 0;
      right: auto;
    }

    .dropdown-menu-right {
      left: auto;
      right: 0;
    }

    @media (min-width: 576px) {
      .dropdown-menu-sm-left {
        left: 0;
        right: auto;
      }

      .dropdown-menu-sm-right {
        left: auto;
        right: 0;
      }
    }
    @media (min-width: 768px) {
      .dropdown-menu-md-left {
        left: 0;
        right: auto;
      }

      .dropdown-menu-md-right {
        left: auto;
        right: 0;
      }
    }
    @media (min-width: 992px) {
      .dropdown-menu-lg-left {
        left: 0;
        right: auto;
      }

      .dropdown-menu-lg-right {
        left: auto;
        right: 0;
      }
    }
    @media (min-width: 1200px) {
      .dropdown-menu-xl-left {
        left: 0;
        right: auto;
      }

      .dropdown-menu-xl-right {
        left: auto;
        right: 0;
      }
    }

    .dropup .dropdown-menu {
      bottom: 100%;
      margin-bottom: 0.125rem;
      margin-top: 0;
      top: auto;
    }

    .dropup .dropdown-toggle::after {
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
      border-right: 0.3em solid transparent;
      border-top: 0;
      content: '';
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
    }

    .dropup .dropdown-toggle:empty::after {
      margin-left: 0;
    }

    .dropright .dropdown-menu {
      left: 100%;
      margin-left: 0.125rem;
      margin-top: 0;
      right: auto;
      top: 0;
    }

    .dropright .dropdown-toggle::after {
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
      border-right: 0;
      border-top: 0.3em solid transparent;
      content: '';
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
    }

    .dropright .dropdown-toggle:empty::after {
      margin-left: 0;
    }

    .dropright .dropdown-toggle::after {
      vertical-align: 0;
    }

    .dropleft .dropdown-menu {
      left: auto;
      margin-right: 0.125rem;
      margin-top: 0;
      right: 100%;
      top: 0;
    }

    .dropleft .dropdown-toggle::after {
      content: '';
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
    }

    .dropleft .dropdown-toggle::after {
      display: none;
    }

    .dropleft .dropdown-toggle::before {
      border-bottom: 0.3em solid transparent;
      border-right: 0.3em solid;
      border-top: 0.3em solid transparent;
      content: '';
      display: inline-block;
      margin-right: 0.255em;
      vertical-align: 0.255em;
    }

    .dropleft .dropdown-toggle:empty::after {
      margin-left: 0;
    }

    .dropleft .dropdown-toggle::before {
      vertical-align: 0;
    }

    .dropdown-menu[x-placement^='top'],
    .dropdown-menu[x-placement^='right'],
    .dropdown-menu[x-placement^='bottom'],
    .dropdown-menu[x-placement^='left'] {
      bottom: auto;
      right: auto;
    }

    .dropdown-divider {
      border-top: 1px solid #e9ecef;
      height: 0;
      margin: 0.5rem 0;
      overflow: hidden;
    }

    .dropdown-item {
      background-color: transparent;
      border: 0;
      clear: both;
      color: #212529;
      display: block;
      font-weight: 400;
      padding: 0.25rem 1.5rem;
      text-align: inherit;
      white-space: nowrap;
      width: 100%;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
      background-color: #f8f9fa;
      color: #16181b;
      text-decoration: none;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      background-color: #007bff;
      color: #fff;
      text-decoration: none;
    }

    .dropdown-item.disabled,
    .dropdown-item:disabled {
      background-color: transparent;
      color: #6c757d;
      pointer-events: none;
    }

    .dropdown-menu.show {
      display: block;
    }

    .dropdown-header {
      color: #6c757d;
      display: block;
      font-size: 0.875rem;
      margin-bottom: 0;
      padding: 0.5rem 1.5rem;
      white-space: nowrap;
    }

    .dropdown-item-text {
      color: #212529;
      display: block;
      padding: 0.25rem 1.5rem;
    }

    .btn-group,
    .btn-group-vertical {
      display: inline-flex;
      position: relative;
      vertical-align: middle;
    }

    .btn-group > .btn,
    .btn-group-vertical > .btn {
      flex: 1 1 auto;
      position: relative;
    }

    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1;
    }

    .btn-group > .btn:focus,
    .btn-group > .btn:active,
    .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1;
    }

    .btn-toolbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .btn-toolbar .input-group {
      width: auto;
    }

    .btn-group > .btn:not(:first-child),
    .btn-group > .btn-group:not(:first-child) {
      margin-left: -1px;
    }

    .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group > .btn-group:not(:last-child) > .btn {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .btn-group > .btn:not(:first-child),
    .btn-group > .btn-group:not(:first-child) > .btn {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .dropdown-toggle-split {
      padding-left: 0.5625rem;
      padding-right: 0.5625rem;
    }

    .dropdown-toggle-split::after,
    .dropup .dropdown-toggle-split::after,
    .dropright .dropdown-toggle-split::after {
      margin-left: 0;
    }

    .dropleft .dropdown-toggle-split::before {
      margin-right: 0;
    }

    .btn-sm + .dropdown-toggle-split,
    .btn-group-sm > .btn + .dropdown-toggle-split {
      padding-left: 0.375rem;
      padding-right: 0.375rem;
    }

    .btn-lg + .dropdown-toggle-split,
    .btn-group-lg > .btn + .dropdown-toggle-split {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .btn-group-vertical {
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
    }

    .btn-group-vertical > .btn,
    .btn-group-vertical > .btn-group {
      width: 100%;
    }

    .btn-group-vertical > .btn:not(:first-child),
    .btn-group-vertical > .btn-group:not(:first-child) {
      margin-top: -1px;
    }

    .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group-vertical > .btn-group:not(:last-child) > .btn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn-group-vertical > .btn:not(:first-child),
    .btn-group-vertical > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .btn-group-toggle > .btn,
    .btn-group-toggle > .btn-group > .btn {
      margin-bottom: 0;
    }

    .btn-group-toggle > .btn input[type='radio'],
    .btn-group-toggle > .btn input[type='checkbox'],
    .btn-group-toggle > .btn-group > .btn input[type='radio'],
    .btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
      position: absolute;
    }

    .input-group {
      align-items: stretch;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      width: 100%;
    }

    .input-group > .form-control,
    .input-group > .form-control-plaintext,
    .input-group > .custom-select,
    .input-group > .custom-file {
      flex: 1 1 auto;
      margin-bottom: 0;
      min-width: 0;
      position: relative;
      width: 1%;
    }

    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px;
    }

    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
      z-index: 3;
    }

    .input-group > .custom-file .custom-file-input:focus {
      z-index: 4;
    }

    .input-group > .form-control:not(:last-child),
    .input-group > .custom-select:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .input-group > .form-control:not(:first-child),
    .input-group > .custom-select:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .input-group > .custom-file {
      align-items: center;
      display: flex;
    }

    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .input-group-prepend,
    .input-group-append {
      display: flex;
    }

    .input-group-prepend .btn,
    .input-group-append .btn {
      position: relative;
      z-index: 2;
    }

    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3;
    }

    .input-group-prepend .btn + .btn,
    .input-group-prepend .btn + .input-group-text,
    .input-group-prepend .input-group-text + .input-group-text,
    .input-group-prepend .input-group-text + .btn,
    .input-group-append .btn + .btn,
    .input-group-append .btn + .input-group-text,
    .input-group-append .input-group-text + .input-group-text,
    .input-group-append .input-group-text + .btn {
      margin-left: -1px;
    }

    .input-group-prepend {
      margin-right: -1px;
    }

    .input-group-append {
      margin-left: -1px;
    }

    .input-group-text {
      align-items: center;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      color: #495057;
      display: flex;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 0;
      padding: 0.375rem 0.75rem;
      text-align: center;
      white-space: nowrap;
    }

    .input-group-text input[type='radio'],
    .input-group-text input[type='checkbox'] {
      margin-top: 0;
    }

    .input-group-lg > .form-control:not(textarea),
    .input-group-lg > .custom-select {
      height: calc(1.5em + 1rem + 2px);
    }

    .input-group-lg > .form-control,
    .input-group-lg > .custom-select,
    .input-group-lg > .input-group-prepend > .input-group-text,
    .input-group-lg > .input-group-append > .input-group-text,
    .input-group-lg > .input-group-prepend > .btn,
    .input-group-lg > .input-group-append > .btn {
      border-radius: 0.3rem;
      font-size: 1.25rem;
      line-height: 1.5;
      padding: 0.5rem 1rem;
    }

    .input-group-sm > .form-control:not(textarea),
    .input-group-sm > .custom-select {
      height: calc(1.5em + 0.5rem + 2px);
    }

    .input-group-sm > .form-control,
    .input-group-sm > .custom-select,
    .input-group-sm > .input-group-prepend > .input-group-text,
    .input-group-sm > .input-group-append > .input-group-text,
    .input-group-sm > .input-group-prepend > .btn,
    .input-group-sm > .input-group-append > .btn {
      border-radius: 0.2rem;
      font-size: 0.875rem;
      line-height: 1.5;
      padding: 0.25rem 0.5rem;
    }

    .input-group-lg > .custom-select,
    .input-group-sm > .custom-select {
      padding-right: 1.75rem;
    }

    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-prepend > .input-group-text,
    .input-group > .input-group-append:not(:last-child) > .btn,
    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group
      > .input-group-append:last-child
      > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group
      > .input-group-append:last-child
      > .input-group-text:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .input-group > .input-group-append > .btn,
    .input-group > .input-group-append > .input-group-text,
    .input-group > .input-group-prepend:not(:first-child) > .btn,
    .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
    .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
    .input-group
      > .input-group-prepend:first-child
      > .input-group-text:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .custom-control {
      color-adjust: exact;
      display: block;
      min-height: 1.5rem;
      padding-left: 1.5rem;
      position: relative;
      z-index: 1;
    }

    .custom-control-inline {
      display: inline-flex;
      margin-right: 1rem;
    }

    .custom-control-input {
      height: 1.25rem;
      left: 0;
      opacity: 0;
      position: absolute;
      width: 1rem;
      z-index: -1;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #007bff;
      border-color: #007bff;
      color: #fff;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #80bdff;
    }

    .custom-control-input:not(:disabled):active
      ~ .custom-control-label::before {
      background-color: #b3d7ff;
      border-color: #b3d7ff;
      color: #fff;
    }

    .custom-control-input[disabled] ~ .custom-control-label,
    .custom-control-input:disabled ~ .custom-control-label {
      color: #6c757d;
    }

    .custom-control-input[disabled] ~ .custom-control-label::before,
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef;
    }

    .custom-control-label {
      margin-bottom: 0;
      position: relative;
      vertical-align: top;
    }

    .custom-control-label::before {
      background-color: #fff;
      border: #adb5bd solid 1px;
      content: '';
      display: block;
      height: 1rem;
      left: -1.5rem;
      pointer-events: none;
      position: absolute;
      top: 0.25rem;
      width: 1rem;
    }

    .custom-control-label::after {
      background: no-repeat 50%/50% 50%;
      content: '';
      display: block;
      height: 1rem;
      left: -1.5rem;
      position: absolute;
      top: 0.25rem;
      width: 1rem;
    }

    .custom-checkbox .custom-control-label::before {
      border-radius: 0.25rem;
    }

    .custom-checkbox
      .custom-control-input:checked
      ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }

    .custom-checkbox
      .custom-control-input:indeterminate
      ~ .custom-control-label::before {
      background-color: #007bff;
      border-color: #007bff;
    }

    .custom-checkbox
      .custom-control-input:indeterminate
      ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
    }

    .custom-checkbox
      .custom-control-input:disabled:checked
      ~ .custom-control-label::before {
      background-color: rgba(0, 123, 255, 0.5);
    }

    .custom-checkbox
      .custom-control-input:disabled:indeterminate
      ~ .custom-control-label::before {
      background-color: rgba(0, 123, 255, 0.5);
    }

    .custom-radio .custom-control-label::before {
      border-radius: 50%;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    .custom-radio
      .custom-control-input:disabled:checked
      ~ .custom-control-label::before {
      background-color: rgba(0, 123, 255, 0.5);
    }

    .custom-switch {
      padding-left: 2.25rem;
    }

    .custom-switch .custom-control-label::before {
      border-radius: 0.5rem;
      left: -2.25rem;
      pointer-events: all;
      width: 1.75rem;
    }

    .custom-switch .custom-control-label::after {
      background-color: #adb5bd;
      border-radius: 0.5rem;
      height: calc(1rem - 4px);
      left: calc(-2.25rem + 2px);
      top: calc(0.25rem + 2px);
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      width: calc(1rem - 4px);
    }

    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none;
      }
    }

    .custom-switch
      .custom-control-input:checked
      ~ .custom-control-label::after {
      background-color: #fff;
      transform: translateX(0.75rem);
    }

    .custom-switch
      .custom-control-input:disabled:checked
      ~ .custom-control-label::before {
      background-color: rgba(0, 123, 255, 0.5);
    }

    .custom-select {
      appearance: none;
      background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      color: #495057;
      display: inline-block;
      font-size: 1rem;
      font-weight: 400;
      height: calc(1.5em + 0.75rem + 2px);
      line-height: 1.5;
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      vertical-align: middle;
      width: 100%;
    }

    .custom-select:focus {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      outline: 0;
    }

    .custom-select:focus::-ms-value {
      background-color: #fff;
      color: #495057;
    }

    .custom-select[multiple],
    .custom-select[size]:not([size='1']) {
      background-image: none;
      height: auto;
      padding-right: 0.75rem;
    }

    .custom-select:disabled {
      background-color: #e9ecef;
      color: #6c757d;
    }

    .custom-select::-ms-expand {
      display: none;
    }

    .custom-select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #495057;
    }

    .custom-select-sm {
      font-size: 0.875rem;
      height: calc(1.5em + 0.5rem + 2px);
      padding-bottom: 0.25rem;
      padding-left: 0.5rem;
      padding-top: 0.25rem;
    }

    .custom-select-lg {
      font-size: 1.25rem;
      height: calc(1.5em + 1rem + 2px);
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      padding-top: 0.5rem;
    }

    .custom-file {
      display: inline-block;
      height: calc(1.5em + 0.75rem + 2px);
      margin-bottom: 0;
      position: relative;
      width: 100%;
    }

    .custom-file-input {
      height: calc(1.5em + 0.75rem + 2px);
      margin: 0;
      opacity: 0;
      position: relative;
      width: 100%;
      z-index: 2;
    }

    .custom-file-input:focus ~ .custom-file-label {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .custom-file-input[disabled] ~ .custom-file-label,
    .custom-file-input:disabled ~ .custom-file-label {
      background-color: #e9ecef;
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: 'Browse';
    }

    .custom-file-input ~ .custom-file-label[data-browse]::after {
      content: attr(data-browse);
    }

    .custom-file-label {
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      color: #495057;
      font-weight: 400;
      height: calc(1.5em + 0.75rem + 2px);
      left: 0;
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    .custom-file-label::after {
      background-color: #e9ecef;
      border-left: inherit;
      border-radius: 0 0.25rem 0.25rem 0;
      bottom: 0;
      color: #495057;
      content: 'Browse';
      display: block;
      height: calc(1.5em + 0.75rem);
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
    }

    .custom-range {
      appearance: none;
      background-color: transparent;
      height: 1.4rem;
      padding: 0;
      width: 100%;
    }

    .custom-range:focus {
      outline: none;
    }

    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    .custom-range::-moz-focus-outer {
      border: 0;
    }

    .custom-range::-webkit-slider-thumb {
      appearance: none;
      background-color: #007bff;
      border: 0;
      border-radius: 1rem;
      height: 1rem;
      margin-top: -0.25rem;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 1rem;
    }

    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none;
      }
    }

    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff;
    }

    .custom-range::-webkit-slider-runnable-track {
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem;
      color: transparent;
      cursor: pointer;
      height: 0.5rem;
      width: 100%;
    }

    .custom-range::-moz-range-thumb {
      appearance: none;
      background-color: #007bff;
      border: 0;
      border-radius: 1rem;
      height: 1rem;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 1rem;
    }

    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none;
      }
    }

    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff;
    }

    .custom-range::-moz-range-track {
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem;
      color: transparent;
      cursor: pointer;
      height: 0.5rem;
      width: 100%;
    }

    .custom-range::-ms-thumb {
      appearance: none;
      background-color: #007bff;
      border: 0;
      border-radius: 1rem;
      height: 1rem;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
      margin-top: 0;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 1rem;
    }

    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none;
      }
    }

    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff;
    }

    .custom-range::-ms-track {
      background-color: transparent;
      border-color: transparent;
      border-width: 0.5rem;
      color: transparent;
      cursor: pointer;
      height: 0.5rem;
      width: 100%;
    }

    .custom-range::-ms-fill-lower {
      background-color: #dee2e6;
      border-radius: 1rem;
    }

    .custom-range::-ms-fill-upper {
      background-color: #dee2e6;
      border-radius: 1rem;
      margin-right: 15px;
    }

    .custom-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd;
    }

    .custom-range:disabled::-webkit-slider-runnable-track {
      cursor: default;
    }

    .custom-range:disabled::-moz-range-thumb {
      background-color: #adb5bd;
    }

    .custom-range:disabled::-moz-range-track {
      cursor: default;
    }

    .custom-range:disabled::-ms-thumb {
      background-color: #adb5bd;
    }

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    @media (prefers-reduced-motion: reduce) {
      .custom-control-label::before,
      .custom-file-label,
      .custom-select {
        transition: none;
      }
    }

    .badge {
      border-radius: 0.25rem;
      display: inline-block;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      padding: 0.25em 0.4em;
      text-align: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      vertical-align: baseline;
      white-space: nowrap;
    }

    @media (prefers-reduced-motion: reduce) {
      .badge {
        transition: none;
      }
    }

    a.badge:hover,
    a.badge:focus {
      text-decoration: none;
    }

    .badge:empty {
      display: none;
    }

    .btn .badge {
      position: relative;
      top: -1px;
    }

    .badge-pill {
      border-radius: 10rem;
      padding-left: 0.6em;
      padding-right: 0.6em;
    }

    .badge-primary {
      background-color: #007bff;
      color: #fff;
    }

    a.badge-primary:hover,
    a.badge-primary:focus {
      background-color: #0062cc;
      color: #fff;
    }

    a.badge-primary:focus,
    a.badge-primary.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
      outline: 0;
    }

    .badge-secondary {
      background-color: #6c757d;
      color: #fff;
    }

    a.badge-secondary:hover,
    a.badge-secondary:focus {
      background-color: #545b62;
      color: #fff;
    }

    a.badge-secondary:focus,
    a.badge-secondary.focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
      outline: 0;
    }

    .badge-success {
      background-color: #28a745;
      color: #fff;
    }

    a.badge-success:hover,
    a.badge-success:focus {
      background-color: #1e7e34;
      color: #fff;
    }

    a.badge-success:focus,
    a.badge-success.focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
      outline: 0;
    }

    .badge-info {
      background-color: #17a2b8;
      color: #fff;
    }

    a.badge-info:hover,
    a.badge-info:focus {
      background-color: #117a8b;
      color: #fff;
    }

    a.badge-info:focus,
    a.badge-info.focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
      outline: 0;
    }

    .badge-warning {
      background-color: #ffc107;
      color: #212529;
    }

    a.badge-warning:hover,
    a.badge-warning:focus {
      background-color: #d39e00;
      color: #212529;
    }

    a.badge-warning:focus,
    a.badge-warning.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
      outline: 0;
    }

    .badge-danger {
      background-color: #dc3545;
      color: #fff;
    }

    a.badge-danger:hover,
    a.badge-danger:focus {
      background-color: #bd2130;
      color: #fff;
    }

    a.badge-danger:focus,
    a.badge-danger.focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
      outline: 0;
    }

    .badge-light {
      background-color: #f8f9fa;
      color: #212529;
    }

    a.badge-light:hover,
    a.badge-light:focus {
      background-color: #dae0e5;
      color: #212529;
    }

    a.badge-light:focus,
    a.badge-light.focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
      outline: 0;
    }

    .badge-dark {
      background-color: #343a40;
      color: #fff;
    }

    a.badge-dark:hover,
    a.badge-dark:focus {
      background-color: #1d2124;
      color: #fff;
    }

    a.badge-dark:focus,
    a.badge-dark.focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
      outline: 0;
    }

    .jumbotron {
      background-color: #e9ecef;
      border-radius: 0.3rem;
      margin-bottom: 2rem;
      padding: 2rem 1rem;
    }

    @media (min-width: 576px) {
      .jumbotron {
        padding: 4rem 2rem;
      }
    }

    .jumbotron-fluid {
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .alert {
      border: 1px solid transparent;
      border-radius: 0.25rem;
      margin-bottom: 1rem;
      padding: 0.75rem 1.25rem;
      position: relative;
    }

    .alert-heading {
      color: inherit;
    }

    .alert-link {
      font-weight: 700;
    }

    .alert-dismissible {
      padding-right: 4rem;
    }

    .alert-dismissible .close {
      color: inherit;
      padding: 0.75rem 1.25rem;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }

    .alert-primary {
      background-color: #cce5ff;
      border-color: #b8daff;
      color: #004085;
    }

    .alert-primary hr {
      border-top-color: #9fcdff;
    }

    .alert-primary .alert-link {
      color: #002752;
    }

    .alert-secondary {
      background-color: #e2e3e5;
      border-color: #d6d8db;
      color: #383d41;
    }

    .alert-secondary hr {
      border-top-color: #c8cbcf;
    }

    .alert-secondary .alert-link {
      color: #202326;
    }

    .alert-success {
      background-color: #d4edda;
      border-color: #c3e6cb;
      color: #155724;
    }

    .alert-success hr {
      border-top-color: #b1dfbb;
    }

    .alert-success .alert-link {
      color: #0b2e13;
    }

    .alert-info {
      background-color: #d1ecf1;
      border-color: #bee5eb;
      color: #0c5460;
    }

    .alert-info hr {
      border-top-color: #abdde5;
    }

    .alert-info .alert-link {
      color: #062c33;
    }

    .alert-warning {
      background-color: #fff3cd;
      border-color: #ffeeba;
      color: #856404;
    }

    .alert-warning hr {
      border-top-color: #ffe8a1;
    }

    .alert-warning .alert-link {
      color: #533f03;
    }

    .alert-danger {
      background-color: #f8d7da;
      border-color: #f5c6cb;
      color: #721c24;
    }

    .alert-danger hr {
      border-top-color: #f1b0b7;
    }

    .alert-danger .alert-link {
      color: #491217;
    }

    .alert-light {
      background-color: #fefefe;
      border-color: #fdfdfe;
      color: #818182;
    }

    .alert-light hr {
      border-top-color: #ececf6;
    }

    .alert-light .alert-link {
      color: #686868;
    }

    .alert-dark {
      background-color: #d6d8d9;
      border-color: #c6c8ca;
      color: #1b1e21;
    }

    .alert-dark hr {
      border-top-color: #b9bbbe;
    }

    .alert-dark .alert-link {
      color: #040505;
    }

    @keyframes progress-bar-stripes {
      from {
        background-position: 1rem 0;
      }
      to {
        background-position: 0 0;
      }
    }

    .progress {
      background-color: #e9ecef;
      border-radius: 0.25rem;
      display: flex;
      font-size: 0.75rem;
      height: 1rem;
      line-height: 0;
      overflow: hidden;
    }

    .progress-bar {
      background-color: #007bff;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      text-align: center;
      transition: width 0.6s ease;
      white-space: nowrap;
    }

    @media (prefers-reduced-motion: reduce) {
      .progress-bar {
        transition: none;
      }
    }

    .progress-bar-striped {
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      background-size: 1rem 1rem;
    }

    .progress-bar-animated {
      animation: progress-bar-stripes 1s linear infinite;
    }

    @media (prefers-reduced-motion: reduce) {
      .progress-bar-animated {
        animation: none;
      }
    }

    .media {
      align-items: flex-start;
      display: flex;
    }

    .media-body {
      flex: 1;
    }

    .list-group {
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding-left: 0;
    }

    .list-group-item-action {
      color: #495057;
      text-align: inherit;
      width: 100%;
    }

    .list-group-item-action:hover,
    .list-group-item-action:focus {
      background-color: #f8f9fa;
      color: #495057;
      text-decoration: none;
      z-index: 1;
    }

    .list-group-item-action:active {
      background-color: #e9ecef;
      color: #212529;
    }

    .list-group-item {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      display: block;
      padding: 0.75rem 1.25rem;
      position: relative;
    }

    .list-group-item:first-child {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    .list-group-item:last-child {
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    .list-group-item.disabled,
    .list-group-item:disabled {
      background-color: #fff;
      color: #6c757d;
      pointer-events: none;
    }

    .list-group-item.active {
      background-color: #007bff;
      border-color: #007bff;
      color: #fff;
      z-index: 2;
    }

    .list-group-item + .list-group-item {
      border-top-width: 0;
    }

    .list-group-item + .list-group-item.active {
      border-top-width: 1px;
      margin-top: -1px;
    }

    .list-group-horizontal {
      flex-direction: row;
    }

    .list-group-horizontal > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
    }

    .list-group-horizontal > .list-group-item:last-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0.25rem;
    }

    .list-group-horizontal > .list-group-item.active {
      margin-top: 0;
    }

    .list-group-horizontal > .list-group-item + .list-group-item {
      border-left-width: 0;
      border-top-width: 1px;
    }

    .list-group-horizontal > .list-group-item + .list-group-item.active {
      border-left-width: 1px;
      margin-left: -1px;
    }

    @media (min-width: 576px) {
      .list-group-horizontal-sm {
        flex-direction: row;
      }
      .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }
      .list-group-horizontal-sm > .list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
      }
      .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0;
      }
      .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-left-width: 0;
        border-top-width: 1px;
      }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px;
      }
    }
    @media (min-width: 768px) {
      .list-group-horizontal-md {
        flex-direction: row;
      }
      .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }
      .list-group-horizontal-md > .list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
      }
      .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0;
      }
      .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-left-width: 0;
        border-top-width: 1px;
      }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px;
      }
    }
    @media (min-width: 992px) {
      .list-group-horizontal-lg {
        flex-direction: row;
      }
      .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }
      .list-group-horizontal-lg > .list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
      }
      .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0;
      }
      .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-left-width: 0;
        border-top-width: 1px;
      }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px;
      }
    }
    @media (min-width: 1200px) {
      .list-group-horizontal-xl {
        flex-direction: row;
      }
      .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
      }
      .list-group-horizontal-xl > .list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
      }
      .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0;
      }
      .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-left-width: 0;
        border-top-width: 1px;
      }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        border-left-width: 1px;
        margin-left: -1px;
      }
    }

    .list-group-flush {
      border-radius: 0;
    }

    .list-group-flush > .list-group-item {
      border-width: 0 0 1px;
    }

    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0;
    }

    .list-group-item-primary {
      background-color: #b8daff;
      color: #004085;
    }

    .list-group-item-primary.list-group-item-action:hover,
    .list-group-item-primary.list-group-item-action:focus {
      background-color: #9fcdff;
      color: #004085;
    }

    .list-group-item-primary.list-group-item-action.active {
      background-color: #004085;
      border-color: #004085;
      color: #fff;
    }

    .list-group-item-secondary {
      background-color: #d6d8db;
      color: #383d41;
    }

    .list-group-item-secondary.list-group-item-action:hover,
    .list-group-item-secondary.list-group-item-action:focus {
      background-color: #c8cbcf;
      color: #383d41;
    }

    .list-group-item-secondary.list-group-item-action.active {
      background-color: #383d41;
      border-color: #383d41;
      color: #fff;
    }

    .list-group-item-success {
      background-color: #c3e6cb;
      color: #155724;
    }

    .list-group-item-success.list-group-item-action:hover,
    .list-group-item-success.list-group-item-action:focus {
      background-color: #b1dfbb;
      color: #155724;
    }

    .list-group-item-success.list-group-item-action.active {
      background-color: #155724;
      border-color: #155724;
      color: #fff;
    }

    .list-group-item-info {
      background-color: #bee5eb;
      color: #0c5460;
    }

    .list-group-item-info.list-group-item-action:hover,
    .list-group-item-info.list-group-item-action:focus {
      background-color: #abdde5;
      color: #0c5460;
    }

    .list-group-item-info.list-group-item-action.active {
      background-color: #0c5460;
      border-color: #0c5460;
      color: #fff;
    }

    .list-group-item-warning {
      background-color: #ffeeba;
      color: #856404;
    }

    .list-group-item-warning.list-group-item-action:hover,
    .list-group-item-warning.list-group-item-action:focus {
      background-color: #ffe8a1;
      color: #856404;
    }

    .list-group-item-warning.list-group-item-action.active {
      background-color: #856404;
      border-color: #856404;
      color: #fff;
    }

    .list-group-item-danger {
      background-color: #f5c6cb;
      color: #721c24;
    }

    .list-group-item-danger.list-group-item-action:hover,
    .list-group-item-danger.list-group-item-action:focus {
      background-color: #f1b0b7;
      color: #721c24;
    }

    .list-group-item-danger.list-group-item-action.active {
      background-color: #721c24;
      border-color: #721c24;
      color: #fff;
    }

    .list-group-item-light {
      background-color: #fdfdfe;
      color: #818182;
    }

    .list-group-item-light.list-group-item-action:hover,
    .list-group-item-light.list-group-item-action:focus {
      background-color: #ececf6;
      color: #818182;
    }

    .list-group-item-light.list-group-item-action.active {
      background-color: #818182;
      border-color: #818182;
      color: #fff;
    }

    .list-group-item-dark {
      background-color: #c6c8ca;
      color: #1b1e21;
    }

    .list-group-item-dark.list-group-item-action:hover,
    .list-group-item-dark.list-group-item-action:focus {
      background-color: #b9bbbe;
      color: #1b1e21;
    }

    .list-group-item-dark.list-group-item-action.active {
      background-color: #1b1e21;
      border-color: #1b1e21;
      color: #fff;
    }

    .close {
      color: #000;
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      opacity: 0.5;
      text-shadow: 0 1px 0 #fff;
    }

    .close:hover {
      color: #000;
      text-decoration: none;
    }

    .close:not(:disabled):not(.disabled):hover,
    .close:not(:disabled):not(.disabled):focus {
      opacity: 0.75;
    }

    button.close {
      background-color: transparent;
      border: 0;
      padding: 0;
    }

    a.close.disabled {
      pointer-events: none;
    }

    .toast {
      background-clip: padding-box;
      background-color: rgba(255, 255, 255, 0.85);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.25rem;
      box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
      flex-basis: 350px;
      font-size: 0.875rem;
      max-width: 350px;
      opacity: 0;
    }

    .toast:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    .toast.showing {
      opacity: 1;
    }

    .toast.show {
      display: block;
      opacity: 1;
    }

    .toast.hide {
      display: none;
    }

    .toast-header {
      align-items: center;
      background-clip: padding-box;
      background-color: rgba(255, 255, 255, 0.85);
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px);
      color: #6c757d;
      display: flex;
      padding: 0.25rem 0.75rem;
    }

    .toast-body {
      padding: 0.75rem;
    }

    .tooltip {
      display: block;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      letter-spacing: normal;
      line-break: auto;
      line-height: 1.5;
      margin: 0;
      opacity: 0;
      position: absolute;
      text-align: left;
      text-align: start;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      white-space: normal;
      word-break: normal;
      word-spacing: normal;
      word-wrap: break-word;
      z-index: 1070;
    }

    .tooltip.show {
      opacity: 0.9;
    }

    .tooltip .arrow {
      display: block;
      height: 0.4rem;
      position: absolute;
      width: 0.8rem;
    }

    .tooltip .arrow::before {
      border-color: transparent;
      border-style: solid;
      content: '';
      position: absolute;
    }

    .bs-tooltip-top,
    .bs-tooltip-auto[x-placement^='top'] {
      padding: 0.4rem 0;
    }

    .bs-tooltip-top .arrow,
    .bs-tooltip-auto[x-placement^='top'] .arrow {
      bottom: 0;
    }

    .bs-tooltip-top .arrow::before,
    .bs-tooltip-auto[x-placement^='top'] .arrow::before {
      border-top-color: #000;
      border-width: 0.4rem 0.4rem 0;
      top: 0;
    }

    .bs-tooltip-right,
    .bs-tooltip-auto[x-placement^='right'] {
      padding: 0 0.4rem;
    }

    .bs-tooltip-right .arrow,
    .bs-tooltip-auto[x-placement^='right'] .arrow {
      height: 0.8rem;
      left: 0;
      width: 0.4rem;
    }

    .bs-tooltip-right .arrow::before,
    .bs-tooltip-auto[x-placement^='right'] .arrow::before {
      border-right-color: #000;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      right: 0;
    }

    .bs-tooltip-bottom,
    .bs-tooltip-auto[x-placement^='bottom'] {
      padding: 0.4rem 0;
    }

    .bs-tooltip-bottom .arrow,
    .bs-tooltip-auto[x-placement^='bottom'] .arrow {
      top: 0;
    }

    .bs-tooltip-bottom .arrow::before,
    .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
      border-bottom-color: #000;
      border-width: 0 0.4rem 0.4rem;
      bottom: 0;
    }

    .bs-tooltip-left,
    .bs-tooltip-auto[x-placement^='left'] {
      padding: 0 0.4rem;
    }

    .bs-tooltip-left .arrow,
    .bs-tooltip-auto[x-placement^='left'] .arrow {
      height: 0.8rem;
      right: 0;
      width: 0.4rem;
    }

    .bs-tooltip-left .arrow::before,
    .bs-tooltip-auto[x-placement^='left'] .arrow::before {
      border-left-color: #000;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      left: 0;
    }

    .tooltip-inner {
      background-color: #000;
      border-radius: 0.25rem;
      color: #fff;
      max-width: 200px;
      padding: 0.25rem 0.5rem;
      text-align: center;
    }

    .popover {
      background-clip: padding-box;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      display: block;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      left: 0;
      letter-spacing: normal;
      line-break: auto;
      line-height: 1.5;
      max-width: 276px;
      position: absolute;
      text-align: left;
      text-align: start;
      text-decoration: none;
      text-shadow: none;
      text-transform: none;
      top: 0;
      white-space: normal;
      word-break: normal;
      word-spacing: normal;
      word-wrap: break-word;
      z-index: 1060;
    }

    .popover .arrow {
      display: block;
      height: 0.5rem;
      margin: 0 0.3rem;
      position: absolute;
      width: 1rem;
    }

    .popover .arrow::before,
    .popover .arrow::after {
      border-color: transparent;
      border-style: solid;
      content: '';
      display: block;
      position: absolute;
    }

    .bs-popover-top,
    .bs-popover-auto[x-placement^='top'] {
      margin-bottom: 0.5rem;
    }

    .bs-popover-top > .arrow,
    .bs-popover-auto[x-placement^='top'] > .arrow {
      bottom: calc(-0.5rem - 1px);
    }

    .bs-popover-top > .arrow::before,
    .bs-popover-auto[x-placement^='top'] > .arrow::before {
      border-top-color: rgba(0, 0, 0, 0.25);
      border-width: 0.5rem 0.5rem 0;
      bottom: 0;
    }

    .bs-popover-top > .arrow::after,
    .bs-popover-auto[x-placement^='top'] > .arrow::after {
      border-top-color: #fff;
      border-width: 0.5rem 0.5rem 0;
      bottom: 1px;
    }

    .bs-popover-right,
    .bs-popover-auto[x-placement^='right'] {
      margin-left: 0.5rem;
    }

    .bs-popover-right > .arrow,
    .bs-popover-auto[x-placement^='right'] > .arrow {
      height: 1rem;
      left: calc(-0.5rem - 1px);
      margin: 0.3rem 0;
      width: 0.5rem;
    }

    .bs-popover-right > .arrow::before,
    .bs-popover-auto[x-placement^='right'] > .arrow::before {
      border-right-color: rgba(0, 0, 0, 0.25);
      border-width: 0.5rem 0.5rem 0.5rem 0;
      left: 0;
    }

    .bs-popover-right > .arrow::after,
    .bs-popover-auto[x-placement^='right'] > .arrow::after {
      border-right-color: #fff;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      left: 1px;
    }

    .bs-popover-bottom,
    .bs-popover-auto[x-placement^='bottom'] {
      margin-top: 0.5rem;
    }

    .bs-popover-bottom > .arrow,
    .bs-popover-auto[x-placement^='bottom'] > .arrow {
      top: calc(-0.5rem - 1px);
    }

    .bs-popover-bottom > .arrow::before,
    .bs-popover-auto[x-placement^='bottom'] > .arrow::before {
      border-bottom-color: rgba(0, 0, 0, 0.25);
      border-width: 0 0.5rem 0.5rem 0.5rem;
      top: 0;
    }

    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^='bottom'] > .arrow::after {
      border-bottom-color: #fff;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      top: 1px;
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^='bottom'] .popover-header::before {
      border-bottom: 1px solid #f7f7f7;
      content: '';
      display: block;
      left: 50%;
      margin-left: -0.5rem;
      position: absolute;
      top: 0;
      width: 1rem;
    }

    .bs-popover-left,
    .bs-popover-auto[x-placement^='left'] {
      margin-right: 0.5rem;
    }

    .bs-popover-left > .arrow,
    .bs-popover-auto[x-placement^='left'] > .arrow {
      height: 1rem;
      margin: 0.3rem 0;
      right: calc(-0.5rem - 1px);
      width: 0.5rem;
    }

    .bs-popover-left > .arrow::before,
    .bs-popover-auto[x-placement^='left'] > .arrow::before {
      border-left-color: rgba(0, 0, 0, 0.25);
      border-width: 0.5rem 0 0.5rem 0.5rem;
      right: 0;
    }

    .bs-popover-left > .arrow::after,
    .bs-popover-auto[x-placement^='left'] > .arrow::after {
      border-left-color: #fff;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      right: 1px;
    }

    .popover-header {
      background-color: #f7f7f7;
      border-bottom: 1px solid #ebebeb;
      border-top-left-radius: calc(0.3rem - 1px);
      border-top-right-radius: calc(0.3rem - 1px);
      font-size: 1rem;
      margin-bottom: 0;
      padding: 0.5rem 0.75rem;
    }

    .popover-header:empty {
      display: none;
    }

    .popover-body {
      color: #212529;
      padding: 0.5rem 0.75rem;
    }

    @keyframes spinner-border {
      to {
        transform: rotate(360deg);
      }
    }

    .spinner-border {
      animation: spinner-border 0.75s linear infinite;
      border: 0.25em solid currentColor;
      border-radius: 50%;
      border-right-color: transparent;
      display: inline-block;
      height: 2rem;
      vertical-align: text-bottom;
      width: 2rem;
    }

    .spinner-border-sm {
      border-width: 0.2em;
      height: 1rem;
      width: 1rem;
    }

    @keyframes spinner-grow {
      0% {
        transform: scale(0);
      }
      50% {
        opacity: 1;
        transform: none;
      }
    }

    .spinner-grow {
      animation: spinner-grow 0.75s linear infinite;
      background-color: currentColor;
      border-radius: 50%;
      display: inline-block;
      height: 2rem;
      opacity: 0;
      vertical-align: text-bottom;
      width: 2rem;
    }

    .spinner-grow-sm {
      height: 1rem;
      width: 1rem;
    }

    .align-baseline {
      vertical-align: baseline !important;
    }

    .align-top {
      vertical-align: top !important;
    }

    .align-middle {
      vertical-align: middle !important;
    }

    .align-bottom {
      vertical-align: bottom !important;
    }

    .align-text-bottom {
      vertical-align: text-bottom !important;
    }

    .align-text-top {
      vertical-align: text-top !important;
    }

    .bg-primary {
      background-color: #007bff !important;
    }

    a.bg-primary:hover,
    a.bg-primary:focus,
    button.bg-primary:hover,
    button.bg-primary:focus {
      background-color: #0062cc !important;
    }

    .bg-secondary {
      background-color: #6c757d !important;
    }

    a.bg-secondary:hover,
    a.bg-secondary:focus,
    button.bg-secondary:hover,
    button.bg-secondary:focus {
      background-color: #545b62 !important;
    }

    .bg-success {
      background-color: #28a745 !important;
    }

    a.bg-success:hover,
    a.bg-success:focus,
    button.bg-success:hover,
    button.bg-success:focus {
      background-color: #1e7e34 !important;
    }

    .bg-info {
      background-color: #17a2b8 !important;
    }

    a.bg-info:hover,
    a.bg-info:focus,
    button.bg-info:hover,
    button.bg-info:focus {
      background-color: #117a8b !important;
    }

    .bg-warning {
      background-color: #ffc107 !important;
    }

    a.bg-warning:hover,
    a.bg-warning:focus,
    button.bg-warning:hover,
    button.bg-warning:focus {
      background-color: #d39e00 !important;
    }

    .bg-danger {
      background-color: #dc3545 !important;
    }

    a.bg-danger:hover,
    a.bg-danger:focus,
    button.bg-danger:hover,
    button.bg-danger:focus {
      background-color: #bd2130 !important;
    }

    .bg-light {
      background-color: #f8f9fa !important;
    }

    a.bg-light:hover,
    a.bg-light:focus,
    button.bg-light:hover,
    button.bg-light:focus {
      background-color: #dae0e5 !important;
    }

    .bg-dark {
      background-color: #343a40 !important;
    }

    a.bg-dark:hover,
    a.bg-dark:focus,
    button.bg-dark:hover,
    button.bg-dark:focus {
      background-color: #1d2124 !important;
    }

    .bg-white {
      background-color: #fff !important;
    }

    .bg-transparent {
      background-color: transparent !important;
    }

    .border {
      border: 1px solid #dee2e6 !important;
    }

    .border-top {
      border-top: 1px solid #dee2e6 !important;
    }

    .border-right {
      border-right: 1px solid #dee2e6 !important;
    }

    .border-bottom {
      border-bottom: 1px solid #dee2e6 !important;
    }

    .border-left {
      border-left: 1px solid #dee2e6 !important;
    }

    .border-0 {
      border: 0 !important;
    }

    .border-top-0 {
      border-top: 0 !important;
    }

    .border-right-0 {
      border-right: 0 !important;
    }

    .border-bottom-0 {
      border-bottom: 0 !important;
    }

    .border-left-0 {
      border-left: 0 !important;
    }

    .border-primary {
      border-color: #007bff !important;
    }

    .border-secondary {
      border-color: #6c757d !important;
    }

    .border-success {
      border-color: #28a745 !important;
    }

    .border-info {
      border-color: #17a2b8 !important;
    }

    .border-warning {
      border-color: #ffc107 !important;
    }

    .border-danger {
      border-color: #dc3545 !important;
    }

    .border-light {
      border-color: #f8f9fa !important;
    }

    .border-dark {
      border-color: #343a40 !important;
    }

    .border-white {
      border-color: #fff !important;
    }

    .rounded-sm {
      border-radius: 0.2rem !important;
    }

    .rounded {
      border-radius: 0.25rem !important;
    }

    .rounded-top {
      border-top-left-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }

    .rounded-right {
      border-bottom-right-radius: 0.25rem !important;
      border-top-right-radius: 0.25rem !important;
    }

    .rounded-bottom {
      border-bottom-left-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }

    .rounded-left {
      border-bottom-left-radius: 0.25rem !important;
      border-top-left-radius: 0.25rem !important;
    }

    .rounded-lg {
      border-radius: 0.3rem !important;
    }

    .rounded-circle {
      border-radius: 50% !important;
    }

    .rounded-pill {
      border-radius: 50rem !important;
    }

    .rounded-0 {
      border-radius: 0 !important;
    }

    .clearfix::after {
      clear: both;
      content: '';
      display: block;
    }

    .d-none {
      display: none !important;
    }

    .d-inline {
      display: inline !important;
    }

    .d-inline-block {
      display: inline-block !important;
    }

    .d-block {
      display: block !important;
    }

    .d-table {
      display: table !important;
    }

    .d-table-row {
      display: table-row !important;
    }

    .d-table-cell {
      display: table-cell !important;
    }

    .d-flex {
      display: flex !important;
    }

    .d-inline-flex {
      display: inline-flex !important;
    }

    @media (min-width: 576px) {
      .d-sm-none {
        display: none !important;
      }

      .d-sm-inline {
        display: inline !important;
      }

      .d-sm-inline-block {
        display: inline-block !important;
      }

      .d-sm-block {
        display: block !important;
      }

      .d-sm-table {
        display: table !important;
      }

      .d-sm-table-row {
        display: table-row !important;
      }

      .d-sm-table-cell {
        display: table-cell !important;
      }

      .d-sm-flex {
        display: flex !important;
      }

      .d-sm-inline-flex {
        display: inline-flex !important;
      }
    }
    @media (min-width: 768px) {
      .d-md-none {
        display: none !important;
      }

      .d-md-inline {
        display: inline !important;
      }

      .d-md-inline-block {
        display: inline-block !important;
      }

      .d-md-block {
        display: block !important;
      }

      .d-md-table {
        display: table !important;
      }

      .d-md-table-row {
        display: table-row !important;
      }

      .d-md-table-cell {
        display: table-cell !important;
      }

      .d-md-flex {
        display: flex !important;
      }

      .d-md-inline-flex {
        display: inline-flex !important;
      }
    }
    @media (min-width: 992px) {
      .d-lg-none {
        display: none !important;
      }

      .d-lg-inline {
        display: inline !important;
      }

      .d-lg-inline-block {
        display: inline-block !important;
      }

      .d-lg-block {
        display: block !important;
      }

      .d-lg-table {
        display: table !important;
      }

      .d-lg-table-row {
        display: table-row !important;
      }

      .d-lg-table-cell {
        display: table-cell !important;
      }

      .d-lg-flex {
        display: flex !important;
      }

      .d-lg-inline-flex {
        display: inline-flex !important;
      }
    }
    @media (min-width: 1200px) {
      .d-xl-none {
        display: none !important;
      }

      .d-xl-inline {
        display: inline !important;
      }

      .d-xl-inline-block {
        display: inline-block !important;
      }

      .d-xl-block {
        display: block !important;
      }

      .d-xl-table {
        display: table !important;
      }

      .d-xl-table-row {
        display: table-row !important;
      }

      .d-xl-table-cell {
        display: table-cell !important;
      }

      .d-xl-flex {
        display: flex !important;
      }

      .d-xl-inline-flex {
        display: inline-flex !important;
      }
    }
    @media print {
      .d-print-none {
        display: none !important;
      }

      .d-print-inline {
        display: inline !important;
      }

      .d-print-inline-block {
        display: inline-block !important;
      }

      .d-print-block {
        display: block !important;
      }

      .d-print-table {
        display: table !important;
      }

      .d-print-table-row {
        display: table-row !important;
      }

      .d-print-table-cell {
        display: table-cell !important;
      }

      .d-print-flex {
        display: flex !important;
      }

      .d-print-inline-flex {
        display: inline-flex !important;
      }
    }

    .embed-responsive {
      display: block;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 100%;
    }

    .embed-responsive::before {
      content: '';
      display: block;
    }

    .embed-responsive .embed-responsive-item,
    .embed-responsive iframe,
    .embed-responsive embed,
    .embed-responsive object,
    .embed-responsive video {
      border: 0;
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .embed-responsive-21by9::before {
      padding-top: 42.8571428571%;
    }

    .embed-responsive-16by9::before {
      padding-top: 56.25%;
    }

    .embed-responsive-4by3::before {
      padding-top: 75%;
    }

    .embed-responsive-1by1::before {
      padding-top: 100%;
    }

    .flex-row {
      flex-direction: row !important;
    }

    .flex-column {
      flex-direction: column !important;
    }

    .flex-row-reverse {
      flex-direction: row-reverse !important;
    }

    .flex-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex-wrap {
      flex-wrap: wrap !important;
    }

    .flex-nowrap {
      flex-wrap: nowrap !important;
    }

    .flex-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }

    .flex-fill {
      flex: 1 1 auto !important;
    }

    .flex-grow-0 {
      flex-grow: 0 !important;
    }

    .flex-grow-1 {
      flex-grow: 1 !important;
    }

    .flex-shrink-0 {
      flex-shrink: 0 !important;
    }

    .flex-shrink-1 {
      flex-shrink: 1 !important;
    }

    .justify-content-start {
      justify-content: flex-start !important;
    }

    .justify-content-end {
      justify-content: flex-end !important;
    }

    .justify-content-center {
      justify-content: center !important;
    }

    .justify-content-between {
      justify-content: space-between !important;
    }

    .justify-content-around {
      justify-content: space-around !important;
    }

    .align-items-start {
      align-items: flex-start !important;
    }

    .align-items-end {
      align-items: flex-end !important;
    }

    .align-items-center {
      align-items: center !important;
    }

    .align-items-baseline {
      align-items: baseline !important;
    }

    .align-items-stretch {
      align-items: stretch !important;
    }

    .align-content-start {
      align-content: flex-start !important;
    }

    .align-content-end {
      align-content: flex-end !important;
    }

    .align-content-center {
      align-content: center !important;
    }

    .align-content-between {
      align-content: space-between !important;
    }

    .align-content-around {
      align-content: space-around !important;
    }

    .align-content-stretch {
      align-content: stretch !important;
    }

    .align-self-auto {
      align-self: auto !important;
    }

    .align-self-start {
      align-self: flex-start !important;
    }

    .align-self-end {
      align-self: flex-end !important;
    }

    .align-self-center {
      align-self: center !important;
    }

    .align-self-baseline {
      align-self: baseline !important;
    }

    .align-self-stretch {
      align-self: stretch !important;
    }

    @media (min-width: 576px) {
      .flex-sm-row {
        flex-direction: row !important;
      }

      .flex-sm-column {
        flex-direction: column !important;
      }

      .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
      }

      .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
      }

      .flex-sm-wrap {
        flex-wrap: wrap !important;
      }

      .flex-sm-nowrap {
        flex-wrap: nowrap !important;
      }

      .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
      }

      .flex-sm-fill {
        flex: 1 1 auto !important;
      }

      .flex-sm-grow-0 {
        flex-grow: 0 !important;
      }

      .flex-sm-grow-1 {
        flex-grow: 1 !important;
      }

      .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
      }

      .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
      }

      .justify-content-sm-start {
        justify-content: flex-start !important;
      }

      .justify-content-sm-end {
        justify-content: flex-end !important;
      }

      .justify-content-sm-center {
        justify-content: center !important;
      }

      .justify-content-sm-between {
        justify-content: space-between !important;
      }

      .justify-content-sm-around {
        justify-content: space-around !important;
      }

      .align-items-sm-start {
        align-items: flex-start !important;
      }

      .align-items-sm-end {
        align-items: flex-end !important;
      }

      .align-items-sm-center {
        align-items: center !important;
      }

      .align-items-sm-baseline {
        align-items: baseline !important;
      }

      .align-items-sm-stretch {
        align-items: stretch !important;
      }

      .align-content-sm-start {
        align-content: flex-start !important;
      }

      .align-content-sm-end {
        align-content: flex-end !important;
      }

      .align-content-sm-center {
        align-content: center !important;
      }

      .align-content-sm-between {
        align-content: space-between !important;
      }

      .align-content-sm-around {
        align-content: space-around !important;
      }

      .align-content-sm-stretch {
        align-content: stretch !important;
      }

      .align-self-sm-auto {
        align-self: auto !important;
      }

      .align-self-sm-start {
        align-self: flex-start !important;
      }

      .align-self-sm-end {
        align-self: flex-end !important;
      }

      .align-self-sm-center {
        align-self: center !important;
      }

      .align-self-sm-baseline {
        align-self: baseline !important;
      }

      .align-self-sm-stretch {
        align-self: stretch !important;
      }
    }
    @media (min-width: 768px) {
      .flex-md-row {
        flex-direction: row !important;
      }

      .flex-md-column {
        flex-direction: column !important;
      }

      .flex-md-row-reverse {
        flex-direction: row-reverse !important;
      }

      .flex-md-column-reverse {
        flex-direction: column-reverse !important;
      }

      .flex-md-wrap {
        flex-wrap: wrap !important;
      }

      .flex-md-nowrap {
        flex-wrap: nowrap !important;
      }

      .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
      }

      .flex-md-fill {
        flex: 1 1 auto !important;
      }

      .flex-md-grow-0 {
        flex-grow: 0 !important;
      }

      .flex-md-grow-1 {
        flex-grow: 1 !important;
      }

      .flex-md-shrink-0 {
        flex-shrink: 0 !important;
      }

      .flex-md-shrink-1 {
        flex-shrink: 1 !important;
      }

      .justify-content-md-start {
        justify-content: flex-start !important;
      }

      .justify-content-md-end {
        justify-content: flex-end !important;
      }

      .justify-content-md-center {
        justify-content: center !important;
      }

      .justify-content-md-between {
        justify-content: space-between !important;
      }

      .justify-content-md-around {
        justify-content: space-around !important;
      }

      .align-items-md-start {
        align-items: flex-start !important;
      }

      .align-items-md-end {
        align-items: flex-end !important;
      }

      .align-items-md-center {
        align-items: center !important;
      }

      .align-items-md-baseline {
        align-items: baseline !important;
      }

      .align-items-md-stretch {
        align-items: stretch !important;
      }

      .align-content-md-start {
        align-content: flex-start !important;
      }

      .align-content-md-end {
        align-content: flex-end !important;
      }

      .align-content-md-center {
        align-content: center !important;
      }

      .align-content-md-between {
        align-content: space-between !important;
      }

      .align-content-md-around {
        align-content: space-around !important;
      }

      .align-content-md-stretch {
        align-content: stretch !important;
      }

      .align-self-md-auto {
        align-self: auto !important;
      }

      .align-self-md-start {
        align-self: flex-start !important;
      }

      .align-self-md-end {
        align-self: flex-end !important;
      }

      .align-self-md-center {
        align-self: center !important;
      }

      .align-self-md-baseline {
        align-self: baseline !important;
      }

      .align-self-md-stretch {
        align-self: stretch !important;
      }
    }
    @media (min-width: 992px) {
      .flex-lg-row {
        flex-direction: row !important;
      }

      .flex-lg-column {
        flex-direction: column !important;
      }

      .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
      }

      .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
      }

      .flex-lg-wrap {
        flex-wrap: wrap !important;
      }

      .flex-lg-nowrap {
        flex-wrap: nowrap !important;
      }

      .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
      }

      .flex-lg-fill {
        flex: 1 1 auto !important;
      }

      .flex-lg-grow-0 {
        flex-grow: 0 !important;
      }

      .flex-lg-grow-1 {
        flex-grow: 1 !important;
      }

      .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
      }

      .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
      }

      .justify-content-lg-start {
        justify-content: flex-start !important;
      }

      .justify-content-lg-end {
        justify-content: flex-end !important;
      }

      .justify-content-lg-center {
        justify-content: center !important;
      }

      .justify-content-lg-between {
        justify-content: space-between !important;
      }

      .justify-content-lg-around {
        justify-content: space-around !important;
      }

      .align-items-lg-start {
        align-items: flex-start !important;
      }

      .align-items-lg-end {
        align-items: flex-end !important;
      }

      .align-items-lg-center {
        align-items: center !important;
      }

      .align-items-lg-baseline {
        align-items: baseline !important;
      }

      .align-items-lg-stretch {
        align-items: stretch !important;
      }

      .align-content-lg-start {
        align-content: flex-start !important;
      }

      .align-content-lg-end {
        align-content: flex-end !important;
      }

      .align-content-lg-center {
        align-content: center !important;
      }

      .align-content-lg-between {
        align-content: space-between !important;
      }

      .align-content-lg-around {
        align-content: space-around !important;
      }

      .align-content-lg-stretch {
        align-content: stretch !important;
      }

      .align-self-lg-auto {
        align-self: auto !important;
      }

      .align-self-lg-start {
        align-self: flex-start !important;
      }

      .align-self-lg-end {
        align-self: flex-end !important;
      }

      .align-self-lg-center {
        align-self: center !important;
      }

      .align-self-lg-baseline {
        align-self: baseline !important;
      }

      .align-self-lg-stretch {
        align-self: stretch !important;
      }
    }
    @media (min-width: 1200px) {
      .flex-xl-row {
        flex-direction: row !important;
      }

      .flex-xl-column {
        flex-direction: column !important;
      }

      .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
      }

      .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
      }

      .flex-xl-wrap {
        flex-wrap: wrap !important;
      }

      .flex-xl-nowrap {
        flex-wrap: nowrap !important;
      }

      .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
      }

      .flex-xl-fill {
        flex: 1 1 auto !important;
      }

      .flex-xl-grow-0 {
        flex-grow: 0 !important;
      }

      .flex-xl-grow-1 {
        flex-grow: 1 !important;
      }

      .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
      }

      .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
      }

      .justify-content-xl-start {
        justify-content: flex-start !important;
      }

      .justify-content-xl-end {
        justify-content: flex-end !important;
      }

      .justify-content-xl-center {
        justify-content: center !important;
      }

      .justify-content-xl-between {
        justify-content: space-between !important;
      }

      .justify-content-xl-around {
        justify-content: space-around !important;
      }

      .align-items-xl-start {
        align-items: flex-start !important;
      }

      .align-items-xl-end {
        align-items: flex-end !important;
      }

      .align-items-xl-center {
        align-items: center !important;
      }

      .align-items-xl-baseline {
        align-items: baseline !important;
      }

      .align-items-xl-stretch {
        align-items: stretch !important;
      }

      .align-content-xl-start {
        align-content: flex-start !important;
      }

      .align-content-xl-end {
        align-content: flex-end !important;
      }

      .align-content-xl-center {
        align-content: center !important;
      }

      .align-content-xl-between {
        align-content: space-between !important;
      }

      .align-content-xl-around {
        align-content: space-around !important;
      }

      .align-content-xl-stretch {
        align-content: stretch !important;
      }

      .align-self-xl-auto {
        align-self: auto !important;
      }

      .align-self-xl-start {
        align-self: flex-start !important;
      }

      .align-self-xl-end {
        align-self: flex-end !important;
      }

      .align-self-xl-center {
        align-self: center !important;
      }

      .align-self-xl-baseline {
        align-self: baseline !important;
      }

      .align-self-xl-stretch {
        align-self: stretch !important;
      }
    }

    .float-left {
      float: left !important;
    }

    .float-right {
      float: right !important;
    }

    .float-none {
      float: none !important;
    }

    @media (min-width: 576px) {
      .float-sm-left {
        float: left !important;
      }

      .float-sm-right {
        float: right !important;
      }

      .float-sm-none {
        float: none !important;
      }
    }
    @media (min-width: 768px) {
      .float-md-left {
        float: left !important;
      }

      .float-md-right {
        float: right !important;
      }

      .float-md-none {
        float: none !important;
      }
    }
    @media (min-width: 992px) {
      .float-lg-left {
        float: left !important;
      }

      .float-lg-right {
        float: right !important;
      }

      .float-lg-none {
        float: none !important;
      }
    }
    @media (min-width: 1200px) {
      .float-xl-left {
        float: left !important;
      }

      .float-xl-right {
        float: right !important;
      }

      .float-xl-none {
        float: none !important;
      }
    }

    .user-select-all {
      user-select: all !important;
    }

    .user-select-auto {
      user-select: auto !important;
    }

    .user-select-none {
      user-select: none !important;
    }

    .overflow-auto {
      overflow: auto !important;
    }

    .overflow-hidden {
      overflow: hidden !important;
    }

    .position-static {
      position: static !important;
    }

    .position-relative {
      position: relative !important;
    }

    .position-absolute {
      position: absolute !important;
    }

    .position-fixed {
      position: fixed !important;
    }

    .position-sticky {
      position: sticky !important;
    }

    .fixed-top {
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1030;
    }

    .fixed-bottom {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      z-index: 1030;
    }

    @supports (position: sticky) {
      .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
      }
    }

    .sr-only {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    .sr-only-focusable:active,
    .sr-only-focusable:focus {
      clip: auto;
      height: auto;
      overflow: visible;
      position: static;
      white-space: normal;
      width: auto;
    }

    .shadow-sm {
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }

    .shadow {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .shadow-lg {
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    }

    .shadow-none {
      box-shadow: none !important;
    }

    .w-25 {
      width: 25% !important;
    }

    .w-50 {
      width: 50% !important;
    }

    .w-75 {
      width: 75% !important;
    }

    .w-100 {
      width: 100% !important;
    }

    .w-auto {
      width: auto !important;
    }

    .h-25 {
      height: 25% !important;
    }

    .h-50 {
      height: 50% !important;
    }

    .h-75 {
      height: 75% !important;
    }

    .h-100 {
      height: 100% !important;
    }

    .h-auto {
      height: auto !important;
    }

    .mw-100 {
      max-width: 100% !important;
    }

    .mh-100 {
      max-height: 100% !important;
    }

    .min-vw-100 {
      min-width: 100vw !important;
    }

    .min-vh-100 {
      min-height: 100vh !important;
    }

    .vw-100 {
      width: 100vw !important;
    }

    .vh-100 {
      height: 100vh !important;
    }

    .m-0 {
      margin: 0 !important;
    }

    .mt-0,
    .my-0 {
      margin-top: 0 !important;
    }

    .mr-0,
    .mx-0 {
      margin-right: 0 !important;
    }

    .mb-0,
    .my-0 {
      margin-bottom: 0 !important;
    }

    .ml-0,
    .mx-0 {
      margin-left: 0 !important;
    }

    .m-1 {
      margin: 0.25rem !important;
    }

    .mt-1,
    .my-1 {
      margin-top: 0.25rem !important;
    }

    .mr-1,
    .mx-1 {
      margin-right: 0.25rem !important;
    }

    .mb-1,
    .my-1 {
      margin-bottom: 0.25rem !important;
    }

    .ml-1,
    .mx-1 {
      margin-left: 0.25rem !important;
    }

    .m-2 {
      margin: 0.5rem !important;
    }

    .mt-2,
    .my-2 {
      margin-top: 0.5rem !important;
    }

    .mr-2,
    .mx-2 {
      margin-right: 0.5rem !important;
    }

    .mb-2,
    .my-2 {
      margin-bottom: 0.5rem !important;
    }

    .ml-2,
    .mx-2 {
      margin-left: 0.5rem !important;
    }

    .m-3 {
      margin: 1rem !important;
    }

    .mt-3,
    .my-3 {
      margin-top: 1rem !important;
    }

    .mr-3,
    .mx-3 {
      margin-right: 1rem !important;
    }

    .mb-3,
    .my-3 {
      margin-bottom: 1rem !important;
    }

    .ml-3,
    .mx-3 {
      margin-left: 1rem !important;
    }

    .m-4 {
      margin: 1.5rem !important;
    }

    .mt-4,
    .my-4 {
      margin-top: 1.5rem !important;
    }

    .mr-4,
    .mx-4 {
      margin-right: 1.5rem !important;
    }

    .mb-4,
    .my-4 {
      margin-bottom: 1.5rem !important;
    }

    .ml-4,
    .mx-4 {
      margin-left: 1.5rem !important;
    }

    .m-5 {
      margin: 3rem !important;
    }

    .mt-5,
    .my-5 {
      margin-top: 3rem !important;
    }

    .mr-5,
    .mx-5 {
      margin-right: 3rem !important;
    }

    .mb-5,
    .my-5 {
      margin-bottom: 3rem !important;
    }

    .ml-5,
    .mx-5 {
      margin-left: 3rem !important;
    }

    .p-0 {
      padding: 0 !important;
    }

    .pt-0,
    .py-0 {
      padding-top: 0 !important;
    }

    .pr-0,
    .px-0 {
      padding-right: 0 !important;
    }

    .pb-0,
    .py-0 {
      padding-bottom: 0 !important;
    }

    .pl-0,
    .px-0 {
      padding-left: 0 !important;
    }

    .p-1 {
      padding: 0.25rem !important;
    }

    .pt-1,
    .py-1 {
      padding-top: 0.25rem !important;
    }

    .pr-1,
    .px-1 {
      padding-right: 0.25rem !important;
    }

    .pb-1,
    .py-1 {
      padding-bottom: 0.25rem !important;
    }

    .pl-1,
    .px-1 {
      padding-left: 0.25rem !important;
    }

    .p-2 {
      padding: 0.5rem !important;
    }

    .pt-2,
    .py-2 {
      padding-top: 0.5rem !important;
    }

    .pr-2,
    .px-2 {
      padding-right: 0.5rem !important;
    }

    .pb-2,
    .py-2 {
      padding-bottom: 0.5rem !important;
    }

    .pl-2,
    .px-2 {
      padding-left: 0.5rem !important;
    }

    .p-3 {
      padding: 1rem !important;
    }

    .pt-3,
    .py-3 {
      padding-top: 1rem !important;
    }

    .pr-3,
    .px-3 {
      padding-right: 1rem !important;
    }

    .pb-3,
    .py-3 {
      padding-bottom: 1rem !important;
    }

    .pl-3,
    .px-3 {
      padding-left: 1rem !important;
    }

    .p-4 {
      padding: 1.5rem !important;
    }

    .pt-4,
    .py-4 {
      padding-top: 1.5rem !important;
    }

    .pr-4,
    .px-4 {
      padding-right: 1.5rem !important;
    }

    .pb-4,
    .py-4 {
      padding-bottom: 1.5rem !important;
    }

    .pl-4,
    .px-4 {
      padding-left: 1.5rem !important;
    }

    .p-5 {
      padding: 3rem !important;
    }

    .pt-5,
    .py-5 {
      padding-top: 3rem !important;
    }

    .pr-5,
    .px-5 {
      padding-right: 3rem !important;
    }

    .pb-5,
    .py-5 {
      padding-bottom: 3rem !important;
    }

    .pl-5,
    .px-5 {
      padding-left: 3rem !important;
    }

    .m-n1 {
      margin: -0.25rem !important;
    }

    .mt-n1,
    .my-n1 {
      margin-top: -0.25rem !important;
    }

    .mr-n1,
    .mx-n1 {
      margin-right: -0.25rem !important;
    }

    .mb-n1,
    .my-n1 {
      margin-bottom: -0.25rem !important;
    }

    .ml-n1,
    .mx-n1 {
      margin-left: -0.25rem !important;
    }

    .m-n2 {
      margin: -0.5rem !important;
    }

    .mt-n2,
    .my-n2 {
      margin-top: -0.5rem !important;
    }

    .mr-n2,
    .mx-n2 {
      margin-right: -0.5rem !important;
    }

    .mb-n2,
    .my-n2 {
      margin-bottom: -0.5rem !important;
    }

    .ml-n2,
    .mx-n2 {
      margin-left: -0.5rem !important;
    }

    .m-n3 {
      margin: -1rem !important;
    }

    .mt-n3,
    .my-n3 {
      margin-top: -1rem !important;
    }

    .mr-n3,
    .mx-n3 {
      margin-right: -1rem !important;
    }

    .mb-n3,
    .my-n3 {
      margin-bottom: -1rem !important;
    }

    .ml-n3,
    .mx-n3 {
      margin-left: -1rem !important;
    }

    .m-n4 {
      margin: -1.5rem !important;
    }

    .mt-n4,
    .my-n4 {
      margin-top: -1.5rem !important;
    }

    .mr-n4,
    .mx-n4 {
      margin-right: -1.5rem !important;
    }

    .mb-n4,
    .my-n4 {
      margin-bottom: -1.5rem !important;
    }

    .ml-n4,
    .mx-n4 {
      margin-left: -1.5rem !important;
    }

    .m-n5 {
      margin: -3rem !important;
    }

    .mt-n5,
    .my-n5 {
      margin-top: -3rem !important;
    }

    .mr-n5,
    .mx-n5 {
      margin-right: -3rem !important;
    }

    .mb-n5,
    .my-n5 {
      margin-bottom: -3rem !important;
    }

    .ml-n5,
    .mx-n5 {
      margin-left: -3rem !important;
    }

    .m-auto {
      margin: auto !important;
    }

    .mt-auto,
    .my-auto {
      margin-top: auto !important;
    }

    .mr-auto,
    .mx-auto {
      margin-right: auto !important;
    }

    .mb-auto,
    .my-auto {
      margin-bottom: auto !important;
    }

    .ml-auto,
    .mx-auto {
      margin-left: auto !important;
    }

    @media (min-width: 576px) {
      .m-sm-0 {
        margin: 0 !important;
      }

      .mt-sm-0,
      .my-sm-0 {
        margin-top: 0 !important;
      }

      .mr-sm-0,
      .mx-sm-0 {
        margin-right: 0 !important;
      }

      .mb-sm-0,
      .my-sm-0 {
        margin-bottom: 0 !important;
      }

      .ml-sm-0,
      .mx-sm-0 {
        margin-left: 0 !important;
      }

      .m-sm-1 {
        margin: 0.25rem !important;
      }

      .mt-sm-1,
      .my-sm-1 {
        margin-top: 0.25rem !important;
      }

      .mr-sm-1,
      .mx-sm-1 {
        margin-right: 0.25rem !important;
      }

      .mb-sm-1,
      .my-sm-1 {
        margin-bottom: 0.25rem !important;
      }

      .ml-sm-1,
      .mx-sm-1 {
        margin-left: 0.25rem !important;
      }

      .m-sm-2 {
        margin: 0.5rem !important;
      }

      .mt-sm-2,
      .my-sm-2 {
        margin-top: 0.5rem !important;
      }

      .mr-sm-2,
      .mx-sm-2 {
        margin-right: 0.5rem !important;
      }

      .mb-sm-2,
      .my-sm-2 {
        margin-bottom: 0.5rem !important;
      }

      .ml-sm-2,
      .mx-sm-2 {
        margin-left: 0.5rem !important;
      }

      .m-sm-3 {
        margin: 1rem !important;
      }

      .mt-sm-3,
      .my-sm-3 {
        margin-top: 1rem !important;
      }

      .mr-sm-3,
      .mx-sm-3 {
        margin-right: 1rem !important;
      }

      .mb-sm-3,
      .my-sm-3 {
        margin-bottom: 1rem !important;
      }

      .ml-sm-3,
      .mx-sm-3 {
        margin-left: 1rem !important;
      }

      .m-sm-4 {
        margin: 1.5rem !important;
      }

      .mt-sm-4,
      .my-sm-4 {
        margin-top: 1.5rem !important;
      }

      .mr-sm-4,
      .mx-sm-4 {
        margin-right: 1.5rem !important;
      }

      .mb-sm-4,
      .my-sm-4 {
        margin-bottom: 1.5rem !important;
      }

      .ml-sm-4,
      .mx-sm-4 {
        margin-left: 1.5rem !important;
      }

      .m-sm-5 {
        margin: 3rem !important;
      }

      .mt-sm-5,
      .my-sm-5 {
        margin-top: 3rem !important;
      }

      .mr-sm-5,
      .mx-sm-5 {
        margin-right: 3rem !important;
      }

      .mb-sm-5,
      .my-sm-5 {
        margin-bottom: 3rem !important;
      }

      .ml-sm-5,
      .mx-sm-5 {
        margin-left: 3rem !important;
      }

      .p-sm-0 {
        padding: 0 !important;
      }

      .pt-sm-0,
      .py-sm-0 {
        padding-top: 0 !important;
      }

      .pr-sm-0,
      .px-sm-0 {
        padding-right: 0 !important;
      }

      .pb-sm-0,
      .py-sm-0 {
        padding-bottom: 0 !important;
      }

      .pl-sm-0,
      .px-sm-0 {
        padding-left: 0 !important;
      }

      .p-sm-1 {
        padding: 0.25rem !important;
      }

      .pt-sm-1,
      .py-sm-1 {
        padding-top: 0.25rem !important;
      }

      .pr-sm-1,
      .px-sm-1 {
        padding-right: 0.25rem !important;
      }

      .pb-sm-1,
      .py-sm-1 {
        padding-bottom: 0.25rem !important;
      }

      .pl-sm-1,
      .px-sm-1 {
        padding-left: 0.25rem !important;
      }

      .p-sm-2 {
        padding: 0.5rem !important;
      }

      .pt-sm-2,
      .py-sm-2 {
        padding-top: 0.5rem !important;
      }

      .pr-sm-2,
      .px-sm-2 {
        padding-right: 0.5rem !important;
      }

      .pb-sm-2,
      .py-sm-2 {
        padding-bottom: 0.5rem !important;
      }

      .pl-sm-2,
      .px-sm-2 {
        padding-left: 0.5rem !important;
      }

      .p-sm-3 {
        padding: 1rem !important;
      }

      .pt-sm-3,
      .py-sm-3 {
        padding-top: 1rem !important;
      }

      .pr-sm-3,
      .px-sm-3 {
        padding-right: 1rem !important;
      }

      .pb-sm-3,
      .py-sm-3 {
        padding-bottom: 1rem !important;
      }

      .pl-sm-3,
      .px-sm-3 {
        padding-left: 1rem !important;
      }

      .p-sm-4 {
        padding: 1.5rem !important;
      }

      .pt-sm-4,
      .py-sm-4 {
        padding-top: 1.5rem !important;
      }

      .pr-sm-4,
      .px-sm-4 {
        padding-right: 1.5rem !important;
      }

      .pb-sm-4,
      .py-sm-4 {
        padding-bottom: 1.5rem !important;
      }

      .pl-sm-4,
      .px-sm-4 {
        padding-left: 1.5rem !important;
      }

      .p-sm-5 {
        padding: 3rem !important;
      }

      .pt-sm-5,
      .py-sm-5 {
        padding-top: 3rem !important;
      }

      .pr-sm-5,
      .px-sm-5 {
        padding-right: 3rem !important;
      }

      .pb-sm-5,
      .py-sm-5 {
        padding-bottom: 3rem !important;
      }

      .pl-sm-5,
      .px-sm-5 {
        padding-left: 3rem !important;
      }

      .m-sm-n1 {
        margin: -0.25rem !important;
      }

      .mt-sm-n1,
      .my-sm-n1 {
        margin-top: -0.25rem !important;
      }

      .mr-sm-n1,
      .mx-sm-n1 {
        margin-right: -0.25rem !important;
      }

      .mb-sm-n1,
      .my-sm-n1 {
        margin-bottom: -0.25rem !important;
      }

      .ml-sm-n1,
      .mx-sm-n1 {
        margin-left: -0.25rem !important;
      }

      .m-sm-n2 {
        margin: -0.5rem !important;
      }

      .mt-sm-n2,
      .my-sm-n2 {
        margin-top: -0.5rem !important;
      }

      .mr-sm-n2,
      .mx-sm-n2 {
        margin-right: -0.5rem !important;
      }

      .mb-sm-n2,
      .my-sm-n2 {
        margin-bottom: -0.5rem !important;
      }

      .ml-sm-n2,
      .mx-sm-n2 {
        margin-left: -0.5rem !important;
      }

      .m-sm-n3 {
        margin: -1rem !important;
      }

      .mt-sm-n3,
      .my-sm-n3 {
        margin-top: -1rem !important;
      }

      .mr-sm-n3,
      .mx-sm-n3 {
        margin-right: -1rem !important;
      }

      .mb-sm-n3,
      .my-sm-n3 {
        margin-bottom: -1rem !important;
      }

      .ml-sm-n3,
      .mx-sm-n3 {
        margin-left: -1rem !important;
      }

      .m-sm-n4 {
        margin: -1.5rem !important;
      }

      .mt-sm-n4,
      .my-sm-n4 {
        margin-top: -1.5rem !important;
      }

      .mr-sm-n4,
      .mx-sm-n4 {
        margin-right: -1.5rem !important;
      }

      .mb-sm-n4,
      .my-sm-n4 {
        margin-bottom: -1.5rem !important;
      }

      .ml-sm-n4,
      .mx-sm-n4 {
        margin-left: -1.5rem !important;
      }

      .m-sm-n5 {
        margin: -3rem !important;
      }

      .mt-sm-n5,
      .my-sm-n5 {
        margin-top: -3rem !important;
      }

      .mr-sm-n5,
      .mx-sm-n5 {
        margin-right: -3rem !important;
      }

      .mb-sm-n5,
      .my-sm-n5 {
        margin-bottom: -3rem !important;
      }

      .ml-sm-n5,
      .mx-sm-n5 {
        margin-left: -3rem !important;
      }

      .m-sm-auto {
        margin: auto !important;
      }

      .mt-sm-auto,
      .my-sm-auto {
        margin-top: auto !important;
      }

      .mr-sm-auto,
      .mx-sm-auto {
        margin-right: auto !important;
      }

      .mb-sm-auto,
      .my-sm-auto {
        margin-bottom: auto !important;
      }

      .ml-sm-auto,
      .mx-sm-auto {
        margin-left: auto !important;
      }
    }
    @media (min-width: 768px) {
      .m-md-0 {
        margin: 0 !important;
      }

      .mt-md-0,
      .my-md-0 {
        margin-top: 0 !important;
      }

      .mr-md-0,
      .mx-md-0 {
        margin-right: 0 !important;
      }

      .mb-md-0,
      .my-md-0 {
        margin-bottom: 0 !important;
      }

      .ml-md-0,
      .mx-md-0 {
        margin-left: 0 !important;
      }

      .m-md-1 {
        margin: 0.25rem !important;
      }

      .mt-md-1,
      .my-md-1 {
        margin-top: 0.25rem !important;
      }

      .mr-md-1,
      .mx-md-1 {
        margin-right: 0.25rem !important;
      }

      .mb-md-1,
      .my-md-1 {
        margin-bottom: 0.25rem !important;
      }

      .ml-md-1,
      .mx-md-1 {
        margin-left: 0.25rem !important;
      }

      .m-md-2 {
        margin: 0.5rem !important;
      }

      .mt-md-2,
      .my-md-2 {
        margin-top: 0.5rem !important;
      }

      .mr-md-2,
      .mx-md-2 {
        margin-right: 0.5rem !important;
      }

      .mb-md-2,
      .my-md-2 {
        margin-bottom: 0.5rem !important;
      }

      .ml-md-2,
      .mx-md-2 {
        margin-left: 0.5rem !important;
      }

      .m-md-3 {
        margin: 1rem !important;
      }

      .mt-md-3,
      .my-md-3 {
        margin-top: 1rem !important;
      }

      .mr-md-3,
      .mx-md-3 {
        margin-right: 1rem !important;
      }

      .mb-md-3,
      .my-md-3 {
        margin-bottom: 1rem !important;
      }

      .ml-md-3,
      .mx-md-3 {
        margin-left: 1rem !important;
      }

      .m-md-4 {
        margin: 1.5rem !important;
      }

      .mt-md-4,
      .my-md-4 {
        margin-top: 1.5rem !important;
      }

      .mr-md-4,
      .mx-md-4 {
        margin-right: 1.5rem !important;
      }

      .mb-md-4,
      .my-md-4 {
        margin-bottom: 1.5rem !important;
      }

      .ml-md-4,
      .mx-md-4 {
        margin-left: 1.5rem !important;
      }

      .m-md-5 {
        margin: 3rem !important;
      }

      .mt-md-5,
      .my-md-5 {
        margin-top: 3rem !important;
      }

      .mr-md-5,
      .mx-md-5 {
        margin-right: 3rem !important;
      }

      .mb-md-5,
      .my-md-5 {
        margin-bottom: 3rem !important;
      }

      .ml-md-5,
      .mx-md-5 {
        margin-left: 3rem !important;
      }

      .p-md-0 {
        padding: 0 !important;
      }

      .pt-md-0,
      .py-md-0 {
        padding-top: 0 !important;
      }

      .pr-md-0,
      .px-md-0 {
        padding-right: 0 !important;
      }

      .pb-md-0,
      .py-md-0 {
        padding-bottom: 0 !important;
      }

      .pl-md-0,
      .px-md-0 {
        padding-left: 0 !important;
      }

      .p-md-1 {
        padding: 0.25rem !important;
      }

      .pt-md-1,
      .py-md-1 {
        padding-top: 0.25rem !important;
      }

      .pr-md-1,
      .px-md-1 {
        padding-right: 0.25rem !important;
      }

      .pb-md-1,
      .py-md-1 {
        padding-bottom: 0.25rem !important;
      }

      .pl-md-1,
      .px-md-1 {
        padding-left: 0.25rem !important;
      }

      .p-md-2 {
        padding: 0.5rem !important;
      }

      .pt-md-2,
      .py-md-2 {
        padding-top: 0.5rem !important;
      }

      .pr-md-2,
      .px-md-2 {
        padding-right: 0.5rem !important;
      }

      .pb-md-2,
      .py-md-2 {
        padding-bottom: 0.5rem !important;
      }

      .pl-md-2,
      .px-md-2 {
        padding-left: 0.5rem !important;
      }

      .p-md-3 {
        padding: 1rem !important;
      }

      .pt-md-3,
      .py-md-3 {
        padding-top: 1rem !important;
      }

      .pr-md-3,
      .px-md-3 {
        padding-right: 1rem !important;
      }

      .pb-md-3,
      .py-md-3 {
        padding-bottom: 1rem !important;
      }

      .pl-md-3,
      .px-md-3 {
        padding-left: 1rem !important;
      }

      .p-md-4 {
        padding: 1.5rem !important;
      }

      .pt-md-4,
      .py-md-4 {
        padding-top: 1.5rem !important;
      }

      .pr-md-4,
      .px-md-4 {
        padding-right: 1.5rem !important;
      }

      .pb-md-4,
      .py-md-4 {
        padding-bottom: 1.5rem !important;
      }

      .pl-md-4,
      .px-md-4 {
        padding-left: 1.5rem !important;
      }

      .p-md-5 {
        padding: 3rem !important;
      }

      .pt-md-5,
      .py-md-5 {
        padding-top: 3rem !important;
      }

      .pr-md-5,
      .px-md-5 {
        padding-right: 3rem !important;
      }

      .pb-md-5,
      .py-md-5 {
        padding-bottom: 3rem !important;
      }

      .pl-md-5,
      .px-md-5 {
        padding-left: 3rem !important;
      }

      .m-md-n1 {
        margin: -0.25rem !important;
      }

      .mt-md-n1,
      .my-md-n1 {
        margin-top: -0.25rem !important;
      }

      .mr-md-n1,
      .mx-md-n1 {
        margin-right: -0.25rem !important;
      }

      .mb-md-n1,
      .my-md-n1 {
        margin-bottom: -0.25rem !important;
      }

      .ml-md-n1,
      .mx-md-n1 {
        margin-left: -0.25rem !important;
      }

      .m-md-n2 {
        margin: -0.5rem !important;
      }

      .mt-md-n2,
      .my-md-n2 {
        margin-top: -0.5rem !important;
      }

      .mr-md-n2,
      .mx-md-n2 {
        margin-right: -0.5rem !important;
      }

      .mb-md-n2,
      .my-md-n2 {
        margin-bottom: -0.5rem !important;
      }

      .ml-md-n2,
      .mx-md-n2 {
        margin-left: -0.5rem !important;
      }

      .m-md-n3 {
        margin: -1rem !important;
      }

      .mt-md-n3,
      .my-md-n3 {
        margin-top: -1rem !important;
      }

      .mr-md-n3,
      .mx-md-n3 {
        margin-right: -1rem !important;
      }

      .mb-md-n3,
      .my-md-n3 {
        margin-bottom: -1rem !important;
      }

      .ml-md-n3,
      .mx-md-n3 {
        margin-left: -1rem !important;
      }

      .m-md-n4 {
        margin: -1.5rem !important;
      }

      .mt-md-n4,
      .my-md-n4 {
        margin-top: -1.5rem !important;
      }

      .mr-md-n4,
      .mx-md-n4 {
        margin-right: -1.5rem !important;
      }

      .mb-md-n4,
      .my-md-n4 {
        margin-bottom: -1.5rem !important;
      }

      .ml-md-n4,
      .mx-md-n4 {
        margin-left: -1.5rem !important;
      }

      .m-md-n5 {
        margin: -3rem !important;
      }

      .mt-md-n5,
      .my-md-n5 {
        margin-top: -3rem !important;
      }

      .mr-md-n5,
      .mx-md-n5 {
        margin-right: -3rem !important;
      }

      .mb-md-n5,
      .my-md-n5 {
        margin-bottom: -3rem !important;
      }

      .ml-md-n5,
      .mx-md-n5 {
        margin-left: -3rem !important;
      }

      .m-md-auto {
        margin: auto !important;
      }

      .mt-md-auto,
      .my-md-auto {
        margin-top: auto !important;
      }

      .mr-md-auto,
      .mx-md-auto {
        margin-right: auto !important;
      }

      .mb-md-auto,
      .my-md-auto {
        margin-bottom: auto !important;
      }

      .ml-md-auto,
      .mx-md-auto {
        margin-left: auto !important;
      }
    }
    @media (min-width: 992px) {
      .m-lg-0 {
        margin: 0 !important;
      }

      .mt-lg-0,
      .my-lg-0 {
        margin-top: 0 !important;
      }

      .mr-lg-0,
      .mx-lg-0 {
        margin-right: 0 !important;
      }

      .mb-lg-0,
      .my-lg-0 {
        margin-bottom: 0 !important;
      }

      .ml-lg-0,
      .mx-lg-0 {
        margin-left: 0 !important;
      }

      .m-lg-1 {
        margin: 0.25rem !important;
      }

      .mt-lg-1,
      .my-lg-1 {
        margin-top: 0.25rem !important;
      }

      .mr-lg-1,
      .mx-lg-1 {
        margin-right: 0.25rem !important;
      }

      .mb-lg-1,
      .my-lg-1 {
        margin-bottom: 0.25rem !important;
      }

      .ml-lg-1,
      .mx-lg-1 {
        margin-left: 0.25rem !important;
      }

      .m-lg-2 {
        margin: 0.5rem !important;
      }

      .mt-lg-2,
      .my-lg-2 {
        margin-top: 0.5rem !important;
      }

      .mr-lg-2,
      .mx-lg-2 {
        margin-right: 0.5rem !important;
      }

      .mb-lg-2,
      .my-lg-2 {
        margin-bottom: 0.5rem !important;
      }

      .ml-lg-2,
      .mx-lg-2 {
        margin-left: 0.5rem !important;
      }

      .m-lg-3 {
        margin: 1rem !important;
      }

      .mt-lg-3,
      .my-lg-3 {
        margin-top: 1rem !important;
      }

      .mr-lg-3,
      .mx-lg-3 {
        margin-right: 1rem !important;
      }

      .mb-lg-3,
      .my-lg-3 {
        margin-bottom: 1rem !important;
      }

      .ml-lg-3,
      .mx-lg-3 {
        margin-left: 1rem !important;
      }

      .m-lg-4 {
        margin: 1.5rem !important;
      }

      .mt-lg-4,
      .my-lg-4 {
        margin-top: 1.5rem !important;
      }

      .mr-lg-4,
      .mx-lg-4 {
        margin-right: 1.5rem !important;
      }

      .mb-lg-4,
      .my-lg-4 {
        margin-bottom: 1.5rem !important;
      }

      .ml-lg-4,
      .mx-lg-4 {
        margin-left: 1.5rem !important;
      }

      .m-lg-5 {
        margin: 3rem !important;
      }

      .mt-lg-5,
      .my-lg-5 {
        margin-top: 3rem !important;
      }

      .mr-lg-5,
      .mx-lg-5 {
        margin-right: 3rem !important;
      }

      .mb-lg-5,
      .my-lg-5 {
        margin-bottom: 3rem !important;
      }

      .ml-lg-5,
      .mx-lg-5 {
        margin-left: 3rem !important;
      }

      .p-lg-0 {
        padding: 0 !important;
      }

      .pt-lg-0,
      .py-lg-0 {
        padding-top: 0 !important;
      }

      .pr-lg-0,
      .px-lg-0 {
        padding-right: 0 !important;
      }

      .pb-lg-0,
      .py-lg-0 {
        padding-bottom: 0 !important;
      }

      .pl-lg-0,
      .px-lg-0 {
        padding-left: 0 !important;
      }

      .p-lg-1 {
        padding: 0.25rem !important;
      }

      .pt-lg-1,
      .py-lg-1 {
        padding-top: 0.25rem !important;
      }

      .pr-lg-1,
      .px-lg-1 {
        padding-right: 0.25rem !important;
      }

      .pb-lg-1,
      .py-lg-1 {
        padding-bottom: 0.25rem !important;
      }

      .pl-lg-1,
      .px-lg-1 {
        padding-left: 0.25rem !important;
      }

      .p-lg-2 {
        padding: 0.5rem !important;
      }

      .pt-lg-2,
      .py-lg-2 {
        padding-top: 0.5rem !important;
      }

      .pr-lg-2,
      .px-lg-2 {
        padding-right: 0.5rem !important;
      }

      .pb-lg-2,
      .py-lg-2 {
        padding-bottom: 0.5rem !important;
      }

      .pl-lg-2,
      .px-lg-2 {
        padding-left: 0.5rem !important;
      }

      .p-lg-3 {
        padding: 1rem !important;
      }

      .pt-lg-3,
      .py-lg-3 {
        padding-top: 1rem !important;
      }

      .pr-lg-3,
      .px-lg-3 {
        padding-right: 1rem !important;
      }

      .pb-lg-3,
      .py-lg-3 {
        padding-bottom: 1rem !important;
      }

      .pl-lg-3,
      .px-lg-3 {
        padding-left: 1rem !important;
      }

      .p-lg-4 {
        padding: 1.5rem !important;
      }

      .pt-lg-4,
      .py-lg-4 {
        padding-top: 1.5rem !important;
      }

      .pr-lg-4,
      .px-lg-4 {
        padding-right: 1.5rem !important;
      }

      .pb-lg-4,
      .py-lg-4 {
        padding-bottom: 1.5rem !important;
      }

      .pl-lg-4,
      .px-lg-4 {
        padding-left: 1.5rem !important;
      }

      .p-lg-5 {
        padding: 3rem !important;
      }

      .pt-lg-5,
      .py-lg-5 {
        padding-top: 3rem !important;
      }

      .pr-lg-5,
      .px-lg-5 {
        padding-right: 3rem !important;
      }

      .pb-lg-5,
      .py-lg-5 {
        padding-bottom: 3rem !important;
      }

      .pl-lg-5,
      .px-lg-5 {
        padding-left: 3rem !important;
      }

      .m-lg-n1 {
        margin: -0.25rem !important;
      }

      .mt-lg-n1,
      .my-lg-n1 {
        margin-top: -0.25rem !important;
      }

      .mr-lg-n1,
      .mx-lg-n1 {
        margin-right: -0.25rem !important;
      }

      .mb-lg-n1,
      .my-lg-n1 {
        margin-bottom: -0.25rem !important;
      }

      .ml-lg-n1,
      .mx-lg-n1 {
        margin-left: -0.25rem !important;
      }

      .m-lg-n2 {
        margin: -0.5rem !important;
      }

      .mt-lg-n2,
      .my-lg-n2 {
        margin-top: -0.5rem !important;
      }

      .mr-lg-n2,
      .mx-lg-n2 {
        margin-right: -0.5rem !important;
      }

      .mb-lg-n2,
      .my-lg-n2 {
        margin-bottom: -0.5rem !important;
      }

      .ml-lg-n2,
      .mx-lg-n2 {
        margin-left: -0.5rem !important;
      }

      .m-lg-n3 {
        margin: -1rem !important;
      }

      .mt-lg-n3,
      .my-lg-n3 {
        margin-top: -1rem !important;
      }

      .mr-lg-n3,
      .mx-lg-n3 {
        margin-right: -1rem !important;
      }

      .mb-lg-n3,
      .my-lg-n3 {
        margin-bottom: -1rem !important;
      }

      .ml-lg-n3,
      .mx-lg-n3 {
        margin-left: -1rem !important;
      }

      .m-lg-n4 {
        margin: -1.5rem !important;
      }

      .mt-lg-n4,
      .my-lg-n4 {
        margin-top: -1.5rem !important;
      }

      .mr-lg-n4,
      .mx-lg-n4 {
        margin-right: -1.5rem !important;
      }

      .mb-lg-n4,
      .my-lg-n4 {
        margin-bottom: -1.5rem !important;
      }

      .ml-lg-n4,
      .mx-lg-n4 {
        margin-left: -1.5rem !important;
      }

      .m-lg-n5 {
        margin: -3rem !important;
      }

      .mt-lg-n5,
      .my-lg-n5 {
        margin-top: -3rem !important;
      }

      .mr-lg-n5,
      .mx-lg-n5 {
        margin-right: -3rem !important;
      }

      .mb-lg-n5,
      .my-lg-n5 {
        margin-bottom: -3rem !important;
      }

      .ml-lg-n5,
      .mx-lg-n5 {
        margin-left: -3rem !important;
      }

      .m-lg-auto {
        margin: auto !important;
      }

      .mt-lg-auto,
      .my-lg-auto {
        margin-top: auto !important;
      }

      .mr-lg-auto,
      .mx-lg-auto {
        margin-right: auto !important;
      }

      .mb-lg-auto,
      .my-lg-auto {
        margin-bottom: auto !important;
      }

      .ml-lg-auto,
      .mx-lg-auto {
        margin-left: auto !important;
      }
    }
    @media (min-width: 1200px) {
      .m-xl-0 {
        margin: 0 !important;
      }

      .mt-xl-0,
      .my-xl-0 {
        margin-top: 0 !important;
      }

      .mr-xl-0,
      .mx-xl-0 {
        margin-right: 0 !important;
      }

      .mb-xl-0,
      .my-xl-0 {
        margin-bottom: 0 !important;
      }

      .ml-xl-0,
      .mx-xl-0 {
        margin-left: 0 !important;
      }

      .m-xl-1 {
        margin: 0.25rem !important;
      }

      .mt-xl-1,
      .my-xl-1 {
        margin-top: 0.25rem !important;
      }

      .mr-xl-1,
      .mx-xl-1 {
        margin-right: 0.25rem !important;
      }

      .mb-xl-1,
      .my-xl-1 {
        margin-bottom: 0.25rem !important;
      }

      .ml-xl-1,
      .mx-xl-1 {
        margin-left: 0.25rem !important;
      }

      .m-xl-2 {
        margin: 0.5rem !important;
      }

      .mt-xl-2,
      .my-xl-2 {
        margin-top: 0.5rem !important;
      }

      .mr-xl-2,
      .mx-xl-2 {
        margin-right: 0.5rem !important;
      }

      .mb-xl-2,
      .my-xl-2 {
        margin-bottom: 0.5rem !important;
      }

      .ml-xl-2,
      .mx-xl-2 {
        margin-left: 0.5rem !important;
      }

      .m-xl-3 {
        margin: 1rem !important;
      }

      .mt-xl-3,
      .my-xl-3 {
        margin-top: 1rem !important;
      }

      .mr-xl-3,
      .mx-xl-3 {
        margin-right: 1rem !important;
      }

      .mb-xl-3,
      .my-xl-3 {
        margin-bottom: 1rem !important;
      }

      .ml-xl-3,
      .mx-xl-3 {
        margin-left: 1rem !important;
      }

      .m-xl-4 {
        margin: 1.5rem !important;
      }

      .mt-xl-4,
      .my-xl-4 {
        margin-top: 1.5rem !important;
      }

      .mr-xl-4,
      .mx-xl-4 {
        margin-right: 1.5rem !important;
      }

      .mb-xl-4,
      .my-xl-4 {
        margin-bottom: 1.5rem !important;
      }

      .ml-xl-4,
      .mx-xl-4 {
        margin-left: 1.5rem !important;
      }

      .m-xl-5 {
        margin: 3rem !important;
      }

      .mt-xl-5,
      .my-xl-5 {
        margin-top: 3rem !important;
      }

      .mr-xl-5,
      .mx-xl-5 {
        margin-right: 3rem !important;
      }

      .mb-xl-5,
      .my-xl-5 {
        margin-bottom: 3rem !important;
      }

      .ml-xl-5,
      .mx-xl-5 {
        margin-left: 3rem !important;
      }

      .p-xl-0 {
        padding: 0 !important;
      }

      .pt-xl-0,
      .py-xl-0 {
        padding-top: 0 !important;
      }

      .pr-xl-0,
      .px-xl-0 {
        padding-right: 0 !important;
      }

      .pb-xl-0,
      .py-xl-0 {
        padding-bottom: 0 !important;
      }

      .pl-xl-0,
      .px-xl-0 {
        padding-left: 0 !important;
      }

      .p-xl-1 {
        padding: 0.25rem !important;
      }

      .pt-xl-1,
      .py-xl-1 {
        padding-top: 0.25rem !important;
      }

      .pr-xl-1,
      .px-xl-1 {
        padding-right: 0.25rem !important;
      }

      .pb-xl-1,
      .py-xl-1 {
        padding-bottom: 0.25rem !important;
      }

      .pl-xl-1,
      .px-xl-1 {
        padding-left: 0.25rem !important;
      }

      .p-xl-2 {
        padding: 0.5rem !important;
      }

      .pt-xl-2,
      .py-xl-2 {
        padding-top: 0.5rem !important;
      }

      .pr-xl-2,
      .px-xl-2 {
        padding-right: 0.5rem !important;
      }

      .pb-xl-2,
      .py-xl-2 {
        padding-bottom: 0.5rem !important;
      }

      .pl-xl-2,
      .px-xl-2 {
        padding-left: 0.5rem !important;
      }

      .p-xl-3 {
        padding: 1rem !important;
      }

      .pt-xl-3,
      .py-xl-3 {
        padding-top: 1rem !important;
      }

      .pr-xl-3,
      .px-xl-3 {
        padding-right: 1rem !important;
      }

      .pb-xl-3,
      .py-xl-3 {
        padding-bottom: 1rem !important;
      }

      .pl-xl-3,
      .px-xl-3 {
        padding-left: 1rem !important;
      }

      .p-xl-4 {
        padding: 1.5rem !important;
      }

      .pt-xl-4,
      .py-xl-4 {
        padding-top: 1.5rem !important;
      }

      .pr-xl-4,
      .px-xl-4 {
        padding-right: 1.5rem !important;
      }

      .pb-xl-4,
      .py-xl-4 {
        padding-bottom: 1.5rem !important;
      }

      .pl-xl-4,
      .px-xl-4 {
        padding-left: 1.5rem !important;
      }

      .p-xl-5 {
        padding: 3rem !important;
      }

      .pt-xl-5,
      .py-xl-5 {
        padding-top: 3rem !important;
      }

      .pr-xl-5,
      .px-xl-5 {
        padding-right: 3rem !important;
      }

      .pb-xl-5,
      .py-xl-5 {
        padding-bottom: 3rem !important;
      }

      .pl-xl-5,
      .px-xl-5 {
        padding-left: 3rem !important;
      }

      .m-xl-n1 {
        margin: -0.25rem !important;
      }

      .mt-xl-n1,
      .my-xl-n1 {
        margin-top: -0.25rem !important;
      }

      .mr-xl-n1,
      .mx-xl-n1 {
        margin-right: -0.25rem !important;
      }

      .mb-xl-n1,
      .my-xl-n1 {
        margin-bottom: -0.25rem !important;
      }

      .ml-xl-n1,
      .mx-xl-n1 {
        margin-left: -0.25rem !important;
      }

      .m-xl-n2 {
        margin: -0.5rem !important;
      }

      .mt-xl-n2,
      .my-xl-n2 {
        margin-top: -0.5rem !important;
      }

      .mr-xl-n2,
      .mx-xl-n2 {
        margin-right: -0.5rem !important;
      }

      .mb-xl-n2,
      .my-xl-n2 {
        margin-bottom: -0.5rem !important;
      }

      .ml-xl-n2,
      .mx-xl-n2 {
        margin-left: -0.5rem !important;
      }

      .m-xl-n3 {
        margin: -1rem !important;
      }

      .mt-xl-n3,
      .my-xl-n3 {
        margin-top: -1rem !important;
      }

      .mr-xl-n3,
      .mx-xl-n3 {
        margin-right: -1rem !important;
      }

      .mb-xl-n3,
      .my-xl-n3 {
        margin-bottom: -1rem !important;
      }

      .ml-xl-n3,
      .mx-xl-n3 {
        margin-left: -1rem !important;
      }

      .m-xl-n4 {
        margin: -1.5rem !important;
      }

      .mt-xl-n4,
      .my-xl-n4 {
        margin-top: -1.5rem !important;
      }

      .mr-xl-n4,
      .mx-xl-n4 {
        margin-right: -1.5rem !important;
      }

      .mb-xl-n4,
      .my-xl-n4 {
        margin-bottom: -1.5rem !important;
      }

      .ml-xl-n4,
      .mx-xl-n4 {
        margin-left: -1.5rem !important;
      }

      .m-xl-n5 {
        margin: -3rem !important;
      }

      .mt-xl-n5,
      .my-xl-n5 {
        margin-top: -3rem !important;
      }

      .mr-xl-n5,
      .mx-xl-n5 {
        margin-right: -3rem !important;
      }

      .mb-xl-n5,
      .my-xl-n5 {
        margin-bottom: -3rem !important;
      }

      .ml-xl-n5,
      .mx-xl-n5 {
        margin-left: -3rem !important;
      }

      .m-xl-auto {
        margin: auto !important;
      }

      .mt-xl-auto,
      .my-xl-auto {
        margin-top: auto !important;
      }

      .mr-xl-auto,
      .mx-xl-auto {
        margin-right: auto !important;
      }

      .mb-xl-auto,
      .my-xl-auto {
        margin-bottom: auto !important;
      }

      .ml-xl-auto,
      .mx-xl-auto {
        margin-left: auto !important;
      }
    }

    .stretched-link::after {
      background-color: rgba(0, 0, 0, 0);
      bottom: 0;
      content: '';
      left: 0;
      pointer-events: auto;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    .text-monospace {
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
        'Courier New', monospace !important;
    }

    .text-justify {
      text-align: justify !important;
    }

    .text-wrap {
      white-space: normal !important;
    }

    .text-nowrap {
      white-space: nowrap !important;
    }

    .text-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .text-left {
      text-align: left !important;
    }

    .text-right {
      text-align: right !important;
    }

    .text-center {
      text-align: center !important;
    }

    @media (min-width: 576px) {
      .text-sm-left {
        text-align: left !important;
      }

      .text-sm-right {
        text-align: right !important;
      }

      .text-sm-center {
        text-align: center !important;
      }
    }
    @media (min-width: 768px) {
      .text-md-left {
        text-align: left !important;
      }

      .text-md-right {
        text-align: right !important;
      }

      .text-md-center {
        text-align: center !important;
      }
    }
    @media (min-width: 992px) {
      .text-lg-left {
        text-align: left !important;
      }

      .text-lg-right {
        text-align: right !important;
      }

      .text-lg-center {
        text-align: center !important;
      }
    }
    @media (min-width: 1200px) {
      .text-xl-left {
        text-align: left !important;
      }

      .text-xl-right {
        text-align: right !important;
      }

      .text-xl-center {
        text-align: center !important;
      }
    }

    .text-lowercase {
      text-transform: lowercase !important;
    }

    .text-uppercase {
      text-transform: uppercase !important;
    }

    .text-capitalize {
      text-transform: capitalize !important;
    }

    .font-weight-light {
      font-weight: 300 !important;
    }

    .font-weight-lighter {
      font-weight: lighter !important;
    }

    .font-weight-normal {
      font-weight: 400 !important;
    }

    .font-weight-bold {
      font-weight: 700 !important;
    }

    .font-weight-bolder {
      font-weight: bolder !important;
    }

    .font-italic {
      font-style: italic !important;
    }

    .text-white {
      color: #fff !important;
    }

    .text-primary {
      color: #007bff !important;
    }

    a.text-primary:hover,
    a.text-primary:focus {
      color: #0056b3 !important;
    }

    .text-secondary {
      color: #6c757d !important;
    }

    a.text-secondary:hover,
    a.text-secondary:focus {
      color: #494f54 !important;
    }

    .text-success {
      color: #28a745 !important;
    }

    a.text-success:hover,
    a.text-success:focus {
      color: #19692c !important;
    }

    .text-info {
      color: #17a2b8 !important;
    }

    a.text-info:hover,
    a.text-info:focus {
      color: #0f6674 !important;
    }

    .text-warning {
      color: #ffc107 !important;
    }

    a.text-warning:hover,
    a.text-warning:focus {
      color: #ba8b00 !important;
    }

    .text-danger {
      color: #dc3545 !important;
    }

    a.text-danger:hover,
    a.text-danger:focus {
      color: #a71d2a !important;
    }

    .text-light {
      color: #f8f9fa !important;
    }

    a.text-light:hover,
    a.text-light:focus {
      color: #cbd3da !important;
    }

    .text-dark {
      color: #343a40 !important;
    }

    a.text-dark:hover,
    a.text-dark:focus {
      color: #121416 !important;
    }

    .text-body {
      color: #212529 !important;
    }

    .text-muted {
      color: #6c757d !important;
    }

    .text-black-50 {
      color: rgba(0, 0, 0, 0.5) !important;
    }

    .text-white-50 {
      color: rgba(255, 255, 255, 0.5) !important;
    }

    .text-hide {
      background-color: transparent;
      border: 0;
      color: transparent;
      font: 0/0 a;
      text-shadow: none;
    }

    .text-decoration-none {
      text-decoration: none !important;
    }

    .text-break {
      word-break: break-word !important;
      word-wrap: break-word !important;
    }

    .text-reset {
      color: inherit !important;
    }

    .visible {
      visibility: visible !important;
    }

    .invisible {
      visibility: hidden !important;
    }

    @media print {
      *,
      *::before,
      *::after {
        box-shadow: none !important;
        text-shadow: none !important;
      }

      a:not(.btn) {
        text-decoration: underline;
      }

      abbr[title]::after {
        content: ' (' attr(title) ')';
      }

      pre {
        white-space: pre-wrap !important;
      }

      pre,
      blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
      }

      thead {
        display: table-header-group;
      }

      tr,
      img {
        page-break-inside: avoid;
      }

      p,
      h2,
      h3 {
        orphans: 3;
        widows: 3;
      }

      h2,
      h3 {
        page-break-after: avoid;
      }

      @page {
        size: a3;
      }
      body {
        min-width: 992px !important;
      }

      .container {
        min-width: 992px !important;
      }

      .navbar {
        display: none;
      }

      .badge {
        border: 1px solid #000;
      }

      .table {
        border-collapse: collapse !important;
      }
      .table td,
      .table th {
        background-color: #fff !important;
      }

      .table-bordered th,
      .table-bordered td {
        border: 1px solid #dee2e6 !important;
      }

      .table-dark {
        color: inherit;
      }
      .table-dark th,
      .table-dark td,
      .table-dark thead th,
      .table-dark tbody + tbody {
        border-color: #dee2e6;
      }

      .table .thead-dark th {
        border-color: #dee2e6;
        color: inherit;
      }
    }
  }
}
