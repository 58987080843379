:global(.desktop),
:global(.tablet) {
  .resultsList {
    &.tiles {
      width: calc(100% + 1rem);
    }
  }
}

.resultsList {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  margin-top: 2rem;
  width: 100%;

  span {
    align-self: center;
  }
}

.banner {
  margin-bottom: 2.5rem;
}
