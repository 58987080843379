@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .ticketsMainContainer {
      background-color: $ticketsBox_ButtonText;
      border-radius: $default_borderRadiusMain;
      margin: 0;
      padding: 1rem 3rem;
    }
  }
}

:global(.empik) {
  .ticketsMainContainer {
    margin: 0;
  }
  &:global(.desktop) {
    .title {
      margin-top: 0;
    }
  }

  &:global(.mobile) {
    .container {
      background-color: $ticketsBox_BackgroundMain;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
      padding: 3.75rem 1.75rem;
    }

    .title {
      margin: 0 0 1.5rem;
    }

    .logoContainer {
      margin: 0 -1.25rem;

      .rectangleSquareContainer {
        border-radius: 0;
      }
    }
  }
}

.ticketsMainContainer {
  background-color: transparent;
  margin-bottom: 5rem;
  padding: 0;
}
:global(.desktop) {
  .title {
    font-size: 3rem;
    margin: 5rem 0 2rem;
  }

  .ticketsMainContainer {
    background-color: $ticketsBox_BackgroundMain;
    padding: 3rem 5rem;
  }

  .icon {
    left: 5rem;
    max-width: 9rem;
    top: 1rem;
    width: 12vw;
  }

  .logoContainer {
    height: 35vw;
    max-height: 30rem;
  }
}

.title {
  font-size: 1.5rem;
  font-weight: $default_fontBoldWeight;
  margin: 4rem 0 1.5rem;
  padding: 0;
}

.ticketsMainContainer {
  border-radius: 1rem 0 1rem 0;
  font-size: 0.75rem;
  font-weight: $default_fontBoldWeight;
  opacity: 0.95;
}

.logoContainer {
  height: 45vw;
  overflow: hidden;
  position: relative;

  .opacityContainer {
    opacity: 0.95;
  }

  .rectangleSquareContainer {
    background-color: $ticketsBox_BackgroundMain;
    border-radius: 0 1rem 0 0;
    height: 75rem;
    position: absolute;
    right: 0;
    transform: rotate(78deg);
    transform-origin: top right;
    width: 100%;
    z-index: -10;
  }

  .icon {
    left: 1.25rem;
    position: absolute;
    width: 16vw;
    z-index: -20;
  }
}
