@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  &:global(.desktop) {
    .event {
      .imageWrapper {
        flex: 1 0 40%;
        max-width: 30rem;
      }

      .eventDetails {
        flex: 0 1 60%;

        .value {
          font-size: 1.125rem;
        }
      }
    }
  }

  .event {
    border-radius: $default_borderRadiusMain;

    &:first-child {
      &,
      .imageWrapper {
        border-radius: $default_borderRadiusMain;
      }
    }

    .tagsContainer {
      left: 5vw;
      margin-bottom: -2rem;
      margin-top: 1.25rem;
      position: relative;
    }

    .eventDetails {
      user-select: none;

      .value {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 0;
      }
    }
  }
}

:global(.desktop) {
  .event {
    .imageWrapper {
      max-width: 28rem;
    }
  }

  .normal {
    flex-direction: row;

    .eventDetails {
      padding: 2rem 3rem;
      position: relative;
    }
  }
}

.event {
  box-shadow: $events_eventShadow;
  margin-bottom: 2.5rem;
  padding: 0;
  width: 100%;

  &:first-child {
    &,
    .imageWrapper {
      border-radius: 1rem 0 0 0;
    }
  }

  .imageWrapper {
    border-radius: $default_borderRadiusMain;
    width: 100%;
  }

  .eventDetails {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 1.5rem;
    width: 100%;

    .detailsTop {
      align-content: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .value {
      display: block;
      font-size: 0.875rem;
      font-weight: bold;
      margin-bottom: 0.3125rem;

      a {
        color: $events_linkColor;
      }
    }

    .eventDescription {
      @include lineLimit(3, 1.5);
      font-size: 0.875rem;
      font-weight: 300;
      hyphens: auto;
      margin: 1rem 0 0;
      text-align: justify;
      word-break: break-word;
    }

    & > div > div > h2 {
      @include lineLimit(2, inherit);
      hyphens: auto;
      word-break: break-word;
    }
  }

  &.withChangeMonitorName {
    .eventDetails {
      .eventDescription {
        @include lineLimit(1, 1.5);
      }

      & > div > div > h2 {
        @include lineLimit(1, inherit);
      }
    }
  }
}

:global(.mobile) {
  .event {
    &.withChangeMonitorName {
      .eventDetails {
        [class*='TitleResponsive_mobileWrapper__'] {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.normal {
  display: flex;
  flex-direction: column;
}

.forcedVertical {
  display: block;
}

.premiumEvent {
  box-shadow: 0 0 0 2px $events_premiumBorder;
}

.buyButtonContainer {
  display: flex;
  flex-grow: 1;
}
