@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .content {
    padding: 5rem;
  }
}

.content {
  border-radius: $default_borderRadiusMain;
  box-shadow: $layout_contentShadow;
  box-sizing: border-box;
  margin-bottom: 5rem;
  padding: 1.5rem;
  width: 100%;
}
