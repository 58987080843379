@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .caption {
    font-size: 1.5rem;
  }

  .eventContainer {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding: 2rem 2rem 1.75rem;

    .contentContainer {
      align-items: center;
      flex-basis: 100%;

      .rundateContainer {
        margin-right: 2.34375rem;
      }

      .infoContainer {
        flex-basis: 70%;
        flex-direction: row;
        font-weight: 400;
        justify-content: space-between;

        .eventName {
          font-size: 1.375rem;
          margin-bottom: 0;
        }

        .placeName {
          align-items: center;
          display: flex;
          flex-basis: 40%;
          font-size: 1.125rem;
          justify-content: flex-end;
        }
      }
    }

    .seeButton {
      align-self: center;
      height: 1rem;
      margin-top: 0;
      width: 10rem;
    }
  }
}

.caption {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  text-transform: capitalize;
}
.center {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;

  .more {
    @include reset;
    border-bottom: 0.125rem solid $default_main;
    color: $default_black;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 0.125rem;
  }
}

.eventContainer {
  background-color: $default_white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 2rem 1rem 1.375rem 1rem;

  .contentContainer {
    display: flex;

    .rundateContainer {
      align-items: center;
      border: 0.0625rem solid $default_black;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      height: 3.625rem;
      justify-content: center;
      margin-right: 0.6875rem;
      min-width: 3.625rem;

      .day {
        font-size: 1.5rem;
        font-weight: 600;
      }

      .dayName {
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 0.0625rem;
        text-transform: uppercase;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      font-size: 0.875rem;
      font-weight: 600;

      .eventName {
        margin-bottom: 0.5625rem;
      }

      .placeName {
        color: $default_navy;
      }
    }
  }

  .seeButton {
    border: 0.0625rem solid $default_main;
    border-radius: 0.25rem;
    color: $default_black;
    display: block;
    font-weight: 500;
    margin-top: 1.75rem;
    padding: 1rem 0;
    text-align: center;
    text-transform: uppercase;
  }
}
