@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .imagesContainer {
      .image {
        &:first-child {
          border-radius: 1rem;
        }
      }
    }
  }

  .imagesContainer {
    .image {
      border-radius: 0.5rem;

      &:last-child {
        background-color: $gallery_tileAccents;
        border: none;
      }
    }
  }
}

:global(.desktop) {
  .imagesContainer {
    .image {
      height: 8rem;
      width: calc(20% - 1rem);

      &:first-child {
        height: 25rem;
      }
    }
  }
}

.imagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 0;

  .image {
    align-items: center;
    box-sizing: border-box;
    color: $gallery_tileTextColor;
    cursor: pointer;
    display: flex;
    flex: 1;
    font-size: 2.25rem;
    font-weight: $default_fontBoldWeight;
    height: 5rem;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 1rem;
    min-height: 5rem;
    object-fit: cover;
    width: calc(33% - 1rem);

    &:first-child {
      flex: unset;
      height: 15rem;
      margin-bottom: 1rem;
      width: 100%;
    }

    &:first-child,
    &:last-child {
      margin-right: 0;
    }

    &:last-child {
      border-bottom: solid 0.25rem $gallery_tileAccents;
    }

    &.loading {
      @include fakeDivCommons();
    }
  }

  &.vertical {
    flex-direction: column;
    height: 30rem;

    .image {
      height: 33%;
      margin-bottom: 1rem;
      margin-right: 0;
      width: 25%;

      &:first-child,
      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }

      &:first-child {
        height: 100%;
        margin-right: 1rem;
        width: calc(75% - 1rem);
      }
    }
  }
}
