@import '@Styles/defs/colors.scss';
@import '@Styles/defs/animations.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  .title,
  .forcedVerticalTitle {
    font-weight: 500;
  }
}

:global(.desktop) {
  .title {
    font-size: calc(2rem + 1vw);
  }
  .forcedVerticalTitle {
    font-size: 1.3125rem;
  }
}

.title,
.forcedVerticalTitle {
  display: block;
  font-weight: bold;
  line-height: inherit;
  margin-bottom: 0.625rem;
  padding: 0.125rem 0;
}

.title {
  font-size: 1.25rem;
}

.forcedVerticalTitle {
  font-size: 1rem;
}

.fakeTitle {
  @include fakeDivCommons();
  height: 1.875rem;
  margin: 0 0 1.5rem;
  width: 100%;
}
