@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  .scroll {
    .wrapper {
      .hotDealWrapper {
        border-radius: $default_borderRadiusMain;

        .imageWrapper {
          border-radius: $default_borderRadiusMain;

          .badge {
            border-radius: 0.25rem;
            font-size: 1rem;
            font-weight: 500;
            padding: 0.25rem 0.75rem 0.25rem 0.5rem;
            top: -0.5rem;
          }
        }

        .contentWrapper {
          padding: 1.5rem;

          .date {
            color: $hotDeals_fontColor;
            font-weight: 500;
          }

          .place {
            font-weight: 500;

            a {
              border-bottom: $reusable_placeFont $default_borderUnderline;
              color: $reusable_placeLink;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

:global(.desktop) {
  .scroll {
    .wrapper {
      flex-flow: row nowrap;

      .hotDealWrapper {
        @include cardDesktop;
        margin: 0 1rem 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.scroll {
  min-width: 100%;
  overflow: visible;

  .wrapper {
    @include cardDeckMobile;
    margin: 1rem 0;
    width: 100%;

    .hotDealWrapper {
      @include cardMobile;
      background-color: $hotDeals_background;
      margin: 1rem 0;

      .contentWrapper {
        flex: 1 1 auto;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 1.125rem 2rem 0 1.5rem;

        .title {
          font-size: 1.25rem;
          margin-bottom: 0.75rem;
        }

        .date {
          margin-bottom: 0.3125rem;
        }

        .place {
          margin-bottom: 0;
        }
      }

      .tagsWrapper {
        display: block;
        padding: 1.5rem;
      }

      .imageWrapper {
        display: block;
        position: relative;

        .badge {
          color: $hotDeals_badgeFont;
          font-size: 1rem;
          padding: 0 1rem;
          position: absolute;
          right: -0.1875rem;
          top: -0.1875rem;

          &.discount {
            background-color: $hotDeals_badgeDiscount;
          }

          &.gift {
            background-color: $hotDeals_badgeGift;
          }

          &.important {
            background-color: $hotDeals_badgeImportant;
          }
        }
      }
    }
  }
}

:global(.white) {
  .scroll {
    .wrapper {
      .hotDealWrapper {
        background-color: $section_whiteElementBackground;
      }
    }
  }
}

:global(.dark) {
  .scroll {
    .wrapper {
      .hotDealWrapper {
        background-color: $card_darkBackgroundColor;

        .contentWrapper {
          .title {
            color: $card_darkTextColorPrimary;
          }

          .date {
            color: $card_darkTextColorSecondary;
          }

          .place {
            color: $card_darkTextColorPrimary;
          }
        }
      }
    }
  }
}
