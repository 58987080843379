@keyframes backgroundBlinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(1rem);
  }
  40% {
    transform: translateX(-1rem);
  }
  60% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes rotation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(359deg);
  }
}
