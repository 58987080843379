.logoContainer {
  display: flex;

  .logoLink {
    display: flex;
    padding: 0.75rem 0;

    .logo {
      height: auto;
      max-height: 1.625rem;
      min-height: 1.625rem;
      width: auto;
    }
  }
}

:global(.desktop) {
  .logoContainer {
    .logoLink {
      .logo {
        max-height: 2.5rem;
        min-height: 2.5rem;
      }
    }
  }
}
