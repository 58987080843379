@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .partnersWrapper {
    @include cardDeckDesktop;
    align-items: center;
    justify-content: center;
    width: calc(100% + 4rem);

    .partnersElement {
      @include cardDesktop;
      margin-bottom: 4rem;
      margin-right: 4rem;
      max-width: 12rem;
      min-width: 6.66rem;

      .partnersContainer {
        padding: 0;
      }
    }
  }
}

.sectionTitle {
  font-size: $landingPage_sectionTitleFontSize;
  font-weight: $landingPage_boldFontWeight;
  margin: $landingPage_sectionTitleMargin;
  user-select: none;
}

.partnersWrapper {
  @include cardDeckMobile;

  * {
    box-sizing: border-box;
  }

  .partnersElement {
    @include cardMobile;
    width: 100%;

    .partnersContainer {
      @include cardBody;
      padding: 2rem 4rem;

      img {
        width: 100%;
      }

      a {
        opacity: 0.8;
        transition: $landingPage_linkAnimation;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
