@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .leadWrapper {
    margin: 5rem auto;

    &.midLead {
      margin: 5rem auto 1rem;

      .titleWrapper {
        .title {
          font-size: 2rem;
        }
      }
    }

    .titleWrapper {
      .title {
        font-size: 3rem;

        &.withDropdown {
          transform: translateX(1.25rem);
        }
      }
    }

    .description {
      font-size: 1.25rem;
    }
  }
}

.leadWrapper {
  @include topBottomMargins;
  margin: 2rem auto;
  text-align: center;

  &.midLead {
    margin: 2rem auto 1rem;

    .titleWrapper {
      .title {
        font-size: 1rem;
      }
    }
  }

  .titleWrapper {
    @include topBottomMargins;
    display: flex;
    flex-grow: 1.5;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0.5rem auto;

    .title {
      font-size: 1.5rem;
    }
  }

  .description {
    @include topBottomMargins;
    font-size: 0.875rem;
    font-weight: 300;
    margin: 0.5rem auto;
    max-width: 45rem;
    width: 100%;
  }
}
