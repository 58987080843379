@import '@Styles/defs/colors.scss';

@mixin hangar {
  :global(.hangar) {
    font-family: $default_hangarFontMedium;

    .summaryBox {
      .content {
        border-bottom: 0.25rem solid $default_black;
        border-top: 0.25rem solid $default_black;
        box-shadow: none;
        margin: 1.43rem 0;
        padding: 1.43rem 0;

        .title {
          font-size: 1.88rem;
        }
        .withoutBottomSpace {
          color: $default_black;
          font-size: 1.25rem;

          a {
            color: $default_black;
            font-family: $default_hangarFont;
            font-stretch: normal;
            font-style: normal;
            font-weight: normal;
            line-height: normal;
          }
        }
      }
    }
    .triangleWrapper {
      display: none;
    }
  }
}
