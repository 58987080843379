@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .cardDeck {
    @include cardDeckDesktop;
    width: calc(100% + 1rem);

    .card {
      @include cardDesktop;
      min-width: 12rem;

      .cardHeader {
      }

      .cardBody {
      }

      .cardFooter {
      }
    }
  }
}

.sectionTitle {
  font-size: $landingPage_sectionTitleFontSize;
  font-weight: $landingPage_boldFontWeight;
  margin: $landingPage_sectionTitleMargin;
  user-select: none;
}

.cardDeck {
  @include cardDeckMobile;
  margin: 0 auto;

  * {
    box-sizing: border-box;
  }

  .card {
    @include cardMobile;
    background: $landingPage_lightCardBackground;
    border: $landingPage_cardBorder;
    border-radius: $landingPage_borderRadius;
    color: $landingPage_darkTextColor;
    text-align: center;
    transition: $landingPage_cardAnimation;
    width: 100%;

    &:hover {
      background: $landingPage_lightCardBackgroundHover;
    }

    &.dark {
      background: $landingPage_darkCardBackground;
      color: $landingPage_lightTextColor;

      &:hover {
        background: $landingPage_darkCardBackgroundHover;
      }
    }

    .cardHeader {
      @include cardHeader;
      border-bottom: $landingPage_cardBorder;
    }

    .cardBody {
      @include cardBody;

      .date {
        font-size: 0.75rem;
        font-weight: $landingPage_normalFontWeight;
        text-transform: uppercase;
      }

      .title {
        font-size: 1.5rem;
        font-weight: $landingPage_boldFontWeight;
        margin: 0.25rem auto;
      }

      .place {
        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }

    .cardFooter {
      @include cardFooter;
      padding: 0 1rem 1rem;

      .cardButton {
        border: $landingPage_buttonBorder;
        border-radius: $landingPage_borderRadius;
        cursor: pointer;
        font-family: $landingPage_mainFont;
        font-size: 1rem;
        font-weight: $landingPage_boldFontWeight;
        line-height: 1;
        margin: 0.5rem auto 0;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        transition: $landingPage_linkAnimation;
        vertical-align: middle;
        width: 100%;

        &.outline {
          background-color: transparent !important;
        }

        &.disabled {
          opacity: 0.65;
          pointer-events: none;
        }

        .socialIcon {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
