@font-face {
  font-family: 'FaktumTest-Bold';
  src: url('fonts/zagrywki/3A1CF5_0_0.eot');
  src: url('fonts/zagrywki/3A1CF5_0_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/zagrywki/3A1CF5_0_0.woff2') format('woff2'),
    url('fonts/zagrywki/3A1CF5_0_0.woff') format('woff'),
    url('fonts/zagrywki/3A1CF5_0_0.ttf') format('truetype'),
    url('fonts/zagrywki/3A1CF5_0_0.svg#wf') format('svg');
}

@font-face {
  font-family: 'Faktum-Medium';
  src: url('fonts/zagrywki/3A1CF5_1_0.eot');
  src: url('fonts/zagrywki/3A1CF5_1_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/zagrywki/3A1CF5_1_0.woff2') format('woff2'),
    url('fonts/zagrywki/3A1CF5_1_0.woff') format('woff'),
    url('fonts/zagrywki/3A1CF5_1_0.ttf') format('truetype'),
    url('fonts/zagrywki/3A1CF5_1_0.svg#wf') format('svg');
}

@font-face {
  font-family: 'Faktum-Bold';
  src: url('fonts/zagrywki/3A1CF5_2_0.eot');
  src: url('fonts/zagrywki/3A1CF5_2_0.eot?#iefix') format('embedded-opentype'),
    url('fonts/zagrywki/3A1CF5_2_0.woff2') format('woff2'),
    url('fonts/zagrywki/3A1CF5_2_0.woff') format('woff'),
    url('fonts/zagrywki/3A1CF5_2_0.ttf') format('truetype'),
    url('fonts/zagrywki/3A1CF5_2_0.svg#wf') format('svg');
}
