@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .card {
    @include cardDesktop;
  }

  .cardWidthFor5 {
    @include responsivCard(calc(20% - 1rem), auto, 1.25rem, 0.75rem, 0.875rem);
  }

  .cardWidthFor4 {
    @include responsivCard(calc(25% - 1rem), auto, 1.5rem, 0.875rem, 1rem);
  }

  .cardWidthFor3 {
    @include responsivCard(calc(33% - 1rem), auto, 1.75rem, 0.875rem, 1rem);

    &.sliderCard {
      @include responsivCard(20.54rem, auto, 1.75rem, 0.875rem, 1rem);
      max-width: 20.54rem;
    }
  }

  .cardWidthFor2 {
    @include responsivCard(calc(50% - 1rem), auto, 1.75rem, 0.875rem, 1rem);
  }

  .cardWidthFor1 {
    @include responsivCard(100%, auto, 1.75rem, 1rem, 1.25rem);
  }

  @each $class, $width in $cardWidth {
    .fixed#{$class} {
      max-width: $width;
    }
  }
}

.card {
  @include cardMobile;
  border-radius: $card_borderRadius;
  user-select: none;

  & > hr {
    margin-left: 0;
    margin-right: 0;
  }

  &.sliderCard {
    @include cardDesktop;
    @include responsivCard(calc(25% - 1rem), auto, 1.25rem, 0.875rem, 0.875rem);
    margin-bottom: 0;
    max-width: calc(25% - 1rem);

    &.cardWidthFor3 {
      @include responsivCard(calc(33% - 1rem), auto, 1.75rem, 0.875rem, 1rem);
      max-width: calc(33% - 1rem);
    }
  }

  .cardHeader {
    @include cardHeader;
    background-color: $card_backgroundColor;
    border-radius: $card_borderRadiusTop;

    &.hotDeals {
      border-radius: 0.5rem;
    }

    .badge {
      border-radius: 0.25rem;
      box-sizing: border-box;
      color: $hotDeals_badgeFont;
      font-size: 0.875rem;
      font-weight: $default_fontBoldWeight;
      left: 0.5rem;
      max-width: calc(100% - 1rem);
      overflow: hidden;
      padding: 0.25rem 0.5rem;
      position: absolute;
      text-overflow: ellipsis;
      top: 0.5rem;
      white-space: nowrap;
      z-index: 1;

      &.dark {
        color: white;
      }

      &.discount {
        background-color: $hotDeals_badgeDiscount;
      }

      &.gift {
        background-color: $hotDeals_badgeGift;
      }

      &.important {
        background-color: $hotDeals_badgeImportant;
      }
    }
  }

  .cardBody {
    @include cardBody;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.5;

    &:last-child {
      border-radius: $card_borderRadiusBottom;
    }

    h2.cardTitle {
      @include topBottomMargins;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.25;
      margin-bottom: 1rem;

      &.highlightTitle {
        background: $card_titleBackgroundColor;
        padding: $card_titlePadding;
      }
    }

    .cardData {
      h3,
      .subtitle {
        color: $card_subtitleColor;
        font-size: 0.875rem;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      h4,
      .date,
      .place {
        font-size: 0.875rem;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p {
        @include topBottomMargins;
        @include lineLimit(4, 1.5);
        font-size: 0.75rem;
        font-weight: 300;
        margin-top: 1rem;
      }
    }
  }

  .cardFooter {
    @include cardFooter;
    border-radius: $card_borderRadiusBottom;
    text-align: left;

    a {
      border-bottom: $default_borderUnderline $card_linkUnderlineColor;
      font-size: 0.875rem;
      font-weight: bold;
    }
  }
}

:global(.mobile) {
  .card {
    &.sliderCard {
      @include responsivCard(16rem, auto, 1rem, 0.75rem, 0.75rem);
      max-width: 16rem;
    }
  }
}

:global(.white) {
  .card {
    .cardHeader {
      background-color: $card_whiteBackgroundColor;
    }

    .cardBody {
      background-color: $card_whiteBackgroundColor;
    }

    .cardFooter {
      background-color: $card_whiteBackgroundColor;
    }
  }
}

:global(.dark) {
  .card {
    .cardHeader {
      background-color: $card_darkBackgroundColor;
    }

    .cardBody {
      background-color: $card_darkBackgroundColor;

      .cardData {
        h3 {
          color: $card_subtitleColor;
        }

        p {
          color: $card_darkTextColorPrimary;
        }
      }
    }

    .cardFooter {
      background-color: $card_darkBackgroundColor;
    }
  }
}
