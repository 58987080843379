@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .faqWrapper {
    @include cardDeckDesktop;
    width: calc(100% + 4rem);

    .faqElement {
      @include cardDesktop;
      margin-right: 4rem;
      min-width: 35%;
    }
  }
}

.faqWrapper {
  @include cardDeckMobile;

  * {
    box-sizing: border-box;
  }

  .faqElement {
    @include cardMobile;
    line-height: 1.3;
    text-align: justify;
    transition: $default_transition;
    width: 100%;

    a {
      color: $faq_textColor;
      font-weight: $default_fontBoldWeight;
    }

    .faqTitle {
      @include cardHeader;
      font-weight: $default_fontBoldWeight;
      margin-bottom: 1rem;
    }

    .faqBody {
      @include cardBody;
      font-size: 0.875rem;
      padding: 0;
    }
  }
}
