@import '@Styles/defs/animations.scss';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

.imageContainer {
  appearance: none;
  background-color: transparent;
  border-radius: inherit;
  display: block;
  height: 0;
  margin: inherit;
  mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  padding-bottom: calc(
    #{$fixedImage_normalHeightRatio} / #{$fixedImage_normalWidthRatio} * 100%
  );
  position: relative;
  width: 100%;

  &.loading {
    animation: backgroundBlinking 0.8s infinite;
    animation-timing-function: ease-in-out;
    background-color: $reusable_imageBackground;
  }

  .image {
    animation: fadeIn 750ms cubic-bezier(0.23, 1, 0.32, 1) forwards 1 10ms;
    height: 100%;
    left: 50%;
    min-height: 101%;
    min-width: 101%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    width: auto;
  }

  &.gradientOverlay {
    @include gradientOverlay(bottom, 33%, 0.75);
  }

  &.wide {
    padding-bottom: calc(
      #{$fixedImage_wideHeightRatio} / #{$fixedImage_wideWidthRatio} * 100%
    );

    .image {
      height: auto;
      width: 100%;
    }
  }

  &.vertical {
    padding-bottom: calc(
      (#{$fixedImage_normalHeightRatio} * 2) / #{$fixedImage_normalWidthRatio} *
        100%
    );

    .image {
      height: 100%;
      width: auto;
    }
  }
}

:global(.mobile) {
  .imageContainer {
    .image {
      transform: translate3d(-50%, -50%, 0);
    }
  }
}
