@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  &:global(.desktop) {
    .location {
      .row {
        .left {
          border-radius: 1rem 0 0 1rem;

          & > div {
            border-radius: 1rem 0 0 1rem;
          }
        }

        .right {
          border-radius: 0 1rem 1rem 0;

          h2 {
            font-size: 2.25rem;
          }

          h3 {
            font-size: 1.125rem;
          }

          .description {
            p {
              font-size: 1.125rem;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
    }
  }

  .location {
    margin-top: 5rem;

    .row {
      .left {
        background: $event_locationBackground;
        border-radius: 1rem 1rem 0 0;
        margin: 0;

        & > div {
          border-radius: $default_borderRadiusMain;
        }

        &:before {
          display: none;
        }
      }

      .right {
        background: $event_locationBackground;
        border-radius: 0 0 1rem 1rem;
        padding: 1.5rem 2rem 2.5rem;

        h2 {
          font-size: 1.5rem;
          font-weight: 500;
        }

        h3 {
          font-size: 1rem;
          font-weight: 500;
        }

        .description {
          margin-top: 1.5rem;

          p {
            @include lineLimit(5, 1.66);
            color: $event_locationDescription;
            font-size: 0.75rem;
            font-weight: 300;
          }
        }

        .placeLinks {
          margin-top: 1.5rem;

          a {
            font-weight: 500;
          }
        }
      }
    }
  }
}

:global(.desktop) {
  .location {
    .row {
      flex-wrap: nowrap;
      margin-bottom: 2rem;

      .left {
        margin-bottom: unset;
        max-width: 26rem;
        min-width: 25rem;
      }

      h2 {
        font-size: 2rem;
        margin-bottom: 0.75rem;
      }

      .right {
        padding: 2rem 4rem;
      }
    }

    .placeName {
      font-size: 3rem;
      margin: 5rem 0 2rem;
    }
  }

  .description {
    p {
      -webkit-line-clamp: 2;
    }
  }
}

.location {
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;

    .left {
      margin-bottom: 1rem;
      position: relative;
      width: 100%;

      &:before {
        background: $event_locationCoverWaterMarkBackground;
        bottom: 2rem;
        content: '';
        display: block;
        left: -0.75rem;
        position: absolute;
        top: 1rem;
        width: 0.75rem;
      }
    }

    .right {
      width: 100%;

      .placeLinks {
        margin-top: 2.5rem;

        a {
          border-bottom: solid 0.125rem $event_locationOthersLinkFont;
          color: $event_placeLinkColor;
          font-size: 0.875rem;
          font-weight: $default_fontBoldWeight;
          padding-bottom: 0.15rem;
        }
      }
    }

    h2 {
      font-size: 1.25rem;
      margin-bottom: 0.75rem;

      a {
        color: $event_placeNameColor;
      }
    }

    h3 {
      color: $event_locationHeader;
      font-size: 1.125rem;
    }
  }

  .placeName {
    color: $event_locationPlaceNameFont;
    font-size: 1.5rem;
    font-weight: $default_fontBoldWeight;
    margin: 3rem 0 1rem;
  }
}

.description {
  margin-top: 2rem;

  p {
    @include lineLimit(6, 1.5);
    font-size: 1rem;
    line-height: 1.5;
  }
}

.tags {
  margin-bottom: 2rem;
}

.buttons {
  font-size: 0.875rem;
  margin-bottom: 3rem;

  ul {
    display: flex;
  }

  li {
    display: block;
    margin-right: 3rem;
  }

  a {
    color: $event_locationButtonsLinkFont;
  }
}

.others {
  margin-top: 3rem;

  h4 {
    font-size: 1.75rem;
    margin-bottom: 2rem;
  }

  .more {
    margin-top: 5rem;
    text-align: center;

    a {
      color: $event_locationOthersLinkFont;
    }
  }

  .othersList {
    display: flex;
    justify-content: space-between;

    li {
      display: block;
      position: relative;
    }

    li:nth-child(3n + 2) {
      &:before,
      &:after {
        background: $event_locationOthersListItemBackground;
        bottom: 0;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        width: 0.1875rem;
      }

      &:before {
        left: -1.25rem;
      }

      &:after {
        right: -1.25rem;
      }
    }
  }
}
