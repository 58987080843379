@use 'sass:math';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

$scroll_size: $size;
$pixelInterpolation: 6;

.scroll {
  backface-visibility: hidden;
  height: 100%;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);

  &:after {
    background: $scroll_exteriorBackground;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: #{$scroll_size * 2}px;
  }

  .bar {
    background: $scroll_background;
    bottom: #{$scroll_size}px;
    content: '';
    display: block;
    right: #{math.div($scroll_size, 8)}px;
    top: #{$scroll_size}px;
    width: #{math.div($scroll_size, 2)}px;
    z-index: 1;

    &,
    div {
      border-radius: #{$scroll_size}px;
      position: absolute;
    }

    div {
      background: $scroll_foreground;
      width: 100%;
    }
  }

  .inner {
    box-sizing: border-box;
    height: inherit;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.buttons {
  button {
    @include reset;
    cursor: pointer;
    display: block;
    height: #{$scroll_size}px;
    outline: none;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-indent: #{$infinity}px;
    width: #{$scroll_size}px;
    z-index: 1;

    &:after {
      border-left: #{math.div($scroll_size, 8)}px solid $scroll_foreground;
      border-top: #{math.div($scroll_size, 8)}px solid $scroll_foreground;
      content: '';
      display: block;
      height: #{$pixelInterpolation}px;
      position: absolute;
      right: 0;
      transform-origin: center;
      width: 6px;
    }
  }

  .up {
    top: 0;

    &:after {
      top: 0;
      transform: rotate(45deg)
        translate(0, #{math.div($pixelInterpolation, 2)}px);
    }
  }

  .down {
    bottom: 0;

    &:after {
      bottom: 0;
      transform: rotate(225deg)
        translate(#{math.div($pixelInterpolation, 2)}px, 0);
    }
  }
}
