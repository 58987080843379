@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

.bar {
  bottom: -8rem;
  box-shadow: $eventBox_buyBarShadow;
  left: 0;
  padding: 1rem;
  position: fixed;
  right: 0;
  transition: bottom $default_animationTime;
  z-index: 999;

  .button {
    background: $eventBox_buyBarButtonBackground;
    border: none;
    border-radius: 0.25rem;
    color: $eventBox_buyBarButtonColor;
    cursor: pointer;
    display: block;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 1rem 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  &.show {
    bottom: 0;
  }
}
