@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';

.wrapper,
.forcedVerticalWrapper {
  @include lineLimit(3, inherit);
  display: block;
  font-size: 0.75rem;
  font-weight: 300;
  margin: 0 0 1.5rem;
  max-height: 9.55rem;
  padding: 0.125rem 0;

  .description {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    &,
    a {
      color: inherit;
    }
  }
}
