@import '@Styles/defs/colors.scss';

@mixin hangar {
  :global(.hangar) {
    .input {
      border: 0.25rem solid $default_black;

      &::placeholder {
        color: white;
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: white;
      }
      &::-ms-input-placeholder {
        color: white;
      }
    }

    .errorBorder {
      border-color: $formField_errorBorder;
    }
  }
}
