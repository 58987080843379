@import '@Styles/defs/colors.scss';
@import '@Styles/defs/animations.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .normal {
    flex-basis: 50%;

    .image {
      height: 100%;
      width: 100%;
    }
  }
}

.image {
  align-items: center;
  background-color: $reusable_imageBackground;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  width: 100%;
}

.normal {
  border-radius: inherit;
  flex: 1;
  height: 100%;

  .image {
    height: 100%;
  }
}

.forcedVertical {
  border-radius: inherit;
  height: 100%;
  width: 100%;

  .image {
    align-items: center;
    background-color: $reusable_imageBackground;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 15.625rem;
    justify-content: center;
    width: 100%;
  }
}
