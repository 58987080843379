.insuranceBox {
  [class*='ToggleField_wrapper__'] {
    margin-top: 0 !important;
  }

  > div {
    > div {
      > div {
        [class*='Checkbox_checkbox__'] {
          label {
            font-size: 1rem !important;
          }
        }
      }
    }
  }
}
