@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .artist {
    .upcomingEventsContainer {
      margin-top: 10rem;
    }
    .title {
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 2.5rem;
    }
    .header {
      h1 {
        font-size: 4.5rem;
      }
    }

    .twoColumns {
      flex-direction: row-reverse;

      .fixedBox {
        display: flex;
        flex: 0 1 $rightColumnWidth;
        flex-flow: column nowrap;
        margin-left: 1rem;
        padding-bottom: 10rem;
      }

      .artistDetails {
        display: flex;
        flex: 0 1 $leftColumnWidth;
        flex-flow: column nowrap;
        min-width: 50%;

        & > div:first-of-type {
          margin-bottom: 5rem;
          width: 100%;
        }
      }

      .cover {
        border-radius: $artist_borderRadius;
        overflow: hidden;
        width: 100%;
      }
    }

    .pastEvents {
      margin-top: 7.5rem;
    }
  }

  .video {
    &:after {
      display: block;
    }

    .videoCover {
      &:after {
        left: 5rem;
        right: 2rem;
      }
    }
  }
}

.artist {
  margin-bottom: 3.125rem;
  width: 100%;

  .title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  .upcomingEventsContainer {
    margin-top: 3rem;

    .center {
      display: flex;
      justify-content: center;

      .more {
        @include reset;
        border-bottom: 0.125rem solid $default_main;
        color: $default_black;
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: bold;
        margin-bottom: 1rem;
        padding-bottom: 0.125rem;
      }
    }
  }

  .header {
    h1 {
      font-size: 2rem;
      font-weight: $default_fontBoldWeight;
    }
  }

  .tagBar {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0 2rem 0;

    .visitors {
      color: $artist_visitorsFont;
      font-weight: $default_fontBoldWeight;
      width: $artist_rightColumnWidth;
    }
  }

  .tagBarDescription {
    background-color: $default_white;
    margin-top: 0;
    padding: 0 4rem 6rem;
  }

  .twoColumns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fixedBox {
      padding-bottom: 10rem;
      width: 100%;
    }

    .artistDetails {
      .customImage {
        border-radius: 0;
        height: 7.875rem;

        &:first-child {
          height: 37.675rem;
        }
      }
    }
  }

  .pastEvents {
    margin-top: 3rem;
  }
}
