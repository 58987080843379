@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  .tag {
    border: $default_solidBorder $events_tagMainBackground;
    border-radius: 0.25rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    padding: 0.25rem 0.5rem;
    text-transform: capitalize;

    &.disableCapitalize {
      text-transform: none;
    }
  }

  .tagOther {
    border: $default_solidBorder $events_tagOtherBorder;
    padding: 0.25rem 0.5rem;
  }
}

.tag {
  font-size: 0.875rem;
  margin-right: 0.5rem;
  width: fit-content;
}

.tagOther {
  font-weight: bold;
  padding: 0.1875rem 0;
}

.tagMain {
  background: $events_tagMainBackground;
  border-radius: 0.1875rem;
  padding: 0.1875rem 0.625rem;
}
