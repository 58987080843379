@use 'sass:math';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

.heart {
  border: $heart_border solid $heart_borderColor;
  border-radius: 0;
  border-right-color: transparent;
  border-top-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  height: $heart_size;
  transform: rotate(-45deg);
  width: $heart_size;

  &:before,
  &:after {
    border: $heart_border solid $heart_borderColor;
    border-bottom-color: transparent;
    border-radius: 50%;
    border-right-color: transparent;
    box-sizing: border-box;
    content: '';
    height: $heart_size;
    position: absolute;
    width: $heart_size;
  }

  &:before {
    left: -$heart_border;
    top: -#{math.div($heart_size, 2)};
    transform: rotate(45deg);
  }

  &:after {
    left: math.div($heart_size, 2) - $heart_border * 2;
    top: -$heart_border;
    transform: rotate(135deg);
  }
}

.active.heart {
  background: $heart_activeColor;
  border: $heart_border solid $heart_activeColor;

  &:before,
  &:after {
    background: $heart_activeColor;
    border: $heart_border solid $heart_activeColor;
  }
}
