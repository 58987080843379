@import '@Styles/defs/colors.scss';
@import '@Styles/defs/animations.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .normal {
    &.content {
      .fakeButtonContainer {
        padding-top: unset;

        .fakeButton {
          width: unset;
        }
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & div {
    @include fakeDivCommons();
  }

  .fakeTags {
    display: flex;
    flex-direction: row;
    height: 1.375rem;
    margin-bottom: 1.25rem;
    width: 30%;
  }

  .fakeTitle {
    height: 1.875rem;
    margin: 0 0 1.5rem;
    width: 100%;
  }

  .fakeValue {
    height: 1rem;
    margin-bottom: 0.3125rem;
    width: 60%;
  }

  .fakeButtonContainer {
    align-items: flex-end;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-start;
    padding-top: 1.5625rem;

    .fakeButton {
      border: none;
      height: 3.1875rem;
      min-width: 10.75rem;
      width: 100%;
    }
  }
}
