@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin defaults {
  :global(.going) {
    .optionWrapper {
      border-radius: 1rem;

      .content {
        .ticketIcon {
          background: $eventBox_ticketIcon no-repeat 0 center;
          background-size: contain;
          border: none;
          height: auto;
          padding: 0.875rem 1.5rem;
          width: auto;
        }
      }
    }
  }

  h2.pageTitle {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .optionWrapper {
    box-shadow: $default_shadow;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 1.5rem 2rem;

    &.footer {
      background-color: transparent;
      box-shadow: none;
    }

    .content {
      display: flex;
      flex-flow: row nowrap;
      margin: auto 1rem auto 0;

      &.clickable {
        cursor: pointer;
      }

      .arrow {
        @include arrow;
        display: flex;
        justify-content: center;
        min-width: 3rem;

        &::before {
          border-color: $myTicket_iconColor;
          left: 0.9rem;
          top: 0.45rem;
          transform: rotate(-135deg);
        }

        &::after {
          border-color: $myTicket_iconColor;
          left: 0.4rem;
          top: 0;
        }
      }

      .ticketIcon {
        color: $myTicket_iconColor;
        font-size: 1.75rem;
        margin: auto 0;
      }

      p.optionText {
        font-size: 1.25rem;
        font-weight: $default_fontBoldWeight;
        margin: auto 1rem;
        overflow: hidden;
      }
    }

    .button {
      @include primaryButton();
      @include disabled(0.5);

      font-size: 1.125rem;
      font-weight: bold;
      min-width: 10.97rem;
      text-align: center;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  :global(.mobile),
  :global(.tablet) {
    .optionWrapper {
      flex-flow: row wrap;

      .content {
        .arrow {
          display: block;
          min-width: 1.43rem;

          &::before {
            left: 0.53rem;
            top: 0.55rem;
          }

          &::after {
            left: 0.53rem;
            top: -0.45rem;
          }
        }

        .ticketIcon {
          font-size: 1rem;
        }

        .optionText {
          font-size: 1.25rem;
        }
      }

      .button {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
}
