@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.mobile) {
  .highlightWrapper {
    .leftColumn {
      .imageWrapper {
        height: 2.5rem;
        min-width: 2.5rem;
        padding: 0.5rem;
        width: 2.5rem;
      }
    }
  }
}

.highlightWrapper {
  @include topBottomMargins;
  align-items: center;
  border-bottom: $search_highlightListBorder $default_solidBorder;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 1rem;
  opacity: 0.7;
  padding-bottom: 1rem;
  transition: $default_transition;
  width: 100%;

  &:hover {
    opacity: 1;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .leftColumn {
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;

    .imageWrapper {
      background-color: $search_highlightIconBackground;
      border-radius: 50%;
      box-sizing: border-box;
      height: 3.5rem;
      overflow: hidden;
      padding: 0.75rem;
      position: relative;
      width: 3.5rem;

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1rem;

      h4 {
        font-size: 1.25rem;
        font-weight: $default_fontBoldWeight;
        hyphens: auto;
        word-break: break-word;
      }

      p {
        font-size: 0.875rem;
      }
    }
  }

  .rightColumn {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;

    .counter {
      align-items: center;
      background-color: $search_highlightListCounterBackground;
      border-radius: 0.25rem;
      display: flex;
      height: 3.5rem;
      justify-content: center;
      margin-left: 1rem;
      width: 3.5rem;

      p {
        font-weight: $default_fontBoldWeight;
      }
    }
  }
}
