@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.mobile) {
  .premiumBadge .tooltip .tooltipText {
    left: unset;
    right: 0;
    transform: unset;

    &::after {
      left: unset;
      right: 10px;
    }
  }
}

.premiumBadge {
  align-items: center;
  color: $events_premiumBadgeFont;
  display: flex;
  font-weight: $default_fontBoldWeight;
  margin-left: 1rem;

  .starIcon {
    display: block;
    padding-right: 0.5rem;
  }

  .tooltip {
    display: flex;
    height: 3.125rem;
    justify-content: center;
    position: relative;
    width: 2rem;

    &:hover .tooltipText {
      display: block;
    }

    .tooltipText {
      background: $events_premiumBadgeTooltipBackground;
      border-radius: 0.25rem;
      bottom: 3.5rem;
      box-shadow: $events_premiumBadgeTooltipShadow;
      color: $events_premiumBadgeTooltipFont;
      display: none;
      font-size: 0.75rem;
      font-weight: normal;
      left: 1rem;
      padding: 1rem;
      position: absolute;
      transform: translateX(-50%);
      width: 12rem;
      z-index: 1;

      &::after {
        border-color: $events_premiumBadgeTooltipBackground transparent
          transparent transparent;
        border-style: solid;
        border-width: 5px;
        content: ' ';
        left: 50%;
        margin-left: -5px;
        position: absolute;
        top: 100%;
      }

      strong {
        color: $events_premiumBadgeFont;
      }

      a {
        @include primaryButton;

        background-color: $events_premiumBadgeTooltipLinkBackground;
        border: none;
        color: $events_premiumBadgeTooltipLinkFont;
        display: block;
        margin-top: 1rem;
        padding: 1rem;
        text-align: center;
      }
    }
  }
}
