@import '@Styles/defs/colors.scss';

:global(.going) {
  &:global(.desktop) {
    .sectionTitle {
      font-size: 3rem;
      margin: 5rem 0 2rem;
    }
  }

  .sectionTitle {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 3rem 0 1rem;
  }
}

:global(.desktop) {
  .accordion,
  .title {
    font-size: 1.5rem;
  }
}

.accordion:first-child .title {
  border: none;
}

.expanded .title {
  color: $place_accordionColor;

  &:after {
    transform: rotate(180deg);
  }
}

.text:after {
  display: block;
  padding: 1rem 0;
}

.title {
  border-top: 1px solid $place_accordionBorder;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  justify-content: space-between;
  padding: 1.25rem 0;

  &:after {
    background-image: url('~@Styles/images/collapse.svg');
    background-size: 20px;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
  }
}
