@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  .description {
    button {
      border-bottom: $default_borderUnderline $pools_buttonBorderHover;
      font-size: 0.875rem;
      font-weight: 500;
      text-transform: lowercase;

      &:focus {
        border-bottom: $default_borderUnderline $pools_buttonBorderHover;
      }
    }

    .arrow {
      border: solid $pools_descriptionFont;
      border-width: 0 0.125rem 0.125rem 0;
      display: inline-block;
      margin-left: 0.1875rem;
      padding: 0.125rem;
      position: absolute;
      right: -0.75rem;

      &.down {
        top: 0.25rem;
        transform: rotate(45deg);
      }

      &.up {
        top: 0.4375rem;
        transform: rotate(-135deg);
      }
    }
  }
}

.description {
  line-height: 0.75rem;

  button {
    background: none;
    border: none;
    color: $pools_descriptionFont;
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    position: relative;

    &:focus {
      border: none;
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .arrow {
    border: solid $pools_descriptionArrow;
    border-width: 0 0.125rem 0.125rem 0;
    display: inline-block;
    margin-left: 0.1875rem;
    padding: 0.125rem;
    position: absolute;
    right: -0.75rem;

    &.down {
      top: 0.25rem;
      transform: rotate(45deg);
    }

    &.up {
      top: 0.4375rem;
      transform: rotate(-135deg);
    }
  }
}
