@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

.radioButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;

  label {
    align-items: center;
    display: flex;
  }

  input[type='radio'] {
    left: #{$infinity}px;
    opacity: 0;
    position: absolute;

    &:checked + .selector {
      position: relative;

      &:before {
        border: 0.4375rem solid $formField_radioBorder;
        border-radius: 0.75rem;
        box-sizing: border-box;
        color: $formField_radioFont;
        content: '';
        font-size: 1.375rem;
        height: 1.5rem;
        left: -1px;
        position: absolute;
        top: -1px;
        width: 1.5rem;
      }
    }
  }

  .selector {
    border: $default_solidBorder $formField_radioSelectorBorder;
    border-radius: 0.75rem;
    box-sizing: border-box;
    display: block;
    flex: 0 0 1.5rem;
    height: 1.5rem;
    margin-right: 0.625rem;
    width: 1.5rem;
  }

  .error {
    bottom: -1.5rem;
    color: $formField_errorFont;
    font-size: 0.75rem;
    left: 0;
    position: absolute;
  }
}
