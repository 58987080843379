@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

.bigImage {
  flex: 1 1 0;
  max-height: calc(100vh - 15rem);
  object-fit: contain;
  user-select: none;
  width: 100%;
}

.arrow {
  @include chevronArrow;
  cursor: pointer;
  position: absolute;
  right: -3rem;
  top: 40%;
  z-index: 0;

  &.left {
    left: -3rem;
    right: unset;
  }
}

.miniatures {
  display: flex;
  flex-flow: row nowrap;
  list-style-type: none;
  margin: 1rem auto 0;
  min-height: 10rem;
  overflow-x: scroll;
  position: relative;
  user-select: none;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  .miniatureContainer {
    height: 10rem;
    margin-right: 1rem;
    max-height: 100%;
    min-width: 15rem;
    user-select: none;

    &:last-child {
      margin-right: 0;
    }

    .miniature {
      cursor: pointer;
      filter: brightness(0.4);
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      user-select: none;
      width: 100%;

      &.active {
        filter: none;
      }
    }
  }
}
