@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/functions.scss';

:global(.going),
:global(.sbm) {
  .pickUpWay {
    .header {
      font-weight: 500;
      text-transform: lowercase;
    }
  }
}

:global(.desktop) {
  .pickUpWay {
    .formPanel {
      padding: 2.375rem 1.875rem;
    }
  }
}

.pickUpWay {
  @include disabledClass();

  margin-top: 3.75rem;
  width: 100%;

  .header {
    font-size: 1.75rem;
    margin-bottom: 2rem;

    &:before {
      content: attr(data-number) '. ';
    }
  }

  .formPanel {
    border: none;
    border-radius: borderRadius(0, 0, 0, 0);
    box-shadow: $pickUpWay_formPanelShadow;
    box-sizing: border-box;
    padding: 1.75rem 1.25rem;
    width: 100%;

    .label {
      font-size: 0.875rem;
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      .icon,
      .specialInfo {
        font-size: 0.875rem;
        margin-left: 0.5rem;
      }

      .specialInfo {
        font-weight: $default_fontBoldWeight;
      }

      &.labelDisabled {
        filter: grayscale(100%);
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
