@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.ticketIcon {
  padding: 0.625rem 1.5rem;
}

.section {
  background: $basket_itemBackground;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  position: relative;

  .contentWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .container {
      display: flex;
      flex: 1;
    }

    .box {
      flex: 1;
      padding: 1rem 1.375rem 1.375rem;

      &.boxPrice {
        align-items: center;
        display: flex;
      }

      .boxWrapper {
        display: block;
        width: 100%;
      }

      .placeContainer {
        display: flex;
        flex-direction: column;
      }

      .title {
        font-size: 1.125rem;
        font-weight: 500;
      }
      .rundate,
      .place {
        font-size: 0.75rem;
        font-weight: 500;
      }
      .priceWrapper {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;

        .amount {
          display: flex;
          font-size: 1.125rem;
          font-weight: 500;
        }

        .price {
          font-size: 2.25rem;
          font-weight: 600;

          .currency {
            font-size: 1.125rem;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.ticketIcon {
  background: $eventBox_ticketIcon no-repeat 0 center;
  background-size: contain;
  border: none;
  font-size: 0.75rem;
  height: auto;
  margin-right: 1rem;
  padding: 0.625rem 1rem;
  width: auto;
}

@media (max-width: 600px) {
  .section {
    .contentWrapper {
      flex-direction: column;

      .box {
        &.boxPrice {
          justify-content: flex-end;
        }

        .priceWrapper {
          justify-content: space-between;
        }
      }
    }
  }
}
