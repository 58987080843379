@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  .detailsLoading {
    .fakeDescription {
      &:before {
        display: none !important;
      }
    }
  }
}

:global(.desktop) {
  .detailsLoading {
    .fullWidth {
      margin-left: -5rem;
    }

    .fakeDescription {
      padding-left: 5rem;

      &:before {
        height: 5.625rem;
        left: 0;
        margin-bottom: unset;
        position: absolute;
        top: 0.5rem;
        width: 3rem;
      }

      .fakeParagraph {
        margin-bottom: 3rem;

        .fakeLine {
          @include fakeDivCommons;

          height: 1.5rem;
        }
      }
    }
  }
}

.detailsLoading {
  width: 100%;

  .fakeCover {
    @include fakeDivCommons;

    border-radius: $default_borderRadiusMain;
    margin-bottom: 5rem;
    width: 100%;
  }

  .fakeDescription,
  .fakeShortDescription {
    margin-bottom: 2.5rem;
    position: relative;

    &:before {
      background-image: url('~@Styles/images/empik-apostrophe.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 2.375rem;
      margin-bottom: 1rem;
      width: 1.25rem;
    }

    .fakeParagraph {
      margin-bottom: 2rem;

      .fakeLine {
        @include fakeDivCommons;

        height: 1.375rem;
        margin: 0.75rem 0;
        width: 100%;
      }
    }
  }
  .fakeShortDescription {
    margin: 0;

    &:before {
      content: normal;
    }
  }
}
