@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

.mobile {
  .headers {
    display: none;
  }

  .calendar {
    display: block;
    width: auto;

    .days,
    .week {
      justify-content: center;
    }

    .month:first-child {
      margin-bottom: 3rem;
    }
  }
}

.dataPicker {
  font-size: 0.875rem;
}

.headers {
  color: $calendar_headers;
  display: flex;
  font-size: 0.75rem;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.calendar {
  display: flex;
  justify-content: space-between;
  width: 34rem;

  .days,
  .week {
    display: flex;

    .day {
      color: $calendar_nameOfDay;
      margin: 0.125rem;
      text-align: center;
      width: 1.875rem;
    }
  }

  .week .day {
    border-radius: 100%;
    color: $calendar_inactiveDay;
    height: 1.875rem;
    line-height: 1.875rem;
    position: relative;
    text-align: center;
  }

  .day.selectable {
    color: $calendar_activeDay;
    cursor: pointer;

    &:hover {
      background: $calendar_hover !important;
      color: $calendar_fontLight;
    }
  }

  .day.selected {
    background: $calendar_selected;
  }

  .day.chosen {
    background: $calendar_chosen;
    color: white;
  }

  .day.hovered {
    background: $calendar_hovered !important;
    color: $calendar_fontLight !important;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    span {
      font-weight: bold;
    }

    button {
      background: none;
      border: none;
      color: $calendar_arrows;
      cursor: pointer;
      font-weight: bold;
    }
  }
}

.current {
  font-weight: bold;
  text-decoration: underline;
}

.buttonContainer {
  border-top: $default_solidBorder $calendar_buttonBorder;
  margin-top: 1rem;
  padding: 2rem 2rem 1rem;
  text-align: center;

  button {
    background: none;
    border: $default_solidBorder $calendar_button;
    border-radius: 0.25rem;
    color: $calendar_button;
    cursor: pointer;
    font-size: 0.875rem;
    min-width: 60%;
    padding: 0.75rem;
  }
}
