@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .wrapper {
    a {
      font-size: 2.5rem;
    }
  }
}

.wrapper {
  @include topBottomMargins;
  margin: 1rem auto;
  text-align: center;
  width: 100%;

  a {
    color: $banner_fontColor;
    font-size: 1.5rem;
  }
}

:global(.dark) {
  .wrapper {
    a {
      color: $banner_lightFontColor;
    }
  }
}
