@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.empik) {
  &:global(.desktop) {
    .section {
      box-shadow: $default_shadow;
      margin-bottom: 1.5rem;

      &.withoutPrice {
        box-shadow: none;

        .contentWrapper {
          .container {
            .box {
              .title {
                font-size: 1.375rem;
              }
            }
          }
        }
      }

      .contentWrapper {
        .container {
          .box {
            &.firstBox {
              align-items: normal;

              .titleWrapper {
                margin-bottom: 0;
              }
            }

            .placeContainer {
              .rundate,
              .place {
                font-size: 0.75rem;
              }
            }
          }
        }

        .box {
          align-items: center;
          display: flex;
          padding-bottom: 1.5rem;

          .title {
            font-size: 1.25rem;
          }

          .serviceFee {
            font-size: 0.75rem;
            font-weight: 700;

            span {
              font-weight: 900;
            }
          }
        }
      }
    }

    .ticketIcon {
      background-position: center;
      background-size: contain;
    }
  }

  .section {
    border-bottom: 0.0625rem solid $basket_porcelain;
    border-radius: 0;
    margin-top: 0;

    &.withoutPrice {
      .contentWrapper {
        .container {
          .box {
            &.firstBox {
              padding: 0 1.375rem 1.375rem;
            }
          }
        }
      }
    }

    .contentWrapper {
      .container {
        .box {
          .title {
            display: flex;
          }

          .placeContainer {
            .rundate,
            .place {
              font-size: 0.75rem;
              font-weight: 700;
              line-height: 1.25rem;
            }

            .place {
              border-bottom: 0.125rem solid $basket_placeBorder;
              width: fit-content;
            }
          }

          &.firstBox {
            padding: 1.5rem 1.25rem;
            width: 100%;

            .titleWrapper {
              display: flex;
              justify-content: space-between;
              margin-bottom: 1.2rem;

              .price {
                color: $basket_totalPriceColor;
                font-weight: 900;
                line-height: 1.8rem;
                margin-bottom: 0.3rem;
              }
            }
          }
        }
      }
    }
  }

  .ticketIcon {
    background: $eventBox_ticketIcon no-repeat 0 0.2rem;
    display: inline-block;
    margin-right: 0.875rem;
    padding: 0.225rem 0.8125rem;
  }
}

:global(.desktop) {
  .section {
    &.withoutPrice {
      margin: 0 -1.875rem;
      margin-bottom: 2.4375rem;

      .contentWrapper {
        .container {
          padding: 0 1.875rem;

          .box {
            padding-top: 0;

            &.boxWithoutPrice {
              padding-bottom: 2rem;
            }

            .title {
              font-size: 1.125rem;
            }

            .rundate,
            .place {
              font-size: 0.875rem;
              font-weight: 400;
            }
          }
        }
      }
      .ticketIcon {
        background: $eventBox_ticketIcon no-repeat 0 0.2rem;
        background-size: contain;
      }
    }

    .close {
      filter: invert(25%);
      height: 0.75rem;
      margin-left: 0.75rem;
      position: static;
      width: 0.75rem;
    }

    .contentWrapper {
      .container {
        padding: 0 2.1875rem;
      }

      .box {
        padding: 1.4375rem 2rem 1.125rem;

        &.firstBox {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 1.4375rem 2rem 1.125rem 1rem;
        }

        .boxWrapper {
          align-items: center;
          display: flex;
          flex-grow: 1;

          .closeContainer {
            align-items: center;
            color: $basket_deleteItem;
            cursor: pointer;
            display: flex;
            font-size: 0.75rem;
            margin-right: 1.1875rem;
          }

          .poolContainer {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }
        }

        .placeContainer {
          flex-direction: row;
        }

        .place {
          border-bottom: 0.125rem solid $basket_placeBorder;
          margin-left: 0.5rem;
        }

        .serviceFee {
          text-align: unset;
        }
      }
    }
  }

  .buttonsWrapper {
    flex-direction: row;
    justify-content: space-between;

    .buttonWrapper {
      width: 47%;
    }
  }

  .ticketIcon {
    padding: 0.625rem 1.5rem;
  }

  .productIcon {
    margin-right: 1rem;
    padding: 1.125rem 0;
  }
}

.section {
  background: $basket_itemBackground;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1.1875rem;
  position: relative;

  &.withoutPrice {
    border-radius: 0;
    margin: 0 -1.25rem;
    margin-bottom: 1.75rem;

    .contentWrapper {
      .container {
        padding: 0 1.25rem;
      }

      .box {
        padding: 0 1.375rem 1.375rem;

        .title {
          font-size: 1rem;
        }

        &.boxWithoutPrice {
          padding: 0;
          padding-bottom: 1.375rem;
        }
      }
    }

    .ticketIcon {
      padding: 0.625rem 1.0625rem;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .container {
      border-bottom: 0.0625rem solid $basket_border;
      display: flex;
    }

    .box {
      padding: 1.5rem 1.25rem;

      .boxWrapper {
        display: block;
      }

      .placeContainer {
        display: flex;
        flex-direction: column;
      }

      .title {
        font-size: 1.25rem;
        font-weight: $default_fontBoldWeight;
        margin-bottom: 0.3rem;
      }

      .rundate,
      .place {
        font-size: 0.75rem;
        font-weight: 500;
      }

      .serviceFee {
        font-size: 0.625rem;
        text-align: right;

        span {
          font-weight: 600;
        }
      }
    }
  }
}

.ticketIcon {
  background: $eventBox_ticketIcon no-repeat 0 center;
  background-size: contain;
  border: none;
  font-size: 0.75rem;
  height: auto;
  margin-right: 1rem;
  padding: 0.625rem 1rem;
  width: auto;
}
