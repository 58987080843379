@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .container {
    display: grid;
    grid-template-columns: 2fr 8fr 3fr 1fr;
    grid-template-rows: 100%;
    padding: 1.5rem 0;

    &:last-of-type {
      padding-bottom: 0.75rem;
    }

    .line {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.totalPriceContainer {
        margin-bottom: 0;

        .summaryPrice {
          color: $basket_summaryPrice;
          font-size: 0.75rem;
        }
      }

      &.row {
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;

        &.ticketAmountContainer {
          justify-content: flex-start;

          .ticketAmount {
            color: $basket_ticketAmount;
            font-size: 2rem;
            font-weight: $default_fontBoldWeight;
            margin-left: 2rem;
          }
        }
      }

      .pricePerItem {
        color: $basket_pricePerItem;
        font-size: 2rem;
        font-weight: $default_fontBoldWeight;
        margin-left: 2rem;
      }

      .currency {
        font-size: 1.375rem;
      }

      .totalPrice {
        border-bottom: 0.125rem solid $basket_totalPriceBorderColor;
        color: $basket_totalPrice;
        font-size: 2rem;
        font-weight: $default_fontBoldWeight;
        margin-left: 1.5rem;
      }

      .poolName {
        margin-bottom: 0;
      }

      .pieces {
        font-size: 1.375rem;
      }

      .serviceFeeContainer {
        flex-direction: column;
        flex-grow: 0;
        justify-content: flex-start;

        .serviceFee {
          color: $basket_serviceFee;
          font-weight: $default_fontBoldWeight;
        }
      }
    }

    .infoContainer {
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 100%;
      padding-bottom: 0;
    }

    .deleteContainer {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }

    .poolNameContainer {
      justify-self: flex-start;
    }
  }
}

.container {
  border-bottom: 0.0625rem solid $basket_poolContainerBorderColor;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  .infoContainer {
    color: $basket_infoContainer;
    font-size: 0.75rem;
    padding-bottom: 1.5rem;
  }

  .line {
    display: flex;
    justify-content: space-between;

    &.totalPriceContainer {
      font-size: 0.875rem;

      .price {
        font-weight: $default_fontBoldWeight;
      }
    }

    .poolName {
      font-weight: $default_fontBoldWeight;
      margin-bottom: 0.5rem;
    }

    .serviceFeeContainer {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  .close {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 0.75rem;
    justify-content: center;
    position: relative;
    width: 0.75rem;

    &::before,
    &::after {
      background-color: $basket_cancelIcon;
      content: '';
      height: 0.0625rem;
      position: absolute;
      width: 100%;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  .info {
    margin-bottom: 1.5rem;
  }
}
