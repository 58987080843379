@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .datePicker {
    flex: none;
    margin-bottom: 0;
    margin-right: 2.5rem;
    width: 100%;

    & > div {
      font-size: 2rem;
    }
  }
}

.datePicker {
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

:global {
  .react-datepicker {
    background: none;

    .react-datepicker__header {
      background: none;
      border: none;
      height: 3rem;

      .react-datepicker__current-month {
        color: $happening_datePicker_fontColor;
        font: $default_font;
        font-size: 1rem;
        position: absolute;
        right: 1rem;
        text-align: center;
        width: 9.5rem;
      }
    }
    .react-datepicker__navigation {
      border-width: 0.5rem;
      top: 1.0625rem;
    }
    .react-datepicker__navigation--previous {
      left: auto;
      right: 10.5rem;
    }
    .react-datepicker__navigation--next {
      right: 0;
    }
    .react-datepicker__day-names .react-datepicker__day-name {
      border: none;
      color: $happening_datePicker_dayColor;
      font: $default_font;
      font-size: 0.69rem;
      font-weight: 500;
      height: 0.69rem;
      line-height: 2rem;
      padding-left: 0.10625rem;
      text-align: left;
    }
    .react-datepicker__week .react-datepicker__day,
    .react-datepicker__week:first-child .react-datepicker__day {
      // not so pretty, but only working solution i was found
      @media (min-width: 576px) {
        font-size: 1.3rem;
        padding: 1rem 0;
      }

      border: 1px solid $happening_datePicker_dayBorder;
      border-radius: 0.5rem;
      color: $happening_datePicker_fontColor;
      font: $default_font;
      font-size: 1rem;
      font-weight: normal;
      margin: 0.1rem;
      padding: 0.4rem 0;
    }

    .react-datepicker__week:last-child {
      border: none;
    }
    .react-datepicker__day:hover {
      background: $happening_datePicker_hover;
      color: $happening_datePicker_selectedText;
    }

    .react-datepicker__day--selected {
      background: $happening_datePicker_selected;
      color: $happening_datePicker_fontColor;
    }
  }

  .dark {
    .react-datepicker__header {
      .react-datepicker__current-month {
        color: $happening_datePicker_darkMode_fontColor;
      }
    }

    .react-datepicker__week .react-datepicker__day,
    .react-datepicker__week:first-child .react-datepicker__day {
      color: $happening_datePicker_darkMode_fontColor;
    }

    .react-datepicker__day:hover {
      color: $happening_datePicker_selectedText;
    }

    .react-datepicker__day--selected {
      color: $happening_datePicker_darkMode_fontColor;
    }
  }
}
