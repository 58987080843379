/*
 * DEFAULT COLORS
 */

$default_hangarBgGradient: linear-gradient(
  225deg,
  #ff00ce,
  #fe0f00 47%,
  #fe7b00
);
$default_hangarBorderColor: $default_black;
$default_hangarButtonBackground: $default_hangarBgGradient;
$default_hangarButtonBorder: $default_black;
$default_hangarButtonTextColor: $default_black;
