@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  .wrapper {
    border-radius: $default_borderRadiusSmall;

    .articlesList {
      border-radius: $default_borderRadiusSmall;
    }
  }
}

.wrapper {
  display: block;
  margin: 1rem 0;
  position: relative;
  width: 100%;

  .articlesList {
    @include sliderContainer;
  }
}

:global(.mobile) {
  .wrapper {
    .articlesList {
      @include sliderContainerMobile;
    }
  }
}
