@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .wrapper {
    padding: 5rem 0;

    &.inner {
      padding: 2rem 0;
    }
  }
}

.wrapper {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 0;

  &.inner {
    padding: 2rem 0;
  }

  &.contain {
    background-size: contain;
  }

  &.gradient {
    background-position: top center;
    background-size: 100% auto;
  }

  .content {
    flex-direction: column;
    margin: 0 auto;
    max-width: $default_desktopWidth;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: relative;

    &.row {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
