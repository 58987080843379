@import '@Styles/defs/colors.scss';

:global(.going) {
  .similarPlacesTitle {
    font-size: 3rem;
  }
}

:global(.desktop) {
  .similarPlaces {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .place {
      width: 30%;
    }
  }
}

.similarPlaces {
  .place {
    border-bottom: 1px solid $place_similarPlacesBorder;
    display: flex;
    flex-direction: column;
    padding: 2rem 0;

    img {
      width: 100%;
    }

    .name {
      color: $place_similarPlacesNameColor;
      font-size: 1.375rem;
      font-weight: bold;
      padding: 1rem;
    }

    .city {
      color: $place_similarPlacesCityColor;
      font-weight: bold;
      padding: 0 1rem;
    }
  }
}
