@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/functions.scss';
@import '@Styles/defs/props.scss';

:global(.going),
:global(.sbm) {
  .pools {
    .header {
      font-weight: 500;
      text-transform: lowercase;
    }
  }
}

:global(.desktop) {
  .formPanel {
    padding: 2.375rem 1.875rem;
  }
}

.pools {
  width: 100%;

  &.disabled {
    @include disabledStyles();
  }

  .header {
    font-size: 1.75rem;
    margin-bottom: 2rem;

    &:before {
      content: attr(data-number) '. ';
    }
  }

  .noResults {
    color: $pools_noResultsFont;
    font-size: 2.5rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li:first-child > div {
      border-radius: borderRadius(1, 0, 0, 0);
    }
  }
}

.loadingWrapper {
  & > div:first-child {
    border-radius: borderRadius(1, 0, 0, 0);
  }
}

.formPanel {
  border: none;

  .label {
    display: inline-block;
    font-size: 0.875rem;
    position: relative;
    width: 100%;

    .labelHeader {
      h4 {
        font-size: 1rem;
        font-weight: $default_fontBoldWeight;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.formPanel {
  border: none;
  border-radius: borderRadius(0, 0, 0, 0);
  box-shadow: $clientData_formPanelShadow;
  box-sizing: border-box;
  padding: 1.75rem 1.25rem;
  transition: $default_animationTime;
  width: 100%;

  &.hasDependency {
    height: auto;
    margin-bottom: 2rem;
    padding-bottom: 2.375rem;
    padding-top: 2.375rem;
  }

  &.noDependency {
    height: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}
