@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin hangar {
  :global(.hangar) {
    .formPanel {
      .label {
        .column {
          input[type='radio']:checked + label {
            background-image: $default_hangarBgGradient;
            border: $default_hangarBorder $default_hangarBorderColor;
            color: $default_black;
            transform: none;
          }

          label {
            border: $default_hangarBorder $default_hangarBorderColor;
            border-radius: 0;

            color: $default_black;
            cursor: pointer;
            float: left;
            font-size: 1.125rem;
            font-weight: bold;
            margin: 0.25rem;
            padding: 0.75rem;
            text-align: center;
            transition: none;

            &:first-child {
              margin-left: 0;
            }

            &:hover {
              background-image: $default_hangarBgGradient;
              border: $default_hangarBorder $default_hangarBorderColor;
              color: $default_black;
              transform: none;
            }
          }
        }
      }
    }

    .submitButton {
      @include hangarDefaultButton;
    }
  }
}
