@import '@Styles/defs/colors.scss';

:global(.going) {
  &:global(.desktop) {
    .buyContainer {
      .buyButton {
        padding: 1rem 1.65rem;
      }
    }
  }

  .buyContainer {
    .buyButton {
      font-size: 1.15rem;
      padding: 1.25rem 1.5rem;
    }
  }
}

:global(.desktop) {
  .buyContainer {
    padding-top: unset;

    .buyButton {
      padding: 0.9375rem 1.75rem;
      width: unset;
    }
  }
}

.buyContainer.withPaddingTop {
  padding-top: 1rem;
}

.buyContainer {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  padding-top: 1.563rem;

  .buyButton {
    background: $events_buyButtonBackground;
    border: none;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: $events_buyButtonFont;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 0.9375rem;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    &.notForSale {
      background: $events_moreButtonBackground;
    }

    &:hover {
      background: $events_buyButtonHoverBackground;
    }
  }
}
