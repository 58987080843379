@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.sectionTitle {
  font-size: $landingPage_sectionTitleFontSize;
  font-weight: $landingPage_boldFontWeight;
  margin: $landingPage_sectionTitleMargin;
  user-select: none;
}

.infoSectionText {
  &.center {
    text-align: center;
  }
}
