@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.empik) {
  &:global(.desktop) {
    .additionalFieldsContainer {
      .ticketContainer {
        .ticketTitle {
          font-size: 1.5rem;
        }
        .poolName {
          font-size: 0.875rem;
        }
      }
    }
  }

  .additionalFieldsContainer {
    .ticketContainer {
      .ticketTitle {
        font-weight: $default_fontBolderWeight;
      }
      .poolName {
        font-weight: $default_fontBoldWeight;
      }
    }
  }
}

:global(.desktop) {
  .additionalFieldsContainer {
    margin: 0 -1.875rem;
    margin-bottom: 2.375rem;
    padding: 0 1.875rem;
    padding-bottom: 2.375rem;

    .ticketContainer {
      margin: 0 -1.875rem;
      margin-bottom: 2.375rem;
      padding: 0 1.875rem;
      padding-bottom: 2.375rem;

      .ticketTitle {
        font-size: 1.25rem;
        font-weight: $default_fontBoldWeight;
      }

      .poolName {
        color: $additionalFields_poolName;
        font-size: 0.75rem;
      }
    }

    .info {
      font-size: 0.875rem;
      font-weight: $default_fontWeight;
      margin-bottom: 0.125rem;
    }
  }
}

.additionalFieldsContainer {
  border-bottom: 0.0625rem solid $default_porcelain;
  margin: 0 -1.25rem;
  margin-bottom: 1.75rem;
  padding: 0 1.25rem;
  padding-bottom: 1.75rem;

  .fillWithBuyerDataButton {
    @include primaryButton();
    font-weight: $default_fontBoldWeight;
    margin-top: 1rem;
    text-align: center;
  }

  .ticketContainer {
    border-bottom: 0.0625rem solid $default_porcelain;
    margin: 0 -1.25rem;
    margin-bottom: 1.75rem;
    padding: 0 1.25rem;
    padding-bottom: 1.75rem;

    .ticketTitle {
      font-size: 1.25rem;
      font-weight: $default_fontBoldWeight;
      margin-bottom: 0.25rem;
    }

    .poolName {
      color: $additionalFields_poolName;
      font-size: 0.75rem;
    }
  }

  .info {
    font-size: 0.875rem;
    font-weight: $default_fontWeight;
    margin-bottom: 0.125rem;
  }
}
