@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin hangar {
  :global(.hangar) {
    .wrapper {
      font-family: $default_hangarFontMedium;

      .header {
        font-size: 1.87rem;
      }

      .submitButton {
        @include hangarDefaultButton;
      }
    }
    .clientDatepicker {
      display: block;
    }
    .defaultDatepicker {
      display: none;
    }

    .dateInput {
      border-bottom: none;
      border-left: $default_hangarBorder $default_hangarBorderColor;
      border-right: $default_hangarBorder $default_hangarBorderColor;
      border-top: $default_hangarBorder $default_hangarBorderColor;
      display: flex;
      font-size: 1.25rem;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 0.81rem;
      width: 11.8rem;

      &.close {
        border: $default_hangarBorder $default_hangarBorderColor;
      }

      &:hover {
        cursor: pointer;
      }

      .text {
        margin-top: 0.31rem;
      }

      .icon {
        background-image: url('~@Styles/images/clients/calendar.svg');
        height: 2.25rem;
        width: 2.25rem;
      }
    }
  }

  :global(.desktop),
  :global(.bigDesktop) {
    .dateInput {
      width: 23.25rem;
    }
  }
}
