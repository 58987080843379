@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .chevron {
    border-width: 0.15rem 0.15rem 0 0;
    height: 0.5rem;
    top: calc(50% - 0.5rem);
    width: 0.5rem;
  }
}

.chevron {
  border: $chevron_color solid;
  border-width: 0.15rem 0.15rem 0 0;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 0.35rem;
  position: absolute;
  right: 1rem;
  top: calc(50% - 0.35rem);
  transform: rotate(135deg);
  transition: $chevron_animation;
  vertical-align: top;
  width: 0.35rem;
}

.chevron.active {
  top: calc(50% - 0.1rem);
  transform: rotate(-45deg);
}
