@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.tablet) {
  .info {
    align-items: center;
  }

  .button {
    font-size: 1.3rem;
  }
}

:global(.desktop),
:global(.bigDesktop) {
  .button {
    font-size: 1.3rem;
  }
}

.wrapper {
  flex-grow: 1;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.timeSlots {
  display: grid;
  grid-gap: 0.2rem;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  list-style: none;
  width: 100%;

  li:focus {
    outline: none;
  }
}

.button {
  border: 1px solid $happening_datePicker_dayBorder;
  border-radius: 0.5rem;
  font-size: 1rem;
  height: 100%;
  padding: 1rem 0.2rem;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $happening_datePicker_hover;
    color: $happening_datePicker_selectedText;
    cursor: pointer;
  }

  &.selected {
    background-color: $happening_datePicker_selected;
    color: $happening_datePicker_selectedText;
  }

  &[disabled] {
    background: $default_grey;
    opacity: 0.3;
    pointer-events: none;
  }
}

.info {
  align-items: center;
  display: flex;
  margin-top: 2.5rem;
  width: 100%;
}

.noSlots {
  margin-top: 0;
}

.remember {
  margin-bottom: 1rem;
}

.infoIcon {
  margin-right: 1.2rem;
}
