@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .wrapper {
    .close {
      background-color: $gallery_viewerCloseBackgroundDesktop;
      color: $gallery_closeColorDesktop;
      font-size: 2rem;
      height: 2.75rem;
      line-height: 2.75rem;
      width: 2.75rem;
    }

    .viewer {
      max-width: $gallery_viewerImageWidth;
      position: relative;
      width: calc(100% - 10rem);
    }
  }
}

.wrapper {
  background: $gallery_viewerBackground;
  display: flex;
  height: 100vh;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;

  .close {
    background-color: $gallery_viewerCloseBackgroundMobile;
    border-radius: 50%;
    color: $gallery_closeColorMobile;
    cursor: pointer;
    font-size: 1.5rem;
    height: 2rem;
    line-height: 2rem;
    position: fixed;
    right: 1.5rem;
    text-align: center;
    top: 1.5rem;
    width: 2rem;
  }

  .viewer {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    margin: auto;
    max-height: 100vh;
    padding: 2rem 0;
    text-align: center;
    width: 90vw;
  }
}
