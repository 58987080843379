@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

// going desktop styles
:global(.going) {
  &:global(.desktop) {
    .event {
      margin-bottom: 5rem;

      .bannerContainer {
        margin-bottom: 5rem;
      }

      .header {
        h1 {
          font-size: 4rem;
        }
      }

      .twoColumns {
        .eventDetails {
          & > div:first-of-type {
            border-radius: $default_borderRadiusMain;
          }

          .video {
            padding: 4rem;

            .videoCover {
              &:before {
                height: 5rem;
                left: -1.5rem;
                right: 6rem;
                top: -1.5rem;
              }
            }
          }
        }
      }
    }
  }
}

// empik desktop styles
:global(.empik) {
  &:global(.desktop) {
    .event {
      .header {
        h1 {
          font-size: 4.5rem;
        }
      }

      .twoColumns {
        .eventDetails {
          & > div:first-of-type {
            border-radius: 1rem 0 1rem 0;
          }

          &:after {
            display: block;
          }

          .videoCover {
            &:after {
              left: 5rem;
              right: 2rem;
            }
          }
        }
      }

      .sectionWrapper {
        display: flex;
        justify-content: space-between;

        .list {
          display: flex;
          flex: 1 0 70%;
          flex-direction: column;
          margin-right: 2rem;
          min-width: 43.75rem;
        }
      }

      .similarEventsSection {
        border-radius: 1rem 0 1rem 0;
        box-sizing: border-box;
        transform: none;
        width: 100%;
      }
    }
  }
}

// shared desktop styles
:global(.desktop) {
  .event {
    .twoColumns {
      flex-direction: row-reverse;
      justify-content: space-between;

      .fixedBox {
        display: flex;
        flex: 0 1 $rightColumnWidth;
        flex-flow: column nowrap;
        margin-left: 1rem;
        padding-bottom: 10rem;
      }

      .eventDetails {
        display: flex;
        flex: 0 1 $leftColumnWidth;
        flex-flow: column nowrap;
        min-width: 50%;

        & > div:first-of-type {
          margin-bottom: 5rem;
          width: 100%;
        }
      }
    }

    .sectionTitle {
      font-size: 3rem;
      margin: 5rem 0 2rem;
    }
  }
}

// going base styles
:global(.going) {
  .event {
    .header {
      h1 {
        font-size: 1.5rem;
        line-height: 1;
      }
    }

    .twoColumns {
      .eventDetails {
        .video {
          background: $event_descriptionBackground;
          border-radius: 0 0 1rem 1rem;
          margin-bottom: 0;
          padding: 0 1rem 2rem;

          .videoCover {
            margin-bottom: 0;

            &:before {
              background: $event_videoWaterMarkBackground;
              content: '';
              display: block;
              height: 5rem;
              left: 1rem;
              position: absolute;
              right: 1rem;
              top: -0.5rem;
            }

            .player {
              iframe {
                border-radius: $default_borderRadiusSmall;
              }
            }
          }
        }
      }
    }
  }
}

// empik base styles
:global(.empik) {
  .event {
    .header {
      h1 {
        font-size: 2rem;
      }
    }

    .twoColumns {
      .eventDetails {
        .video {
          margin-bottom: 5rem;

          &:after {
            background-image: url('./../../misc/styles/images/empik-apostrophe.svg');
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: none;
            height: 20rem;
            opacity: 0.2;
            position: absolute;
            right: -13.5rem;
            top: -5rem;
            width: 10rem;
            z-index: -1;
          }

          .videoCover {
            margin-bottom: 3rem;

            &:after {
              background: $event_videoWaterMarkBackground;
              bottom: -1rem;
              content: '';
              display: block;
              height: 1rem;
              left: 3rem;
              position: absolute;
              right: 1rem;
            }
          }
        }
      }
    }
  }
}

// shared base styles
.event {
  margin-bottom: 3.125rem;
  max-width: 100%;
  width: 100%;

  .header {
    h1 {
      font-weight: $default_fontBoldWeight;
    }
  }

  .tagBar {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0 2rem 0;

    .visitors {
      color: $event_visitorsFont;
      font-weight: $default_fontBoldWeight;
      width: $rightColumnWidth;
    }
  }

  .twoColumns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fixedBox {
      padding-bottom: 10rem;
      width: 100%;
    }

    .eventDetails {
      width: 100%;

      .video {
        position: relative;

        .videoCover {
          position: relative;

          .player {
            height: 0;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 1.5625rem;
            position: relative;

            iframe {
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }
        }

        .videoDescription {
          font-size: 1.125rem;
          line-height: 1.75rem;
          padding-left: 5rem;
          padding-right: 2rem;
        }
      }
    }
  }

  h3 {
    font-size: 3rem;
  }

  .sectionTitle {
    font-size: 1.5rem;
    font-weight: $default_fontBoldWeight;
    margin: 3rem 0 1rem;
  }

  .similarEventsSection {
    background: $event_similarEventsSectionBackground;
    box-sizing: border-box;
    padding: 1.25rem;
    transform: translateX(-1.25rem);
    width: calc(100% + 2.5rem);
  }
}
