@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .description {
      margin-bottom: 0;
      padding: 4rem;

      .title {
        font-size: 1.5rem;
      }
      .image {
        margin: 2.5rem 0;
      }

      .player {
        margin: 2.5rem 0;
      }
    }
  }

  .description {
    border-radius: 1rem;
    margin-bottom: 0;
    padding: 2rem;

    &:before {
      display: none;
    }

    p {
      font-weight: 300;
      line-height: 1.5;

      &.firstParagraph {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.3;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .noVideo {
    border-radius: $default_borderRadiusMain;
  }

  .player {
    border-radius: 0.5rem;
  }
}

:global(.desktop) {
  .description {
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-bottom: 5rem;
    padding-left: 5rem;

    &.fullWidth {
      margin-left: -5rem;
    }

    &:before {
      height: 5.625rem;
      left: 0;
      margin-bottom: unset;
      position: absolute;
      top: 0.5rem;
      width: 3rem;
    }

    p {
      margin-bottom: 2rem;
    }

    .defaultTitle {
      font-size: 3rem;
      margin-bottom: 2.5rem;
    }
  }
}

.description {
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 2.5rem;
  position: relative;

  strong {
    font-size: inherit;
  }

  &.hideApostrophe {
    &::before {
      background-image: none;
    }
  }

  &:before {
    background-image: url('~@Styles/images/empik-apostrophe.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 2.375rem;
    margin-bottom: 1rem;
    width: 1.25rem;
  }

  p {
    margin-bottom: 1.375rem;
    word-break: break-word;

    &.firstParagraph {
      font-weight: bold;
    }
  }

  figure {
    margin: 0;

    figcaption {
      font-size: 0.8125rem;
    }
  }

  .image {
    margin: 1.5rem 0;
    max-width: 100%;
  }

  .player {
    height: 0;
    margin: 1.5rem 0;
    padding-bottom: 56.25%;
    padding-top: 1.5625rem;
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .title {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  .defaultTitle {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
