@import '@Styles/defs/animations.scss';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.mobile) {
  .dateFilterWrapper {
    .dateDropdown {
      border-radius: 0;
      bottom: 0;
      justify-content: flex-start;
      left: 0;
      max-width: 100vw;
      overflow-y: scroll;
      padding: 5rem 1rem 2rem;
      position: fixed;
      right: 0;
      top: 4rem;

      .shortcuts {
        padding: 0 0.5rem;
      }

      .monthSelect {
        .chevron {
          margin: 0 1rem;
        }
      }
    }
  }
}

.dateFilterWrapper {
  position: relative;

  .dateDropdown {
    animation: fadeIn ease-in-out 250ms;
    background: white;
    border-radius: $search_calendarBorderRadius;
    box-shadow: 0 0 1rem rgba($default_shadowColor, 0.25);
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    left: 0;
    padding: 2rem;
    position: absolute;
    top: 3rem;
    z-index: 1;

    i.close {
      align-items: center;
      background-color: $dateFilter_calendarClose;
      border-radius: 50%;
      display: flex;
      font-family: sans-serif;
      height: 2rem;
      justify-content: center;
      line-height: 1;
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 2rem;
    }

    .shortcuts {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      justify-content: flex-start;
      margin-bottom: 2rem;

      .shortcut {
        background-color: $dateFilter_calendarSelectedBg;
        color: $dateFilter_calendarSelectedText;
        cursor: pointer;
        padding: 0.25rem 0.5rem;

        &:active {
          transform: translateY(0.15rem);
        }
      }
    }

    .monthSelect {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .month {
        font-weight: $default_fontBoldWeight;
        margin: 0 1rem;
        user-select: none;
      }

      .chevron {
        border-style: solid;
        border-width: 0.35rem 0.35rem 0 0;
        box-sizing: content-box;
        cursor: pointer;
        display: block;
        height: 0.35rem;
        top: 0.5rem;
        transform: rotate(45deg);
        transition: $default_transition;
        width: 0.35rem;

        &:hover {
          transform: rotate(45deg) scale(1.25);
        }

        &:active {
          transform: rotate(45deg) scale(1);
        }

        &.left {
          transform: rotate(-135deg);

          &:hover {
            transform: rotate(-135deg) scale(1.25);
          }

          &:active {
            transform: rotate(-135deg) scale(1);
          }
        }
      }
    }

    .calendarContainer {
      margin: 1rem auto 0;
      width: fit-content;

      .daysBar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);

        div {
          align-items: center;
          display: flex;
          height: 2.5rem;
          justify-content: center;
          width: 2.5rem;
        }
      }

      .calendar {
        display: grid;
        grid-template-rows: repeat(5, 1fr);

        .calendarRow {
          display: grid;
          grid-template-columns: repeat(7, 1fr);

          .day {
            align-items: center;
            cursor: pointer;
            display: flex;
            height: 2.5rem;
            justify-content: center;
            width: 2.5rem;

            &.disabled {
              color: $dateFilter_calendarDayDisabled;
              pointer-events: none;
            }

            &.today {
              color: $dateFilter_calendarDayCurrent;
              font-weight: $default_fontBoldWeight;
            }

            &.selected {
              background-color: $dateFilter_calendarSelectedBg;
              color: $dateFilter_calendarSelectedText;
            }

            &:hover {
              background-color: $dateFilter_calendarSelectedBg;
              border-radius: 50%;
              color: $dateFilter_calendarSelectedText;
              font-weight: $default_fontBoldWeight;

              &.selected {
                border-radius: 0;
              }
            }
          }
        }
      }
    }

    .filterButton {
      @include primaryButton;
      font-size: 0.85rem;
      font-weight: $default_fontBoldWeight;
      margin-top: 2rem;
      width: 100%;
    }

    .addDates {
      font-size: 0.75rem;
      margin-top: 0.5rem;
      text-align: center;
    }

    .removeFilters {
      background: none;
      border: none;
      cursor: pointer;
      margin: 1rem auto 0;
      text-align: center;
    }
  }
}
