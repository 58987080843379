@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  &:global(.desktop) {
    .eventTile {
      max-width: 23.29125rem;
    }
  }

  .eventTile {
    .imageWrapper {
      border-radius: $default_borderRadiusTop;
    }

    .eventDetails {
      .value {
        font-weight: 400;

        a {
          font-weight: 400;
        }
      }
    }
  }
}

:global(.empik) {
  .eventTile {
    &:first-child {
      border-radius: 1rem 0 0 0;

      .imageWrapper {
        border-radius: 1rem 0 0 0;
      }
    }

    &:last-child {
      border-radius: 0 0 1rem;
    }
  }
}

:global(.desktop) {
  .eventTile {
    @include cardDesktop;
    max-width: 23.25rem;
    min-width: 14rem;

    .eventFooter {
      & > div {
        justify-content: center;
        margin: 0;

        & > a {
          width: 100%;
        }
      }
    }
  }
}

.eventTile {
  @include cardMobile;
  background: $events_eventBackground;
  border-radius: $default_borderRadiusMain;
  box-shadow: $events_eventShadow;
  margin-bottom: 2.5rem;
  padding: 0;
  width: 100%;

  .imageWrapper {
    @include cardHeader;
    border-radius: 0;
    width: 100%;
  }

  .eventDetails {
    @include cardBody;
    padding-bottom: 0.5rem;

    .value {
      color: $events_eventValueFont;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.3;

      a {
        color: $events_linkColor;
      }

      & > h3 {
        margin-bottom: 0;
      }
    }
  }

  .eventFooter {
    @include cardFooter;
  }
}

.premiumEvent {
  box-shadow: 0 0 0 2px $events_premiumBorder;
}
