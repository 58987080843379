@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .twoColumns {
    flex-direction: row-reverse;

    .form {
      margin: 0;
      padding-right: 2rem;
      width: $leftColumnWidth;
    }

    .fixedBox {
      padding-bottom: 10rem;
      width: $rightColumnWidth;
    }
  }
}

.twoColumns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5rem;
  width: 100%;

  .form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
  }

  .fixedBox {
    padding-bottom: 4rem;
    width: 100%;
  }
}
