@font-face {
  font-family: 'Campton';
  font-weight: 400;
  src: url('fonts/hangary/Campton/30C500_1_0.eot');
  src: url('fonts/hangary/Campton/30C500_1_0.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/hangary/Campton/30C500_1_0.woff2') format('woff2'),
    url('fonts/hangary/Campton/30C500_1_0.woff') format('woff'),
    url('fonts/hangary/Campton/30C500_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Campton';
  font-weight: 700;
  src: url('fonts/hangary/Campton/30C500_0_0.eot');
  src: url('fonts/hangary/Campton/30C500_0_0.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/hangary/Campton/30C500_0_0.woff2') format('woff2'),
    url('fonts/hangary/Campton/30C500_0_0.woff') format('woff'),
    url('fonts/hangary/Campton/30C500_0_0.ttf') format('truetype');
}
