@import './../../Place.module.scss';

:global(.desktop) {
  .tagBar .tags {
    width: $leftColumnWidth;
  }

  .topLine {
    border-top: 1px solid $place_tagsBorder;
    padding: 1.5rem 0;
  }
}

.tags {
  padding: 1rem 0;
}

.tagBar {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
  margin-bottom: 2rem;
}
