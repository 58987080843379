@font-face {
  font-family: 'Point';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/saltos/point/Point-Light.ttf') format('truetype');
  src: local('Point Light'), local('Point-Light');
}

@font-face {
  font-family: 'Point';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/saltos/point/Point-LightItalic.ttf') format('truetype');
  src: local('Point Light Italic'), local('Point-LightItalic');
}

@font-face {
  font-family: 'Point';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/saltos/point/Point-Regular.ttf') format('truetype');
  src: local('Point Regular'), local('Point-Regular');
}

@font-face {
  font-family: 'Point';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/saltos/point/Point-MediumItalic.ttf') format('truetype');
  src: local('Point Medium Italic'), local('Point-MediumItalic');
}

@font-face {
  font-family: 'Point';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/saltos/point/Point-SemiBold.ttf') format('truetype');
  src: local('Point Semi Bold'), local('Point-SemiBold');
}

@font-face {
  font-family: 'Point';
  font-style: italic;
  font-weight: 600;
  src: url('fonts/saltos/point/Point-SemiBoldItalic.ttf') format('truetype');
  src: local('Point Semi Bold Italic'), local('Point-SemiBoldItalic');
}

@font-face {
  font-family: 'Point';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/saltos/point/Point-Bold.ttf') format('truetype');
  src: local('Point Bold'), local('Point-Bold');
}

@font-face {
  font-family: 'Point';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/saltos/point/Point-BoldItalic.ttf') format('truetype');
  src: local('Point Bold Italic'), local('Point-BoldItalic');
}

@font-face {
  font-family: 'Point';
  font-style: italic;
  font-weight: 800;
  src: url('fonts/saltos/point/Point-ExtraBoldItalic.ttf') format('truetype');
  src: local('Point Extra Bold Italic'), local('Point-ExtraBoldItalic');
}
