@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  .wrapper {
    .bigArticle {
      a {
        border-radius: 0.5rem;
        margin: 0;
      }
    }

    .articlesList {
      .smallArticle {
        border: none;
        border-radius: 0.5rem;

        .image {
          border-radius: 0.5rem 0 0 0.5rem;
        }
      }
    }
  }
}

:global(.desktop) {
  .wrapper {
    .articlesList {
      .smallArticle {
        .content {
          .title {
            @include lineLimit(3, inherit);
            font-size: 1.25rem;
          }
        }

        &:hover {
          .arrow {
            display: block;
          }
        }
      }
    }
  }
}

:global(.desktop),
:global(.tablet) {
  .wrapper {
    flex-flow: row nowrap;
    justify-content: space-between;

    .bigArticle {
      flex: 0 1 calc(50% - 0.5rem);
      margin: 0;

      .content {
        padding: 2rem;

        .title {
          @include lineLimit(5, 1.2);
          font-size: 2vw;
        }
      }
    }

    .articlesList {
      flex: 0 1 calc(50% - 0.5rem);
      justify-content: space-between;

      .smallArticle {
        .image {
          flex: 0 1 30%;
        }

        .content {
          flex: 0 1 70%;
        }
      }
    }
  }
}

:global(.tablet) {
  .wrapper {
    .articlesList {
      .smallArticle {
        margin-bottom: 0;

        .content {
          .title {
            @include lineLimit(2, inherit);
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  margin: 1rem auto;
  width: 100%;

  .bigArticle {
    display: flex;
    flex: 1 0 0%;
    height: fit-content;
    margin-bottom: 2rem;
    position: relative;

    a {
      margin: 0;
    }

    .content {
      bottom: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      padding: 1.5rem;
      position: absolute;
      width: 100%;

      .title {
        @include lineLimit(8, inherit);
        color: $bigAndVerticalHotspots_mainTitleColor;
        font-size: 6vw;
        font-weight: bold;
        margin: 0;
        max-height: 100%;
      }
    }
  }

  .articlesList {
    box-sizing: border-box;
    display: flex;
    flex: 1 0 0%;
    flex-flow: nowrap column;

    .smallArticle {
      border-right: 0.25rem $bigAndVerticalHotspots_smallArticleBorder solid;
      display: flex;
      flex-flow: nowrap row;
      margin-bottom: 1rem;
      transition: border-color $default_longAnimationTime,
        background-color $default_longAnimationTime;

      &:hover {
        background-color: $bigAndVerticalHotspots_smallArticleHoverBackground;
        border-color: $bigAndVerticalHotspots_smallArticleHoverBorder;

        .content {
          .title {
            color: $bigAndVerticalHotspots_smallArticleTitleColor;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .image {
        display: flex;
        flex: 0 0 50%;
      }

      .content {
        box-sizing: border-box;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        padding: 1rem;

        .title {
          @include lineLimit(5, inherit);
          font-size: 1rem;
          font-weight: $default_fontBoldWeight;
          margin: 0;
        }
      }

      .arrow {
        @include arrow;
        display: none;
        left: -3rem;
        position: relative;
        top: calc(50% - 1.5rem);
        transition: $default_longAnimationTime;
        width: 0;
      }
    }
  }
}

:global(.dark) {
  .wrapper {
    .articlesList {
      .smallArticle {
        .arrow {
          border-color: $card_darkTextColorPrimary;
        }

        &:hover {
          .content {
            .title {
              color: $bigAndVerticalHotspots_smallArticleTitleColor;
            }
          }

          .arrow {
            border-color: $bigAndVerticalHotspots_smallArticleTitleColor;
          }
        }
      }
    }
  }
}
