@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .top {
      .title {
        font-size: 3rem;
      }
    }
  }

  .top {
    .background {
      display: none;
    }
  }
}

:global(.desktop) {
  .top {
    margin: 4rem 0;

    .background {
      background-size: contain;
      left: -10.19rem;
    }

    .title {
      font-size: 4.5rem;
    }
  }
}

.top {
  margin: 1.5rem 0;
  position: relative;
  width: 100%;

  .background {
    background-image: url('~@Styles/images/main-page-top-background.svg');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 9.938rem auto;
    height: 16rem;
    left: -3.125rem;
    position: absolute;
    top: -1.75rem;
    width: 14.25rem;
    z-index: -1;
  }

  .title {
    font-size: 2.75rem;
  }
}
