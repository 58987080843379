@import './styles/default';
@import './styles/hangar.scss';

@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

$backgroundPaper: #282828;

:global(.dark) {
  :global {
    .btn-primary {
      &:hover {
        background-color: $formio_buttonBackgroundHover;
        border-color: $formio_buttonBackgroundHover;
        color: $formio_buttonFont;
      }

      background-color: $formio_buttonBackgroundColor;
      border-color: $formio_buttonBackgroundColor;
      color: $formio_buttonFont;
    }

    .langButton {
      color: $default_white;
    }

    .formio-form {
      a {
        color: $formio_linkColor;
      }

      .form-control {
        background-color: $backgroundPaper;
        color: $default_white;

        &:focus {
          border-color: $default_main;
          -webkit-box-shadow: 0 0 0 0.2rem $default_main;
          box-shadow: 0 0 0 0.2rem $default_main;
        }
      }

      .list-group-item {
        background-color: $default_black;
      }

      .choices {
        .choices__list--dropdown {
          background-color: $default_black;
        }

        &[data-type*='select-one'] {
          &:after {
            border-color: $default_white transparent transparent;
          }

          .choices__button {
            filter: invert(1);
          }

          .choices__input {
            background-color: $default_black;
            color: $default_white;
          }

          .choices__list--dropdown {
            .choices__item--selectable {
              &.is-highlighted {
                background-color: $backgroundPaper;
              }
            }
          }
        }
      }
    }
  }
}

:global(.backButtonDisabled) {
  :global {
    .btn {
      display: none;
    }
  }
}
