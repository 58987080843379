@import '@Styles/defs/props.scss';

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 5rem auto;

  .path {
    height: 10rem;
    width: 10rem;
  }

  .loadingText {
    font-weight: $default_fontBoldWeight;
    margin-top: 5rem;
    padding-left: 3rem;
  }
}
