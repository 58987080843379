@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/functions.scss';

.loginModal {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;

  .loginBox {
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 0 1rem rgba($default_shadowColor, 0.25);
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    max-width: 33rem;
    padding: 2rem;
    position: fixed;

    .closeLogin {
      align-items: center;
      background-color: yellow;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 2rem;
      justify-content: center;
      line-height: 1;
      position: absolute;
      right: 1rem;
      top: 1rem;
      transition: $default_transition;
      user-select: none;
      width: 2rem;

      &:active {
        transform: translateY(0.15rem);
      }
    }

    .loginForm {
      .loginHeader {
        font-weight: $default_fontBoldWeight;
        margin-bottom: 2rem;
        padding: 0 2rem;
        text-align: center;
      }

      .emailInfo {
        display: block;
        margin: 0 auto 2rem;
        width: fit-content;
      }

      .loginFields {
        border: none;
        margin: 0;
        padding: 0;

        input {
          appearance: none;
        }
      }

      .submitLogin {
        @include primaryButton;
        font-size: 1.25rem;
        font-weight: $default_fontBoldWeight;
        margin: 1rem 0;
        min-height: 3.875rem;
        transition: $default_transition;
        width: 100%;
      }

      a {
        color: black;
      }
    }
  }
}
