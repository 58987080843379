@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.loading {
  .fakeImage {
    @include fakeDivCommons();

    border-radius: $default_borderRadiusMain;
    display: block;
    width: 100%;
  }

  .fakeContent {
    padding: 1.5rem 0;

    .fakeTitle {
      @include fakeDivCommons();

      height: 1.75rem;
      margin-bottom: 1.5rem;
      width: 80%;
    }

    .infoBox {
      .fakeInfo {
        @include fakeDivCommons();

        height: 1.125rem;
        margin-bottom: 0.625rem;
        width: 50%;

        &:last-child {
          margin-bottom: 0;
          width: 60%;
        }
      }
    }
  }
}
