@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop),
:global(.tablet) {
  .statusBarWrapper {
    flex-flow: row nowrap;
    justify-content: space-between;

    .dates {
      font-size: 2rem;
      margin-bottom: 0;
    }

    .found {
      .label {
        .length {
          padding: 0.5rem 1rem;
        }
      }
    }
  }
}

.statusBarWrapper {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  line-height: 1;
  margin-top: 2rem;
  min-height: 2rem;
  user-select: none;
  width: 100%;

  .dates {
    flex: 2;
    font-size: 1rem;
    font-weight: $default_fontBolderWeight;
    margin-bottom: 1rem;
    text-align: center;
  }

  .found {
    flex: 1;
    text-align: right;

    .label {
      font-size: 0.875rem;
    }

    .length {
      background-color: $search_resultsCountBackground;
      color: $search_resultsCount;
      font-weight: $default_fontBoldWeight;
      margin: 0 0.5rem;
      padding: 0.25rem 0.75rem;
    }
  }
}
