@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global {
  .langButton {
    appearance: none;
    background: transparent;
    border: 1px $formio_buttonBorder solid;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 0.25rem 0.5rem;
  }

  #formio {
    .alert-success,
    .help-block {
      display: none;
    }
    .choices__list {
      .choices__item {
        overflow: hidden;
        padding-right: 2rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.is-active {
        .choices__item {
          padding-right: 0.75rem;
          white-space: normal;
          word-break: normal;
        }
      }
    }

    .btn-primary,
    .btn-secondary {
      background-color: $formio_buttonBackgroundColor;
      border-color: $formio_buttonBackgroundColor;
      color: $formio_buttonFont;
      font-size: 1.25rem;
      font-weight: bold;
      padding: 0.5rem 1rem;
      text-transform: uppercase;

      &:hover {
        background-color: $formio_buttonBackgroundHover;
        border-color: $formio_buttonBackgroundHover;
        color: $formio_buttonFont;
      }

      &:not(:disabled):not(.disabled):active,
      :not(:disabled):not(.disabled).active,
      .show > .btn-primary.dropdown-toggle {
        background-color: $formio_buttonBackgroundActive;
        border-color: $formio_buttonBackgroundActive;
        color: $formio_buttonFont;
      }
    }

    .form-control {
      overflow: hidden;
      text-overflow: ellipsis;

      &.is-invalid {
        background-position: right 3rem center;
      }
    }

    .has-error {
      margin-top: 0.5rem;
    }

    .alert {
      & > ul {
        list-style: square;
        transform: translateX(1rem);

        & > li {
          font-weight: 300;
          line-height: 1.5;
        }
      }
    }
  }
}
