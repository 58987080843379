@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

@mixin checkbox {
  cursor: pointer;
  display: flex;
  position: relative;

  input[type='checkbox'] {
    left: #{$infinity}px;
    opacity: 0;
    position: relative;
  }

  input[type='checkbox']:checked + span::before {
    border: solid $default_black;
    border-width: 0 0.18rem 0.18rem 0;
    color: $default_black;
    content: '';
    font-size: 1.4rem;
    height: 0.8rem;
    left: -1.21rem;
    position: absolute;
    top: 0.1rem;
    transform: rotate(45deg);
    width: 0.3125rem;
  }

  .form-check-label::before {
    border: 0.18rem solid $default_black;
    box-sizing: border-box;
    content: '';
    display: inline-block;
    height: 1.25rem;
    margin-right: 0.6rem;
    min-width: 1.25rem;
    width: 1.25rem;
  }
}

:global(.hangar) {
  font-family: $default_hangarFontMedium;

  :global {
    #formio {
      font-family: $default_hangarFontMedium;

      .formio-form {
        .form-group {
          margin-bottom: 1.25rem;

          .form-check {
            @include checkbox;
            padding-left: 0;

            span {
              font-family: $default_hangarFontMedium;
              font-size: 0.93rem;
              position: relative;
              top: -5px;
            }
          }

          .col-form-label {
            font-family: $default_hangarFontMedium;
            font-size: 1.25rem;
            padding-bottom: 0.31rem;
            padding-top: 0.31rem;
          }
          input.form-control {
            border: 0.25rem solid $default_black;
            border-radius: 0;
            box-shadow: none;
            font-size: 1.25rem;
            padding: 0.68rem;
          }

          .btn-primary,
          .btn-secondary {
            background-image: $default_hangarBgGradient;
            background-size: inherit;
            border: 0.25rem solid $default_black;
            border-radius: 0;
            color: $default_black;
            font-family: $default_hangarFontMedium;
            max-width: 26rem;
            text-transform: none;
            width: 100%;

            &:hover {
              background-color: $formio_buttonBackgroundHover;
              color: $formio_buttonFont;
            }
          }
        }
      }
    }
  }
}
