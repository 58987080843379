@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

@mixin hangar {
  :global(.hangar) {
    font-family: $default_hangarFontMedium;

    .panel {
      &:before {
        display: none;
      }

      .buttonWrapper {
        .closeButton {
          background-image: $default_hangarBgGradient;
          background-size: inherit;
          border: 0.25rem solid $default_black;
          border-radius: 0;
          color: $default_black;
          text-transform: none;
        }
      }
    }
  }
}
