@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.seatsPanel {
  background: $pools_seatsBackground;
  box-shadow: $pools_seatsShadow;
  box-sizing: border-box;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  width: 100%;
}
