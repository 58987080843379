@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop),
:global(.tablet) {
  .searchWrapper {
    &.page {
      margin: 5rem auto;
    }

    .inputContainer {
      .input {
        appearance: none;
        border: none;
        border-radius: $search_inputBorderRadius;
        box-sizing: border-box;
        flex: 1 1 100%;
        font-size: 1.25rem;
        padding: 0 $search_inputDesktopPadding;
        z-index: 1;
      }

      .button {
        background: $search_buttonBackground;
        border: 0.0625rem solid $search_buttonBorder;
        border-radius: $search_buttonBorderRadius;
        color: $search_buttonFont;
        cursor: pointer;
        font-size: 1.75rem;
        font-weight: $default_fontBolderWeight;
        position: static;
        width: $search_buttonWidth;

        .buttonIcon {
          display: none;
        }
      }

      .input,
      .button {
        height: $search_nominalInputHeight;
      }
    }

    .highlightsList {
      padding: 2rem;
    }
  }
}

.searchWrapper {
  width: 100%;

  &.page {
    margin: 2rem auto;
  }

  .inputContainer {
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    position: relative;
    width: 100%;

    .apostrophe {
      background-image: url('~@Styles/images/empik-apostrophe-light.svg');
      background-size: cover;
      height: 9.5rem;
      left: -4rem;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 5.125rem;
      z-index: 0;
    }

    .input,
    .button {
      height: 3.5rem;

      &:focus {
        outline: none;
      }
    }

    ::placeholder {
      color: $search_inputFont;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: $search_inputFont;
    }
    ::-ms-input-placeholder {
      color: $search_inputFont;
    }

    .input {
      appearance: none;
      background-color: $search_inputBackground;
      border: none;
      border-radius: $search_inputBorderRadiusMobile;
      font-size: 0.85rem;
      font-weight: 500;
      overflow: hidden;
      padding: 0 1rem;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .highlightsList {
    background-color: $search_highlightListBackground;
    border-radius: 0.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-bottom: 1rem;
    overflow: hidden;
    padding: 1rem;
    position: relative;
    top: 0;
    transition: $default_transition;
    width: 100%;
  }

  .filtersList {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + 1rem);

    .filter {
      align-items: center;
      background-color: $search_filterBackground;
      border-radius: $search_filterBorderRadius;
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      height: 2rem;
      margin: 0 1rem 1rem 0;
      transition: $default_transition;
      user-select: none;

      &:hover {
        box-shadow: 0 0.15rem 1rem rgba($default_shadowColor, 0.28);
        transform: translateY(-0.15rem);
      }

      &:active {
        box-shadow: none;
        transform: translateY(0);
      }

      span {
        padding: 0.25rem 0.25rem 0.25rem 1rem;

        &:last-child {
          padding: 0.25rem 1rem;
        }
      }

      i {
        align-items: center;
        background-color: $search_resetIconBackground;
        border-radius: 50%;
        color: $search_resetIcon;
        display: flex;
        font-family: sans-serif;
        font-size: 0.75rem;
        height: 1.25rem;
        justify-content: center;
        line-height: 1;
        margin: 0 1rem 0 0.5rem;
        width: 1.25rem;
      }
    }
  }

  .childrenContainer {
    margin-top: 2rem;
    width: 100%;
  }
}

:global(.mobile) {
  .searchWrapper {
    .inputContainer {
      .button {
        display: none;
      }
    }
  }
}
