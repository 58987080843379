@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .loading {
    flex-direction: row;
    padding: 1.5rem 1.875rem;

    .column {
      &:nth-child(1) {
        text-align: left;
        width: 42%;
      }

      &:nth-child(2) {
        text-align: center;
        width: 15%;
      }

      &:nth-child(3) {
        text-align: right;
        width: 43%;
      }

      &.columnLeft {
        align-items: center;
        display: flex;
      }

      .ticketInfo {
        margin: 0 0.75rem 0 1.25rem;

        .fakeTitle {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
      }

      .fakePrice {
        margin-bottom: 0;
      }

      .fakeButtons {
        justify-content: flex-end;

        .fakeButton {
          flex-grow: unset;

          &:first-child {
            margin-left: unset;
          }
        }
      }
    }
  }
}

.loading {
  align-items: center;
  background: $pools_loadingBackground;
  box-shadow: $pools_loadingShadow;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  width: 100%;

  .column {
    text-align: left;
    width: 100%;

    &.columnLeft {
      align-items: flex-start;
      display: flex;
    }

    .fakeImage {
      @include fakeDivCommons();
      height: 1.25rem;
      width: 3rem;
    }

    .ticketInfo {
      display: flex;
      flex-direction: column;
      margin: 0 0 1em;
      width: calc(100% - 1.25rem);

      & > div {
        @include fakeDivCommons();
      }

      .fakeTitle {
        height: 1.5rem;
        margin-bottom: 0.375rem;
        width: 60%;
      }

      .fakeDescription {
        height: 0.9375rem;
        width: 3.25rem;
      }
    }

    .fakePrice {
      @include fakeDivCommons();

      height: 1.625rem;
      margin-bottom: 1.5rem;
      width: 4.5rem;
    }

    .fakeButtons {
      display: flex;
      justify-content: flex-end;

      .fakeButton {
        @include fakeDivCommons();

        flex-grow: 1;
        height: 2.625rem;
        margin-left: 0.4375rem;
        width: 2.625rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
