@import '@Styles/defs/colors.scss';

.wrapper {
  margin-top: 1rem;
}

.form {
  margin-top: 1rem;
}

.buyPremiumLink {
  color: $clientData_premiumLink;
  font-size: 0.875rem;
  font-weight: bold;
  padding-left: 0.125rem;
}
