@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .borderContainer {
      border: none;
      border-bottom: $default_solidBorder $ticketsBox_Border;
      border-radius: 0;
      margin: 0;

      &:last-child {
        border-bottom: none;
      }

      .singleTicketContainer {
        grid-template-columns: 1fr 4fr 6fr 1fr 1fr;
        padding: 1.5rem 0;

        .ticketIcon {
          font-size: 1.75rem;
        }

        .ticketKind {
          font-size: 1.75rem;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .singleTicketContainerPrice {
          .priceContainer {
            .priceTextContainer {
              .priceText {
                font-size: 1.5rem;
              }
              .price {
                font-size: 2rem;
                white-space: nowrap;
              }
              .currency {
                font-size: 1.5rem;
              }
            }
          }

          .ticketsInformationText {
            font-size: 1rem;
            font-weight: 500;
          }
        }

        .button {
          font-size: 1.125rem;
          font-weight: 500;
          margin-left: 0;
          padding: 1rem;
          width: auto;
        }

        .disabled {
          @include disabledClass();
        }
      }

      .descriptionContainer {
        color: $ticketsBox_defaultText;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.5;
        padding: 0 2rem 1rem;

        p {
          margin-bottom: 2rem;
        }

        h3 {
          display: none;
        }
      }
    }
  }

  .manyTickets {
    border: none;
    margin: 0;
  }

  .borderContainer {
    border: none;
    border-radius: $default_borderRadiusMain;
    margin-bottom: 2rem;

    .singleTicketContainer {
      border-radius: $default_borderRadiusTop;
    }

    .detailsTitle {
      border-radius: 0 0 1rem 1rem;
    }

    .descriptionContainer {
      border-radius: $default_borderRadiusMain;
      padding-top: 1rem;
    }
  }
}

:global(.desktop) {
  .singleTicketContainer {
    align-items: center;
    box-shadow: $default_shadow;
    display: grid;
    font-size: 1.75rem;
    grid-column-gap: 0.4rem;
    grid-template-columns: 1fr 3fr 5fr 3fr 2fr;
    padding: 1rem 1.6rem;
  }

  .ticketsInformationText {
    font-size: 1rem;
    order: 1;
  }

  .price {
    font-size: 2rem;
  }

  .ticketKind {
    font-size: 1.375rem;
  }

  .priceText {
    margin-right: 0.3rem;
  }

  .priceTextContainer {
    font-size: 1.2rem;
  }

  .ticketIcon {
    color: $ticketsBox_iconColor;
    display: inherit;
    font-size: 1.25rem;
  }

  .priceContainer {
    order: 2;
    width: 45%;
  }

  .button {
    margin-left: 0.5rem;
    padding: 0.5rem 0.875rem;
    width: 8.125rem;
  }

  .detailsTitle {
    display: none;
  }

  .mobileDisplayNone {
    display: inherit;
    margin-left: 1rem;
  }

  .manyTickets {
    border: none;
    margin: 0;

    .singleTicketContainer {
      display: grid;
      padding-left: 6.25rem;

      .buttonWrapper {
        display: block;
      }

      .ticketKind {
        font-size: 1.125rem;
      }

      .priceTextContainer {
        font-size: 1rem;
      }

      .price {
        font-size: 1.5rem;
      }

      .button {
        opacity: 0;
      }
    }
  }
}

.button {
  @include primaryButton();
  font-weight: 600;
  width: 100%;
}

.borderContainer {
  border: solid 0.063rem $ticketsBox_Border;
  margin: 1rem 0;
}

.manyTickets {
  border: none;
  margin: 0;

  .singleTicketContainer {
    align-items: center;
    box-shadow: unset;
    display: flex;
    justify-content: space-between;
    padding: 0;
    transition: $default_animationTime;

    &:hover {
      background-color: $default_whiteLilac;
      .button {
        opacity: 1;
      }
    }

    .ticketKind {
      font-size: 0.75rem;
      font-weight: bold;
    }

    .priceTextContainer {
      font-size: 0.75rem;
    }

    .currency {
      font-weight: bold;
    }

    .price {
      font-size: 0.75rem;
      font-weight: bold;
    }

    .buttonWrapper {
      display: none;
    }

    .button {
      @include borderButton();
      padding: 0.5rem 0.875rem;
      transition: $default_animationTime;
    }
  }

  .ticketIcon {
    display: block;
  }
}

.borderContainer:last-of-type {
  margin-bottom: 0;
}

.detailsTitle {
  align-items: center;
  background-color: $ticketsBox_ButtonText;
  border-top: solid 0.063rem $ticketsBox_Border;
  color: $ticketsBox_defaultText;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  justify-content: center;
  padding: 0.875rem;
  padding-top: 0.5rem;
}

.descriptionContainer {
  background-color: $ticketsBox_ButtonText;
  font-size: 1rem;
  font-weight: 100;
  padding: 0 2.5rem 1rem 2.5rem;
}

.spaceUpRectangleSquareContainer {
  background-color: $ticketsBox_BackgroundMain;
  border-radius: 0 0 1rem 0;
  height: 2rem;
  top: 0;
  width: 100%;
}

.singleTicketContainer {
  background-color: $ticketsBox_ButtonText;
  font-size: 1rem;
  padding: 1.25rem;

  .ticketKind {
    font-size: 1.125rem;
  }
}

.singleTicketContainerPrice {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  justify-content: flex-end;
  margin: 0.875rem 0 1.25rem;

  .priceText {
    color: $ticketsBox_defaultText;
    font-weight: normal;
  }

  .ticketsInformationText {
    color: $ticketsBox_ButtonBackground;
  }
}

.ticketIcon {
  display: none;
}

.mobileDisplayNone {
  display: none;
}

.imageContainer {
  padding-top: 1rem;
  width: 100%;
}

.tooltip {
  margin-left: 0.25rem;
  position: relative;
  top: 0.25rem;
}
