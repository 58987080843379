@mixin default {
  :global(.desktop),
  :global(.bigDesktop) {
    .datePicker {
      flex: none;
      margin-bottom: 0;
      // margin-right: 1.56rem;
      width: 25.375rem;

      & > div {
        font-size: 1.25rem;
      }
    }
  }

  .datePicker {
    margin-bottom: 1.56rem;
    margin-right: 0;

    &.disabled {
      opacity: 0.18;
      pointer-events: none;
    }
  }
}
