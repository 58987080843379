@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';

.fakeLinkContainer {
  align-items: flex-end;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  padding-top: 1.5625rem;

  .fakeLink {
    @include fakeDivCommons();
    border: none;
    height: 1.125rem;
    min-width: 10.75rem;
    width: 30%;
  }
}
