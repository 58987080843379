@import '@Styles/defs/mixins.scss';

.tagsLoading {
  display: flex;

  .fakeTag {
    @include fakeDivCommons;

    height: 1.5rem;
    margin-right: 1rem;
    width: 3.125rem;
  }
}
