@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/functions.scss';

:global(.going),
:global(.sbm) {
  .pool {
    .column {
      justify-content: center;
      text-align: center;

      .ticketIcon {
        background: $eventBox_ticketIcon no-repeat 0 0.15rem;
        background-size: contain;
        border: none;
        height: auto;
        margin-right: 0.25rem;
        padding: 1rem 1.5rem;
        width: auto;
      }

      .ticketInfo {
        align-self: center;
        display: flex;
        flex-direction: column;
        margin: 0 0 1em;

        .title {
          h4 {
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.25rem;
            text-align: center;
          }
        }
      }

      .price {
        font-size: 1.375rem;
        font-weight: 500;
        .priceValue {
          font-size: 2rem;
        }
      }
    }
  }
}

:global(.empik) {
  .pool {
    .column {
      justify-content: center;
      text-align: center;
    }
  }
}

:global(.mobile),
:global(.tablet) {
  .pool {
    .column {
      .ticketInfo {
        align-items: center;

        .title {
          h4 {
            text-align: center;
          }
        }
      }
    }
  }
}

:global(.desktop) {
  .pool {
    display: grid;
    grid-template-columns: 4fr 3fr 4fr;
    grid-template-rows: 100%;
    padding: 1.5rem 1.875rem;

    .column {
      justify-content: flex-start;
      text-align: right;

      .ticketInfo {
        margin: 0 0.75rem 0 1.25rem;

        .title {
          font-size: 1.5rem;
          line-height: 1.5rem;

          h4 {
            text-align: left;
          }
        }
      }

      .price {
        display: inline;
        font-size: 1.375rem;
        margin-bottom: 0;

        .priceValue {
          font-size: 2rem;
        }
      }
    }
  }
}

.pool {
  align-items: center;
  background: $pools_poolBackground;
  border-radius: borderRadius(0, 0, 0, 0);
  box-shadow: $pools_poolShadow;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  width: 100%;

  &.disabled {
    @include disabledStyles;
    opacity: 0.2;
  }

  &.highlighted {
    border: 0.125rem solid $pools_poolHighlightedBorder;
  }

  .column {
    text-align: left;
    width: 100%;

    &.columnLeft {
      align-items: flex-start;
      display: flex;
      text-align: left;
    }

    .image {
      align-self: flex-start;
      color: $pools_poolImageFont;
      font-size: 1.25rem;
      margin-top: 0.125rem;
    }

    .ticketInfo {
      display: flex;
      flex-direction: column;
      margin: 0 0 1em;

      .title {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }

      .titleWithMarginBottom {
        margin-bottom: 0.375rem;
      }
    }

    .price {
      display: inline-block;
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
  }
}
