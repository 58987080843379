@import '@Styles/defs/colors.scss';
@import '@Styles/defs/animations.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .normal {
    flex-direction: row;
    height: 21.875rem;

    .fakeImage {
      height: 100%;
      width: 50%;
    }

    .fakeDetails {
      padding: 1.875rem;
      width: calc(50% - 3.75rem);
    }
  }
}

.loading {
  background: $events_loadingBackground;
  box-shadow: $events_loadingShadow;
  margin-bottom: 2.5rem;
  padding: 0;
  width: 100%;

  &:first-child,
  &:first-child > div:first-child {
    border-radius: 1rem 0 0 0;
  }

  .fakeImage {
    align-items: center;
    animation: backgroundBlinking 0.8s infinite;
    animation-timing-function: ease-in-out;
    background-color: $events_loadingFakeBackground;
    display: flex;
    height: calc((100vw) * 0.8);
    justify-content: center;
    width: 100%;
  }

  .fakeDetails {
    padding: 1.25rem;
    width: calc(100% - 2.5rem);
  }
}

.normal {
  display: flex;
  flex-direction: column;
}

.forcedVertical {
  display: block;

  .fakeImage {
    height: calc((100vw - 18rem) * 0.85);
  }
}
