@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin datepicker {
  :global {
    .react-datepicker {
      background-color: $default_white;
      border: none;
      color: $default_black;
      font-size: 0.93rem;
      width: 100%;

      .react-datepicker__navigation {
        border: 0.62rem solid transparent;
        top: 1.25rem;
      }

      .react-datepicker__navigation--previous {
        border-right-color: $default_black;
      }

      .react-datepicker__navigation--next {
        border-left-color: $default_black;
      }

      .react-datepicker__month-container {
        width: 100%;
      }

      .react-datepicker__header {
        background-color: $default_white;
        border-bottom: none;
        border-left: 0.25rem solid $default_black;
        border-radius: 0;
        border-right: 0.25rem solid $default_black;
        border-top: 0.25rem solid $default_black;
        padding-top: 0.93rem;
      }

      .react-datepicker__month {
        border-collapse: collapse;
        display: flex;
        flex-direction: column;
        margin: 0;
        width: 100%;
      }

      .react-datepicker__current-month {
        color: $default_black;
        font-size: 1.25rem;
        padding-bottom: 0.93rem;
      }

      .react-datepicker__week {
        display: flex;

        &:last-child {
          border-bottom: 0.25rem solid $default_black;
        }

        &:first-child {
          border-top: none;

          .react-datepicker__day {
            border-top: none;
          }
        }

        .react-datepicker__day--disabled {
          color: rgba(14, 6, 107, 0.3);
          flex: 1;
          font-weight: 400;

          &:hover {
            background-color: transparent;
            color: rgba(14, 6, 107, 0.3);
          }

          &.react-datepicker__day--selected {
            background-color: rgba(14, 6, 107, 0.3);
            color: $default_white;
            outline: none;
          }
        }
      }

      .react-datepicker__day-names {
        display: flex;
        justify-content: space-around;
        text-transform: uppercase;

        .react-datepicker__day-name {
          border-bottom: 0.25rem solid $default_black;
          border-radius: 0;
          border-right: 0.25rem solid $default_black;
          border-top: 0.25rem solid $default_black;
          color: $default_black;

          flex: 1;
          font-size: 0.93rem;
          font-weight: bold;
          margin: 0;
          padding: 1rem 0;

          &:last-child {
            border-right: none;
          }
        }
      }

      .react-datepicker__day {
        border: 0.25rem solid $default_black;
        border-bottom: none;
        border-right: none;
        color: $default_black;
        display: table-cell;
        flex: 1;
        font-weight: 600;
        margin: 0;
        outline: none;
        padding: 1rem 0;

        &:hover {
          background-color: $default_black;
          background-image: $default_hangarBgGradient;
          background-position: center;
          background-size: auto;
          border-radius: 0;
          color: $default_black;
        }

        &:last-child {
          border-right: 0.25rem solid $default_black;
        }
      }

      .react-datepicker__day--selected {
        background-color: $default_black;
        background-image: $default_hangarBgGradient;
        background-position: center;
        background-size: auto;
        border-radius: inherit;
        color: $default_black;
        outline: none;
      }

      .react-datepicker__day--outside-month {
        color: $default_grey;
        flex: 1;
        pointer-events: none;
      }
    }
  }
}
