@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.wrapper {
  @include center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  padding: 4rem 1rem;
  position: relative;

  &.inRow {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
