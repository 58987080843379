@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';

.modalBackground {
  background-color: $deepLinkModal_overlay;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;

  .deepLinkBar {
    background: $deepLinkModal_background;
    border-radius: 1rem;
    bottom: 0.75rem;
    display: flex;
    flex-direction: column;
    left: 0.5rem;
    padding: 1rem 0;
    position: fixed;
    right: 0.5rem;
    z-index: 99;

    .title {
      border-bottom: 0.0125rem solid $default_lightGrey;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 2rem;
      margin-bottom: 1.5rem;
      padding-bottom: 1rem;
      text-align: center;
    }
    .optionsWrapper {
      padding: 0 1.5rem;

      .option {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        .icon {
          flex-basis: 20%;
          height: 2.15625rem;
          width: 2.15625rem;
        }

        .open {
          @include primaryButton();
          font-size: 0.5625rem;
          font-weight: 600;
          padding: 1rem 0;
          width: 10rem;
        }

        .description {
          font-size: 0.8125rem;
        }

        .open,
        .description {
          flex-basis: 0;
          flex-grow: 2;
        }
      }
    }
  }
}
