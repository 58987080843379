@font-face {
  font-family: 'Noir Pro';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/going/NoirPro/NoirPro-Light.eot');
  src: local('Noir Pro Light'), local('NoirPro-Light'),
  url('fonts/going/NoirPro/NoirPro-Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-Light.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-Light.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/going/NoirPro/NoirPro-LightItalic.eot');
  src: local('Noir Pro Light Italic'), local('NoirPro-LightItalic'),
  url('fonts/going/NoirPro/NoirPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-LightItalic.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/going/NoirPro/NoirPro-Regular.eot');
  src: local('Noir Pro Regular'), local('NoirPro-Regular'),
  url('fonts/going/NoirPro/NoirPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-Regular.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/going/NoirPro/NoirPro-Italic.eot');
  src: local('Noir Pro Italic'), local('NoirPro-Italic'),
  url('fonts/going/NoirPro/NoirPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-Italic.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/going/NoirPro/NoirPro-Medium.eot');
  src: local('Noir Pro Medium'), local('NoirPro-Medium'),
  url('fonts/going/NoirPro/NoirPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-Medium.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/going/NoirPro/NoirPro-MediumItalic.eot');
  src: local('Noir Pro Medium Italic'), local('NoirPro-MediumItalic'),
  url('fonts/going/NoirPro/NoirPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-MediumItalic.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/going/NoirPro/NoirPro-SemiBold.eot');
  src: local('Noir Pro Semi Bold'), local('NoirPro-SemiBold'),
  url('fonts/going/NoirPro/NoirPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-SemiBold.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: italic;
  font-weight: 600;
  src: url('fonts/going/NoirPro/NoirPro-SemiBoldItalic.eot');
  src: local('Noir Pro Semi Bold Italic'), local('NoirPro-SemiBoldItalic'),
  url('fonts/going/NoirPro/NoirPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-SemiBoldItalic.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/going/NoirPro/NoirPro-Bold.eot');
  src: local('Noir Pro Bold'), local('NoirPro-Bold'),
  url('fonts/going/NoirPro/NoirPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-Bold.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/going/NoirPro/NoirPro-BoldItalic.eot');
  src: local('Noir Pro BoldItalic'), local('NoirPro-BoldItalic'),
  url('fonts/going/NoirPro/NoirPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-BoldItalic.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: normal;
  font-weight: 800;
  src: url('fonts/going/NoirPro/NoirPro-Heavy.eot');
  src: local('Noir Pro Heavy'), local('NoirPro-Heavy'),
  url('fonts/going/NoirPro/NoirPro-Heavy.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-Heavy.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-Heavy.woff') format('woff');
}

@font-face {
  font-family: 'Noir Pro';
  font-style: italic;
  font-weight: 800;
  src: url('fonts/going/NoirPro/NoirPro-HeavyItalic.eot');
  src: local('Noir Pro HeavyItalic'), local('NoirPro-HeavyItalic'),
  url('fonts/going/NoirPro/NoirPro-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/going/NoirPro/NoirPro-HeavyItalic.woff2') format('woff2'),
  url('fonts/going/NoirPro/NoirPro-HeavyItalic.woff') format('woff');
}