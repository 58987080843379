@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

:global(.desktop) {
  height: 100%;

  .footer {
    img {
      margin-bottom: 1rem;
      max-height: 3rem;
    }
  }
}

:global(.mobile) {
  height: 100%;

  .landingWrapper {
    .headerWrapper {
      justify-content: space-evenly;
      padding: 1rem;

      img {
        max-width: 100%;
      }
    }
  }
}

.landingWrapper {
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;

  * {
    box-sizing: border-box;
  }

  &.fixed {
    background-attachment: fixed;
    overflow-y: auto;
  }

  a {
    transition: $landingPage_linkAnimation;
  }

  .headerWrapper {
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 2rem;
    text-align: center;
    width: 100%;

    img {
      margin: 0 auto;
      max-height: 75vh;
      max-width: 75vw;
    }

    .mainButton {
      border: $landingPage_buttonBorder;
      border-radius: $landingPage_borderRadius;
      font-size: 2rem;
      font-weight: $landingPage_boldFontWeight;
      line-height: 1;
      margin: 0 auto;
      padding: 1rem 2rem;
      text-transform: uppercase;
      width: fit-content;
    }
  }

  .contentWrapper {
    color: $landingPage_darkTextColor;
    margin: 0;
    padding: 4rem 0;

    &.dark {
      color: $landingPage_lightTextColor;
    }

    &.transparent {
      background: transparent;
    }

    .mainColumn {
      background-color: transparent;
      margin: 0 auto;
      max-width: $landingPage_contentWidth;
      padding: 0 1.5rem;

      .mainTitle {
        font-size: $landingPage_sectionTitleFontSize;
        font-weight: $landingPage_boldFontWeight;
        margin: $landingPage_sectionTitleMargin;
        user-select: none;
      }

      .description {
        font-size: 1rem;
        font-weight: $landingPage_normalFontWeight;
        line-height: 1.5;
        text-align: justify;
        user-select: none;
      }
    }
  }

  .footer {
    img {
      margin-bottom: 1rem;
      max-height: 2rem;
      transform: translateX(-1rem);
    }
  }
}
