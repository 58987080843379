@import '@Styles/defs/animations.scss';

.spinnerIcon {
  animation: rotation infinite 1s linear 0s normal;
  animation-timing-function: steps(12, start);
  display: block;
  left: calc(50% - 1rem);
  opacity: 0.2;
  position: absolute;
  top: calc(50% - 1rem);
  will-change: transform;

  &.relative {
    left: auto;
    margin: 0 auto;
    position: relative;
    top: auto;
  }
}
