@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/functions.scss';

:global(.going),
:global(.sbm) {
  .clientData {
    font-weight: 300;
  }
  .header {
    font-weight: 500;
    text-transform: lowercase;
  }

  .importData {
    font-size: 0.75rem;
    font-weight: 500;
  }
}

:global(.desktop) {
  .clientData {
    .formPanel {
      padding: 2.375rem 1.875rem;
    }
  }

  .autoCompleteContainer {
    .autoCompleteLabel {
      flex-direction: row;

      .labelHeader {
        .email {
          color: $default_osloGrey;
          font-weight: normal;
        }
      }

      .autoCompleteButton {
        font-size: 0.875rem;
        margin: 0;
      }
    }
  }

  .formPanel {
    .names {
      margin-bottom: 2rem;

      &.slotsNames {
        margin-bottom: 0;
      }

      .namesLabel {
        width: calc(50% - 0.625rem);

        &:nth-child(2) {
          margin-bottom: unset;
        }

        &:last-child {
          margin-left: 0.625rem;
          width: 50%;
        }
      }
    }
  }
}

.discountCheckBox {
  @include disabledClass();
}

.clientData {
  @include disabledClass();

  &.withoutMargin {
    margin-top: 0;
  }

  .formPanel {
    border: none;
    border-radius: borderRadius(0, 0, 0, 0);
    box-shadow: $clientData_formPanelShadow;
    box-sizing: border-box;
    padding: 1.75rem 1.25rem;
    width: 100%;

    &.hasDependency {
      height: auto;
      margin-bottom: 2rem;
      padding-bottom: 2.375rem;
      padding-top: 2.375rem;
    }

    &.noDependency {
      height: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: 0;
    }

    .names,
    .premiumCard {
      margin-top: 1rem;
    }
  }

  input {
    appearance: none;
  }

  margin-top: 3.75rem;
  width: 100%;
}

.autoCompleteContainer {
  margin-bottom: 2rem;
  transition: $default_animationTime;

  &.autoCompleteClicked {
    height: 0;
    padding: 0;
  }

  .autoCompleteLabel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .labelHeader {
      display: flex;
      flex-direction: column;
      font-size: 0.875rem;

      .email {
        color: $default_black;
        font-weight: bold;
        margin-top: 0.5rem;
      }
    }

    .autoCompleteButton {
      border: 0.125rem solid $buyForm_autocompleteButtonBorderColor;
      border-radius: 0.25rem;
      cursor: pointer;
      font-size: 0.875rem;
      padding: 1rem;
    }
  }
}

.header {
  font-size: 1.75rem;
  margin-bottom: 2rem;

  &:before {
    content: attr(data-number) '. ';
  }
}

.labelHeader {
  margin-bottom: 1rem;
}

.socialIcon {
  font-size: 1.25rem;
  margin-left: 0.5rem;
  vertical-align: middle;
}

.mailValidationAlertsContainer,
.mailValidationAlert {
  margin-top: 1rem;
}

.formPanel {
  border: none;
  padding-block-end: 0;
  padding-block-start: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;

  .label {
    font-size: 0.875rem;
    position: relative;
    width: 100%;
  }

  .names,
  .premiumCard {
    display: flex;
    flex-wrap: wrap;

    .description {
      width: 100%;
    }

    .namesLabel {
      position: relative;
      width: 100%;

      &:nth-child(2) {
        margin-bottom: 0.75rem;
      }
    }
  }

  .wrapper {
    padding-top: 1rem;

    label {
      font-size: 0.875rem;
    }
  }
}

.premiumCard {
  label {
    font-size: 0.875rem;
  }
}

.premiumDescription {
  color: $clientData_premiumFont;
  display: block;
  font-size: 0.75rem;

  a {
    color: $clientData_premiumLink;
  }
}
