@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .normal {
    flex-basis: 50%;

    .fakeImage {
      height: 100%;
      width: 100%;
    }
  }
}

.normal,
.forcedVertical {
  border-radius: inherit;
  flex: 1;
  height: 100%;

  .fakeImage {
    @include fakeDivCommons();
    border-radius: inherit;
    height: 100%;
    width: 100%;
  }
}
