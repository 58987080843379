@import '@Styles/defs/colors.scss';

.payuCardForm {
  width: calc(100% - 2rem);
}
.payUCardFormContainer {
  padding-left: 9px;
  padding-top: 1rem;

  > div {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.payUCardFormInnerContainer {
  align-items: center;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  padding: 1rem;
  /* from MUI Input Styles */
  width: 100%;
  /* from MUI Input Styles */
}

.payUCardFormInnerContainer:hover {
  border-color: $default_inputColor;
}
