@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop),
:global(.sbm) {
  .basketPools {
    padding: 0.75rem 0;
  }
}

.basketPools {
  border-bottom: 0.0625rem solid $basket_border;
  padding: 0.75rem 0;

  &:not(.insurance) {
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .line {
    color: $default_grey;
    display: flex;
    font-size: 0.75rem;
    justify-content: space-between;
    line-height: 1.25rem;

    &.firstLine {
      align-items: center;
      color: $default_black;
      font-size: 1rem;
      font-weight: $default_fontBoldWeight;
      padding-bottom: 0.5rem;
      position: relative;
    }

    &.lastLine {
      color: $default_black;
      font-size: 0.875rem;
      margin-top: 0.5rem;

      .finalPrice {
        font-weight: $default_fontBoldWeight;
      }
    }
  }

  .close {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 0.75rem;
    justify-content: center;
    position: relative;
    width: 0.75rem;
  }
}
