@import '@Styles/defs/colors.scss';

.wrapper {
  display: flex;
  flex: 0 1 15.875rem;
  height: 100%;

  .imageContainer {
    display: block;
    height: min-content;
    position: sticky;
    top: 2.5rem;

    .rectangle {
      object-fit: contain;
      width: 100%;
    }
  }
}
