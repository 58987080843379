@import '@Styles/defs/colors.scss';

.dropdown {
  position: relative !important;

  .content {
    background: $reusable_dropdownBackground;
    border-radius: 0.5rem;
    box-shadow: $reusable_dropdownShadow;
    box-sizing: border-box;
    left: 0;
    margin-bottom: 2rem;
    min-width: 200px;
    padding: 1rem;
    position: absolute;
    top: calc(100% + 0.5rem);
    visibility: hidden;
    width: min-content;
    z-index: 200;

    &.open {
      visibility: visible;
    }

    &.cityDropdown {
      left: auto;
      right: 0;
    }
  }
}
