@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

.date {
  color: $reusable_dateFont;
  font-size: 0.875rem;
  font-weight: $default_fontBolderWeight;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fakeDate {
  @include fakeDivCommons();
  height: 0.875rem;
  margin-bottom: 1.25rem;
  width: 40%;
}
