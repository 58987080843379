@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  .ticketListWrapper {
    margin-bottom: 7.5rem;

    .ticketContainer {
      border-radius: 1rem;

      .column {
        .display {
          padding: 0.25rem 1rem;
        }
      }
    }
  }
}

h2.pageTitle {
  font-size: 3rem;
  margin-bottom: 3rem;
}

.ticketListWrapper {
  list-style: none;

  .ticketContainer {
    background-color: $myTicket_backgroundColor;
    box-shadow: $default_shadow;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 1.5rem 2rem;

    &:first-child {
      margin-top: 0;
    }

    .column {
      display: flex;
      flex-flow: row nowrap;
      margin: auto 0;

      .ticketIcon {
        color: $myTicket_iconColor;
        font-size: 1.75rem;
        margin: auto 0;
      }

      .eventDetails {
        display: flex;
        flex-direction: column;
        font-weight: $default_fontBoldWeight;
        margin: auto 1rem;

        p {
          font-size: 0.875rem;
          line-height: 1.5rem;

          &.date {
            color: $myTicket_iconColor;
          }
        }
      }

      .display {
        align-items: center;
        border: $default_border $default_solidBorder;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 1rem;
        min-width: 3rem;
        padding: 0.25rem;

        .count {
          font-size: 1.125rem;
          font-weight: $default_fontBoldWeight;
        }

        .code {
          font-size: 0.6875rem;
        }
      }

      .button {
        @include primaryButton();
        @include disabled(0.5);

        font-size: 1.125rem;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
