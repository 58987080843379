@import '@Styles/defs/colors.scss';

:global(.going) {
  .list {
    font-size: 0.875rem;
    line-height: 1;

    .tag {
      border: 1px $reusable_tagBorder solid;
      border-radius: 0.25rem;
      font-weight: 400;
      margin-top: 0.5rem;
      padding: 0.25rem 0.5rem;
      text-transform: capitalize;

      &:before {
        display: none;
      }
    }

    .mainTag {
      border: 1px $reusable_mainTagBorder solid;
      font-weight: 500;
      padding: 0.25rem 0.5rem;
      text-transform: capitalize;
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8125rem;

  li {
    display: inline-block;
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .tag {
    align-items: center;
    color: $reusable_tagFont;
    display: block;
    margin-top: 0.75rem;

    &:before {
      content: '#';
    }
  }

  .mainTag {
    background: $reusable_mainTagBackground;
    border-radius: 0.25rem;
    color: $reusable_mainTagFont;
    display: block;
    margin-top: 0.5rem;
    padding: 0.25rem 0.5rem;
  }
}
