@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin default {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  .input {
    border: 0.0625rem solid $formField_inputBorder;
    box-sizing: border-box;
    font-size: 1rem;
    margin-top: 0.875rem;
    padding: 0.9375rem 1.25rem;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  :global(.desktop) {
    .error {
      bottom: -1.25rem;
      left: 0;
      right: unset;
      top: unset;
    }
  }

  .correctBorder {
    border-color: $formField_correctBorder;
  }

  .correctMessage {
    color: $formField_correctColor;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }

  .errorBorder {
    border-color: $formField_errorBorder;
  }

  .error {
    color: $formField_errorFont;
    font-size: 0.75rem;
    right: 1rem;
    top: 2rem;
  }
}
