@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  .linkContainer {
    border-radius: 0.5rem;
  }
}

.linkContainer {
  display: block;
  height: inherit;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .carouselSlide {
    border-radius: inherit;
    display: flex;
    flex: 0 0 100%;
    height: inherit;
    max-width: inherit;
    min-width: inherit;
    overflow: hidden;
    position: relative;

    .carouselImage {
      border-radius: inherit;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
    }
  }
}
