@import '@Styles/defs/props.scss';

.picker {
  box-sizing: border-box;
  width: 100%;
}

.info {
  font-size: 1.25rem;
  margin-top: 2.5rem;
}

.infoRow {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.value {
  font-family: $default_font;
}
