@import '@Styles/defs/animations.scss';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  .mainContainer {
    border-radius: $default_borderRadiusSmall;

    .arrowSlider {
      border-radius: $default_borderRadiusSmall;
    }
  }
}

.mainContainer:hover .sliderOverlay {
  opacity: 0;
  visibility: hidden;
}

.mainContainer {
  display: block;
  margin: 1rem 0;
  min-height: 2rem;
  position: relative;
  user-select: none;
  width: 100%;

  .arrowSlider {
    @include sliderContainer;
    z-index: 0;
  }

  .arrow {
    @include arrowButton;
    top: 5.69rem;

    &.verticalImage {
      top: 13.125rem;
    }

    &.left {
      left: -1.75rem;
    }

    &.right {
      right: -1.75rem;
    }
  }
}

:global(.mobile) {
  .mainContainer {
    .arrowSlider {
      @include sliderContainerMobile;
    }
  }
}
