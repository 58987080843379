:global() {
  body {
    background: transparent;
  }
}

.embedWrap {
  animation: showOn;
  animation-duration: 2s;
}

@keyframes showOn {
  from {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
