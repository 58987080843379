.addressBlock {
  margin-top: 2rem;
}

.wrap {
  > div {
    position: relative;
  }

  .info {
    font-size: 0.875rem;
    width: 100%;
  }
}

:global(.desktop) {
  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      margin-bottom: 0.5rem;
    }

    .street {
      width: 60%;
    }

    .number {
      width: 17%;
    }

    .name {
      width: 48.5%;
    }

    .code {
      width: 30%;
    }

    .city {
      width: 67%;
    }

    .phone {
      width: 100%;
    }
  }
}
