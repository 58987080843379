@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin hangar {
  :global(.hangar) {
    .wrapper {
      font-family: $default_hangarFontMedium;

      .timeSlots {
        .button {
          border: 0.25rem solid $default_black;
          font-family: $default_hangarFont;
          font-size: 1.25rem;
        }
      }
    }
  }
}
