@import '@Styles/defs/colors.scss';
@import '@Styles/defs/functions.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .wrapper {
    margin-left: 2rem;
  }
}

.wrapper {
  background: $pickUpParameters_checkboxPanelBackground;
  border-radius: 3px;
  padding: 0.875rem 1.5rem;

  .checkbox {
    font-size: 0.875rem;

    span {
      border: 1px solid $pickUpParameters_checkboxBorder;
      border-radius: 3px;
    }

    .price {
      border: none;
      color: $pickUpParameters_priceFont;
      display: contents;
      font-weight: bold;
      padding-left: 0.125rem;
    }

    .giftIcon {
      background: $pickUpParameters_giftIcon no-repeat right;
      border: none;
      height: 21px;
      padding-left: 1.5rem;
      vertical-align: middle;
      width: 20px;
    }
  }

  &.disabled {
    background: $pickUpParameters_disabledBackground;
    cursor: initial;
    opacity: 0.6;
    pointer-events: none;

    span {
      color: $pickUpParameters_disabledFont !important;
      filter: saturate(0);
    }
  }
}
