@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.artistSliderContainer {
  display: block;
  position: relative;
  width: 100%;

  .artistSlider {
    @include sliderContainer;
    z-index: 0;
    &.centered {
      justify-content: center;
    }
  }

  .arrow {
    cursor: pointer;

    &::before {
      border-style: solid;
      border-width: 0.25rem 0.25rem 0 0;
      box-sizing: content-box;
      content: '';
      display: block;
      height: 0.45rem;
      position: absolute;
      top: calc(50% - 1rem);
      vertical-align: top;
      width: 0.45em;
    }

    &.left {
      &::before {
        left: -2rem;
        transform: rotate(-135deg);
      }
    }

    &.right {
      &::before {
        right: -2rem;
        transform: rotate(45deg);
      }
    }
  }
}

:global(.mobile) {
  .artistSliderContainer {
    .artistSlider {
      @include sliderContainerMobile;
    }
  }
}
