@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .background {
    background-color: transparent;
    background-image: url('~@Styles/images/buy-page-background.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100% - 5rem);
    top: -2rem;

    &.hide {
      &:before {
        content: none;
      }
    }

    &:before {
      background-image: url('~@Styles/images/empik-apostrophe.svg');
      background-size: cover;
      content: '';
      height: 8.375rem;
      left: -2.1875rem;
      position: absolute;
      top: 14.375rem;
      width: 4.4375rem;
      z-index: -1;
    }
  }
}

.background {
  background-color: $buyForm_backgroundColor;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
