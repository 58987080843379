@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .container {
    .row {
      flex-direction: row;
    }

    .rowReverse {
      flex-direction: row;
    }

    p {
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.5;
    }

    h2 {
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;

  .row {
    align-items: center;
    display: flex;
    flex-direction: column;

    .leftColumn,
    .rightColumn {
      display: flex;
      flex-direction: column;
      padding: 3rem 0;
    }
  }

  .rowReverse {
    flex-direction: column-reverse;
  }

  .download {
    border-top: 0.0625rem solid $staticPage_downloadBorder;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -5rem;
    margin-right: -5rem;
    padding-bottom: 2.5rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 6.25rem;

    .downloadTitle {
      color: $staticPage_downloadText;
      font-size: 2.25rem;
    }

    .downloadButtons {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    a {
      margin-right: 1.8125rem;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .icon {
      width: 100%;
    }
  }

  .headerDesktop {
    color: $staticPage_contactLink;
  }

  img {
    max-width: 100%;
  }

  p {
    color: $aboutApp_textColor;
    font-size: 1.125rem;
    font-weight: $default_fontWeightSecond;
    line-height: 1.35;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: $default_fontBoldWeightSecond;
  }

  a {
    color: $aboutApp_textColor;
  }

  a {
    text-decoration: underline;
  }
}
