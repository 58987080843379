@import '@Styles/defs/colors.scss';

.wrapper {
  margin-top: 1rem;

  label {
    font-size: 0.875rem;
  }
}

.form {
  margin-bottom: 1rem;
}
