.dialog {
  display: flex;
  flex-flow: row nowrap;

  .imageContainer {
    flex: 1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .dialogBody {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 2;
    padding: 2rem;

    .content {
      .header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        h5 {
          align-items: inherit;
          display: inherit;
        }

        .icon {
          margin-right: 0.5rem;
        }
      }
    }

    .footer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  &.mobile {
    flex-flow: column;

    .dialogBody {
      gap: 2rem;

      .footer {
        flex-flow: column-reverse;
      }
    }
  }
}
