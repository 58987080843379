@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  .buttons {
    .button {
      padding: 0.75rem 0;
    }
  }
}

:global(.desktop) {
  .buttons {
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;

    &:first-child {
      margin-left: 0.4375rem;
    }

    .button {
      flex-grow: unset;
    }

    .amount {
      font-size: 1.125rem;
      padding: 0 0.75rem;
      width: 0.75rem;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-around;

  .button {
    align-items: center;
    background: $pools_buttonBackground;
    border: none;
    border-radius: 0.5rem;
    color: $pools_buttonFont;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    font-size: 2rem;
    font-weight: $default_fontBoldWeight;
    height: 3rem;
    justify-content: center;
    line-height: 1;
    margin: 0.25rem;
    padding: 0.625rem 0.9375rem;
    transition: background $default_animationTime, color $default_animationTime,
      border-color $default_animationTime;
    width: 3rem;

    &.disabled {
      cursor: no-drop;
      opacity: 0.25;
    }

    &:focus {
      outline: none;
    }
  }

  .amount {
    align-items: center;
    display: flex;
    font-size: 2rem;
    font-weight: $default_fontBoldWeight;
    justify-content: center;
    width: 5.1875rem;
  }
}
