@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin default {
  :global(.going) {
    .formPanel {
      border-radius: 1rem;
    }

    .header {
      font-weight: 500;
      text-transform: lowercase;
    }
  }

  :global(.desktop) {
    .formPanel .label {
      &.columns {
        flex-direction: row;

        .column {
          &.columnLeft {
            margin-bottom: 0;

            h4 {
              padding-left: 1rem;
            }

            .ticketIcon {
              background: $ticketsTransfer_ticketIcon no-repeat 0 0.15rem;
              background-size: contain;
              border: none;
              height: auto;
              margin-right: 0.25rem;
              padding: 1rem 1.5rem;
              width: auto;
            }
          }

          &.columnRight {
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      &.names .namesLabel {
        float: left;
        width: calc(50% - 0.625rem);

        &:nth-child(2) {
          margin-bottom: unset;
          margin-right: 0.625rem;
        }

        &:last-child {
          margin-left: 0.625rem;
        }
      }
    }
  }

  h2.pageTitle {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .header {
    font-size: 1.75rem;
    margin-bottom: 2rem;

    &:before {
      content: attr(data-number) '. ';
    }
  }

  .formPanel {
    border: none;
    box-shadow: $clientData_formPanelShadow;
    box-sizing: border-box;
    margin-bottom: 2rem;
    padding: 1.5rem 1.25rem;
    width: 100%;

    &.tickets {
      align-items: center;
    }

    .label {
      display: inline-block;
      font-size: 0.875rem;
      position: relative;
      width: 100%;

      &:not(:first-child) {
        margin-top: 2.5rem;
      }

      &.columns {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .text {
        display: block;
      }

      .column {
        align-items: center;
        text-align: right;
        width: 100%;

        &.columnLeft {
          align-items: center;
          display: flex;
          margin-bottom: 0.5rem;
          text-align: left;
        }

        h4 {
          display: inline;
          font-size: 1.5rem;
          font-weight: $default_fontBoldWeight;
          line-height: 1.5rem;
        }

        input[type='radio'] {
          opacity: 0;
          position: fixed;
          width: 0;
        }

        input[type='radio']:checked + label {
          background: $tickets_selectedBackground;
          border-color: $tickets_selectedBorder;
          color: $tickets_selectedFont;
        }

        .ticket {
          border: 0.0625rem solid $tickets_buttonBorder;
          border-radius: 0.25rem;
          color: $tickets_buttonFont;
          cursor: pointer;
          float: left;
          font-size: 1.125rem;
          font-weight: $default_fontBoldWeight;
          margin-right: 0.25rem;
          min-height: 1.25rem;
          min-width: 1.25rem;
          padding: 0.75rem;
          text-align: center;
          transition: background $default_animationTime,
            color $default_animationTime, border-color $default_animationTime;
        }
      }
    }

    .error {
      color: $tickets_errorColor;
      font-size: 0.75rem;
    }

    .description {
      font-size: 0.875rem;
      font-weight: 300;
      margin: 0.25rem 0;
    }
  }

  .submitButton {
    @include primaryButton();
    @include disabled(0.5);

    font-size: 1.125rem;
    font-weight: bold;
    margin-top: 1.75rem;
    width: 100%;
  }
  i {
    font-style: italic;
  }
}
