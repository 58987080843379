@import '@Styles/defs/colors.scss';

.bar {
  background: $progressBar_foreground;
  height: 100%;
  transition-property: width;
  transition-timing-function: linear;
  width: 0;

  &.loading {
    width: 75%;
  }

  &.finishing {
    width: 100%;
  }
}

.container {
  background: $progressBar_background;
  height: 0.1875rem;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;

  &.show {
    opacity: 1;
  }
}
