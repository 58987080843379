@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  &:global(.desktop) {
    .wideArticle {
      .description {
        font-size: 1.125rem;
      }

      .details {
        width: 100%;
      }
    }
  }

  .wideArticle {
    .description {
      font-size: 0.75rem;
      font-weight: 300;
    }

    .title {
      a {
        font-weight: 500;
      }
    }

    .details {
      width: 100%;
    }
  }
}

:global(.desktop) {
  .normal {
    flex-direction: row;
    height: 100%;

    .details {
      padding: 2rem 3rem;
      position: relative;

      .tags {
        margin-bottom: 1.5625rem;
        margin-top: 0;
      }
    }
  }

  .wideArticle {
    .title {
      font-size: calc(2rem + 1vw);
    }

    font-size: 1.5rem;
    font-weight: $default_fontWeight;

    .description {
      font-size: 1.5rem;
    }
  }
}

.wideArticle {
  border-radius: $default_borderRadiusMain;
  box-shadow: $wideArticle_articleShadow;
  margin: 1rem auto;
  padding: 0;
  width: 100%;

  .title {
    @include lineLimit(4, inherit);
    font-size: 1.25rem;
    font-weight: $default_fontBoldWeight;
  }

  .description {
    @include lineLimit(4, 1.5);
    line-height: 1.5;
    margin-bottom: 0;
  }

  .image {
    border-radius: $default_borderRadiusMain;
    display: flex;
    width: 100%;
  }

  .details {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    position: relative;
    width: 100%;

    .tags {
      margin: 0.75rem 0;
      width: 100%;
    }

    .date {
      font-size: 0.875rem;
    }
  }

  .value {
    margin-bottom: 0.75rem;
  }
}

.normal {
  display: flex;
  flex-direction: column;
}

:global(.white) {
  .wideArticle {
    background: $section_whiteElementBackground;
  }
}
