@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';

// going desktop styles
:global(.going) {
  &:global(.desktop) {
  }
}

// empik desktop styles
:global(.empik) {
  &:global(.desktop) {
    .placeEvents {
      @include center;
      border-radius: 2rem 0 2rem 0;
      box-sizing: border-box;
      margin-bottom: 4rem;
      padding-bottom: 5rem;

      h2.sectionTitle {
        width: 75%;
      }

      .eventsListWrapper {
        flex-flow: row nowrap;
        justify-content: space-between;

        ul.eventsList {
          margin-right: 1rem;
          width: 75%;
        }
      }
    }
  }
}

// shared desktop styles
:global(.desktop) {
  .placeEvents {
    h2.sectionTitle {
      font-size: 3rem;
      margin: 5rem 0 2rem;
    }
  }
}

// going base styles
:global(.going) {
}

// empik base styles
:global(.empik) {
}

// shared base styles
.placeEvents {
  max-width: 100%;
  width: 100%;

  h2.sectionTitle {
    font-size: 1.5rem;
    font-weight: $default_fontBoldWeight;
    margin: 3rem 0 1rem;
  }

  .eventsListWrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 100%;
    padding: 0;
    position: relative;
    width: 100%;

    ul.eventsList {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 100%;
    }
  }

  .noResults {
    color: $placeEvent_noResultsFont;
    font-size: 2.5rem;
    margin: 4rem auto 8rem;
    text-align: center;
    width: 100%;
  }

  button {
    margin: 2rem auto 4rem;
  }
}
