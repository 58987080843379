@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .timelineWrapper {
    flex-flow: row nowrap;
    margin: 8rem auto 4rem;
    padding: 0 4rem 0 1rem;
    transform: unset;

    .timelineElement {
      flex-flow: row nowrap;
      height: auto;
      width: 100%;

      .dataWrapper {
        left: 0;
        right: unset;
        top: -2rem;
        transform: rotate(-45deg);
        transform-origin: left;
      }

      .markerBar {
        height: 0.25rem;
        width: 100%;
      }
    }
  }
}

.timelineWrapper {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  list-style-type: none;
  margin: 4rem auto 0;
  transform: translateX(-3rem);

  * {
    box-sizing: border-box;
  }

  .timelineElement {
    align-items: center;
    display: flex;
    flex-flow: column;
    height: 3rem;
    position: relative;
    width: 2rem;

    .dataWrapper {
      left: 2.5rem;
      position: absolute;
      text-align: left;
      top: -0.5rem;

      h3 {
        font-size: 0.875rem;
        font-weight: $default_fontBoldWeight;
        white-space: nowrap;
      }

      p {
        font-size: 0.75rem;
        white-space: nowrap;
      }
    }

    .marker {
      background: $timeline_marker;
      border: 0.25rem solid $timeline_border;
      border-radius: 50%;
      height: 1rem;
      position: relative;
      transition: border-width 250ms linear;
      width: 1rem;

      &:hover {
        border-width: 0;
      }
    }

    .markerBar {
      background: $timeline_border;
      height: 100%;
      width: 0.25rem;
    }

    &:last-child {
      width: auto;

      .markerBar {
        display: none;
      }
    }
  }

  &.dark {
    .timelineElement {
      .marker {
        background: $timeline_markerDark;
        border-color: $timeline_borderDark;
      }

      .markerBar {
        background: $timeline_borderDark;
      }
    }
  }
}

:global(.dark) {
  .timelineElement {
    .marker {
      background: $timeline_markerDark;
      border-color: $timeline_borderDark;
    }

    .markerBar {
      background: $timeline_borderDark;
    }
  }
}
