@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .sectionTitle {
    font-size: 3rem;
    margin: 5rem 0 2rem;
  }

  .cardDeck {
    @include cardDeckDesktop;
    min-width: calc(100% + 1rem);
  }
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: $default_fontBoldWeight;
  margin: 3rem 0 1rem;
}

.cardDeck {
  @include cardDeckMobile;
  margin: 1rem 0 -1rem;
  width: 100%;
}

.slidableDeck {
  @include sliderContainer;
  @include sliderContainerMobile;
}
