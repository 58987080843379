@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin hangar {
  :global(.hangar) {
    .button {
      @include hangarDefaultButton;
      margin-top: 2.06rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
