@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.formWrapper {
  margin-bottom: 3rem;

  h2.pageTitle {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .label {
    display: inline-block;
    font-size: 0.875rem;
    position: relative;
    width: 100%;
  }

  .submitButton {
    @include primaryButton();
    @include disabled(0.5);

    font-size: 1.125rem;
    font-weight: bold;
    margin-top: 3.75rem;
    width: 100%;
  }

  .extraField {
    display: none;
  }
}

:global(.desktop) {
  .formWrapper {
    margin-bottom: 10rem;
    width: 40rem;

    .submitButton {
      width: 20rem;
    }
  }
}
