.spaces {
  display: flex;
  flex-direction: column;
  list-style: none;

  &:after {
    content: '';
    flex: auto;
  }
}
