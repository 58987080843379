@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  &:global(.desktop) {
    .wrapper {
      margin-left: -5rem;
      padding: 2rem 4rem;

      .title {
        border-bottom: 0.1rem $eventBox_titleBorder solid;
        font-size: 1.5rem;
        padding-bottom: 2rem;
      }
    }
  }

  .wrapper {
    border-radius: 0 0 1rem 1rem;
    margin: -1rem 0 0.75rem;
    padding: 1rem;

    .title {
      font-size: 1.125rem;
    }
  }
}

:global(.desktop) {
  .wrapper {
    padding: 3.125rem 5.625rem 6rem 5.625rem;

    .title {
      font-size: 1.25rem;
    }

    .authorsWrapper {
      .author {
        flex-basis: 50%;
        font-size: 1.3125rem;

        .avatar,
        .loadingAvatar {
          height: 4.6875rem;
          width: 4.6875rem;
        }
      }
    }
  }
}

.wrapper {
  background-color: $staticArticle_authorsBackground;
  border-radius: 1rem 0;
  margin-bottom: 17.125rem;
  padding: 0.6875rem 1.375rem;

  .title {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 1.5625rem;
  }

  .authorsWrapper {
    display: flex;
    flex-wrap: wrap;

    .author {
      align-items: center;
      color: $staticArticle_authorText;
      display: flex;
      flex-basis: 100%;
      font-size: 0.875rem;
      margin-bottom: 1.25rem;

      .avatar {
        background-size: cover;
        border-radius: 50%;
        height: 3.125rem;
        margin-right: 1.125rem;
        width: 3.125rem;
      }

      .loadingAvatar {
        @include fakeDivCommons();
        border-radius: 50%;
        height: 3.125rem;
        margin-right: 1.125rem;
        width: 3.125rem;
      }

      .loadingName {
        @include fakeDivCommons();
        height: 25px;
        width: 50%;
      }
    }
  }
}
