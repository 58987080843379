@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .buyCallBox {
      margin-bottom: unset;

      .wrapper {
        position: sticky;
        top: 1.5rem;

        .buy {
          border-radius: $default_borderRadiusMain;
          padding: 0;

          .buyMain {
            padding: 2rem 2rem 1.5rem;

            .date,
            .time {
              font-size: 1rem;
            }

            .place {
              h3 {
                font-size: 1rem;
              }

              a {
                font-size: 1rem;
              }
            }

            .tickets {
              margin-top: 6rem;
            }

            .tickets .price {
              font-size: 1.5rem;
            }

            .price {
              line-height: 1.2rem;
              padding-top: 0.28rem;
            }
          }
        }
      }
    }
  }

  .buyCallBox {
    height: 100%;
    margin-bottom: 2.625rem;

    .wrapper {
      border-radius: $default_borderRadiusMain;

      .imageContainer {
        .image {
          border-radius: $default_borderRadiusMain;
          overflow: hidden;
        }
      }

      .buy {
        border-radius: 0 0 1rem 1rem;
        box-shadow: none;
        font-size: inherit;
        padding: 1.875rem 1.25rem;

        .tickets {
          display: flex;
          justify-content: left;
          margin-top: 4rem;
        }

        h2.title {
          border-bottom: 0.1rem $eventBox_titleBorder solid;
          font-size: 2rem;
          font-weight: 500;
          line-height: 1.1875;
        }

        .buyMain {
          .calendarIcon,
          .clockIcon,
          .locationIcon,
          .ticketIcon {
            border: none;
            height: auto;
            margin-right: 0.25rem;
            padding: 1rem 0.9rem;
            width: auto;
          }

          .buttonSeeMore {
            text-transform: uppercase;
          }

          .calendarIcon {
            background: $eventBox_calendarIcon no-repeat 0 0;
          }

          .clockIcon {
            background: $eventBox_clockIcon no-repeat 0 0;
          }

          .locationIcon {
            background: $eventBox_locationIcon no-repeat 0 center;
          }

          .ticketIcon {
            background: $eventBox_ticketIcon no-repeat 0 center;
          }

          .date,
          .time {
            align-items: baseline;
            display: flex;
            margin-bottom: 0;
            position: relative;
            text-transform: capitalize;

            h3 {
              font-size: 1.125rem;
              font-weight: 500;
              line-height: 1.5;
              transform: translateY(-1rem);
            }
          }

          .place {
            align-items: center;
            display: flex;

            h3 {
              font-size: 0.75rem;
              font-weight: 500;
              line-height: 1.5;

              a {
                border-bottom: $default_borderUnderline
                  $event_locationOthersLinkFont;
                font-size: 0.75rem;
                line-height: 1.75;
                padding-bottom: 0.15rem;
              }
            }
          }

          .tickets .price {
            font-size: 1.5rem;
          }

          .price {
            line-height: 1.2rem;
            padding-top: 0.28rem;
          }

          .lastTickets {
            color: $eventBox_buyLastTicketsFont;
            font-size: 1rem;
            font-weight: 500;
          }
        }

        .button {
          background: $eventBox_buyButtonBackground;
          border: none;
          border-radius: 0.25rem;
          color: $eventBox_buyButtonFont;
          cursor: pointer;
          display: block;
          font-size: 1.25rem;
          font-weight: 600;
          margin: 1rem 0;
          padding: 1.25rem 0;
          text-align: center;
          text-transform: uppercase;
          width: 100%;
        }

        a.button:hover {
          background: $eventBox_buyButtonHoverBackground;
        }

        .disabledButton {
          background: $eventBox_buyButtonBackgroundDisabled;
          color: $eventBox_buyButtonColorDisabled;
          cursor: inherit;
        }

        .info {
          align-items: center;
          display: flex;
          font-size: 0.75rem;
        }
      }
    }
  }
}

:global(.empik) {
  &:global(.desktop) {
    .buyCallBox {
      margin-bottom: unset;

      .wrapper {
        position: sticky;
        top: 1.5rem;

        .buy {
          padding: 2rem 2rem 1.5rem;

          .date,
          .time,
          .tickets .price,
          .button {
            h3 {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  .buyCallBox {
    height: 100%;
    margin-bottom: 2.625rem;

    .wrapper {
      border-radius: 1rem 0 0 0;

      .imageContainer {
        .image {
          border-radius: 1rem 0 0 0;
          overflow: hidden;
        }
      }

      .buy {
        background: $eventBox_buyCallBoxBackground;
        border-radius: 0.25rem 0.25rem 0.5rem 0;
        box-shadow: $eventBox_buyCallBoxShadow;
        font-size: inherit;
        padding: 1.875rem 1.25rem;

        .date,
        .time {
          align-items: baseline;
          display: flex;
          margin-bottom: 1rem;
          position: relative;
          text-transform: capitalize;

          h3 {
            font-size: 1.25rem;
            font-weight: bold;
          }
        }

        .date {
          span {
            display: block;
          }
        }

        .place {
          align-items: center;
          display: flex;
          font-weight: bold;

          h3 {
            font-size: 1rem;
            line-height: 1.3;
          }

          a {
            font-size: 1rem;
            line-height: 1.3;
          }
        }

        .icon {
          color: $eventBox_buyIcon;
          font-size: 1.125rem;
          min-width: 2rem;
        }

        .tickets {
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;

          .price {
            font-size: 1.25rem;
          }

          .lastTickets {
            color: $eventBox_buyLastTicketsFont;
            font-size: 1rem;
            font-weight: bold;
          }
        }

        .button {
          background: $eventBox_buyButtonBackground;
          border: none;
          border-radius: 0.25rem;
          color: $eventBox_buyButtonFont;
          cursor: pointer;
          display: block;
          font-size: 1.25rem;
          font-weight: bold;
          margin: 1rem 0 0;
          padding: 1rem 0;
          text-align: center;
          text-transform: uppercase;
          width: 100%;
        }

        a.button:hover {
          background: $eventBox_buyButtonHoverBackground;
        }

        .disabledButton {
          background: $eventBox_buyButtonBackgroundDisabled;
          color: $eventBox_buyButtonColorDisabled;
          cursor: inherit;
        }

        .info {
          align-items: center;
          display: flex;
          font-size: 0.75rem;
        }
      }

      .promo {
        display: flex;
        margin-bottom: 1rem;
        width: 100%;

        .input {
          margin-right: 1rem;
          overflow: hidden;

          input {
            border: $lineSize solid $eventBox_buyPromoBorder;
            box-sizing: border-box;
            padding: 0.75rem 0.5rem;
            width: 100%;
          }
        }

        .confirm {
          button {
            background: $eventBox_buyConfirmBackground;
            border: $lineSize solid $eventBox_buyConfirmBorder;
            color: $eventBox_buyConfirmFont;
            padding: 0.75rem 0.5rem;
          }
        }
      }
    }
  }
}

.buyMain {
  .buttonSeeMore {
    border: 0.0625rem solid $eventBox_seeMoreButtonBorder;
    border-radius: 0.25rem;
    color: $eventBox_seeMoreButton;
    cursor: pointer;
    display: block;
    font-weight: 600;
    margin-top: 0.75rem;
    padding: 1.25rem 1.5rem;
    text-align: center;
  }

  .nearest {
    font-size: 1rem;
    font-weight: 500;
    line-height: 2.375rem;
    margin-bottom: 1.3125rem;
  }
}
