@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.authGate {
  align-items: center;
  background: $actionMessage_modalBackground;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  .close {
    @include closeModal;
  }

  .panel {
    background: $actionMessage_modalPanelBackground;
    border-radius: 0.25rem;
    margin: 0 1.5rem;
    max-width: calc(100% - 3rem);
    padding: 3rem 1rem;
    position: relative;

    &::before {
      background-size: cover;
      content: '';
      left: 1rem;
      position: absolute;
      z-index: 1;
    }

    &.empik::before {
      background-image: url('~@Styles/images/empik-apostrophe.svg');
      height: 5.5rem;
      top: -2rem;
      width: 3rem;
    }

    &.going::before {
      background-image: url('~@Styles/images/going-square.svg');
      height: 3rem;
      top: -1rem;
      width: 3rem;
    }

    .message {
      background: $actionMessage_modalPanelTitleBackground;
      box-sizing: border-box;
      color: $actionMessage_modalPanelFont;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .error {
      color: $actionMessage_modalErrorFont;
      display: block;
      font-size: 0.75rem;
      line-height: 1;
      margin-bottom: -1.25rem;
      margin-top: 0.5rem;
      text-align: center;
      width: 100%;
    }

    .buttonWrapper {
      display: flex;
      flex-flow: row-reverse wrap;
      justify-content: space-evenly;

      .button {
        @include primaryButton;
        font-weight: bold;
        margin: 3rem 0.5rem 0;
        min-width: 6rem;
        padding: 0.75rem 1rem;

        &.cancel {
          background: $actionMessage_modalCancelButtonBackground;
          border-color: $actionMessage_modalCancelButtonBackground;

          &:hover {
            background: $actionMessage_modalCancelButtonBackgroundHover;
            border-color: $actionMessage_modalCancelButtonBackgroundHover;
          }
        }
      }
    }
  }

  &.desktop {
    .panel {
      max-width: 50%;
      min-width: 15.625rem;
      padding: 3rem 4rem;

      .buttonWrapper {
        .button {
          padding: 0.875rem 3rem;
        }
      }
    }
  }
}
