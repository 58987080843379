@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .wrapper {
      .infoContainer {
        .infoColumn {
          .titleWrapper {
            .title {
              color: $reusable_infoBoxTextColor;
            }
          }
        }
      }
    }
  }

  .wrapper {
    .infoContainer {
      border-radius: $default_borderRadiusSmall;
      font-weight: 500;
      margin-bottom: 0.875rem;

      .iconColumn {
        background: $reusable_infoBoxBackgroundSecondary;
        border-radius: 0.5rem 0 0 0.5rem;
      }

      .infoColumn {
        .titleWrapper {
          .title {
            color: $reusable_infoBoxTextColorSecondary;
          }
        }
      }
    }
  }
}

:global(.desktop) {
  .wrapper {
    .sectionTitle {
      font-size: 1.75rem;
      margin: 5rem 0 2rem;
    }

    .infoContainer {
      .iconColumn {
        margin-right: 0;
        min-height: 4.5rem;
        width: 4.5rem;

        .icon {
          margin-top: calc(50% - 0.75rem);
        }
      }

      .infoColumn {
        flex-direction: row;
        font-size: 1rem;

        .titleWrapper {
          min-height: 4.5rem;
        }
      }
    }
  }
}
.wrapper {
  margin-bottom: 6.25rem;

  .sectionTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 3rem 0 1rem;
  }

  .infoContainer {
    align-items: center;
    border: 0.0625rem solid $reusable_infoBoxBorderColor;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 0;
    width: 100%;

    .iconColumn {
      margin-right: 0.5rem;
      min-height: 5.5rem;
      text-align: center;
      width: 4rem;

      .icon {
        color: $place_detailIcon;
        display: block;
        font-size: 1.5rem;
        margin-top: calc(50% - 0.25rem);
      }
    }

    .infoColumn {
      display: flex;
      flex-direction: column;
      font-size: 0.75rem;

      .titleWrapper {
        align-items: center;
        display: flex;
        margin-right: 0.75rem;

        .title {
          color: $reusable_infoBoxTextColorSecondary;
          display: flex;
          width: 9rem;
        }
      }

      .descriptionWrapper {
        align-items: center;
        display: flex;

        .description {
          display: flex;
          flex-wrap: wrap;
          font-weight: normal;
        }
      }
    }
  }
}
