@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .mapTitle {
      font-size: 3rem;
      margin: 5rem 0 2rem;
    }

    .map {
      background-color: $place_boxContentBackground;
      border-radius: $default_borderRadiusMain;
      padding: 1rem;
    }

    .map > div {
      border-radius: 0;
    }
  }

  .map > div {
    border-radius: $default_borderRadiusMain;
  }

  .mapTitle {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 3rem 0 1rem;
    padding: 0;
    width: 100%;
  }
}

.map > div {
  border-radius: 0 1rem 0 1rem;
}
