@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.desktop) {
  .buy {
    .fakeDateItem,
    .fakeTimeItem,
    .tickets .fakePrice {
      height: 1.875rem;
    }

    .fakeButton {
      height: 3.75rem;
    }
  }
}

.fakeImage {
  @include fakeDivCommons;

  border-radius: 1rem 0 0 0;
  display: block;
  width: 100%;
}

.buy {
  padding: 1.5rem;

  .fakeDate,
  .fakeTime,
  .fakePlace,
  .fakeInfo {
    display: block;
    margin-bottom: 1rem;
    width: 100%;
  }

  .fakeDateItem {
    @include fakeDivCommons;

    display: inline-block;
    height: 1.5rem;
    width: calc(90% - 2rem);
  }

  .fakeTimeItem {
    @include fakeDivCommons;

    display: inline-block;
    height: 1.5rem;
    width: 3.625rem;
  }

  .fakePlace,
  .fakeInfo {
    margin-bottom: 0;
  }

  .fakePlaceItem {
    @include fakeDivCommons;

    display: inline-block;
    height: 1.25rem;
    width: calc(100% - 2rem);
  }

  .fakeIcon {
    @include fakeDivCommons;

    display: inline-block;
    height: 1.125rem;
    margin-right: 0.875rem;
    width: 1.125rem;
  }

  .tickets {
    margin-top: 2rem;

    .fakePrice {
      @include fakeDivCommons;

      height: 1.5rem;
      width: 7.5rem;
    }
  }

  .fakeButton {
    @include fakeDivCommons;

    height: 3.5rem;
    margin: 1rem 0 0;
    width: 100%;
  }

  .fakeInfoItem {
    @include fakeDivCommons;

    display: inline-block;
    height: 1.25rem;
    width: calc(60% - 2rem);
  }
}
