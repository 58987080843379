@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .searchDropdownWrapper {
      .dropdown {
        font-size: 1.25rem;
      }
    }

    .searchOptionsWrapper {
      .options {
        input {
          font-size: 1.25rem;
          padding: 1rem 5.5rem 1rem 1rem;
          text-align: left;
        }
      }
    }
  }

  .optionsWrapper,
  .searchOptionsWrapper {
    .options {
      input {
        font-size: 1.25rem;
        padding: 0.75rem 4rem 1rem 0;
      }
    }
  }
}

:global(.desktop) {
  .dropdownWrapper {
    .dropdown {
      font-size: 3rem;
      margin-left: 1.25rem;
      padding-right: 2.75rem;
    }
  }

  .select {
    width: auto;
  }

  .searchDropdownWrapper {
    .dropdown {
      background-color: $search_dropDownBackground;
      box-shadow: $reusable_dropDownInputShadow;
      font-size: 1.75rem;
      padding: 0 1.75rem;
      text-align: left;

      &:focus {
        outline: none;
      }
    }
  }

  .searchOptionsWrapper {
    .options {
      input {
        font-size: 1.75rem;
      }
    }
  }

  .searchSelect {
    height: $search_nominalInputHeight;
    width: auto;
  }
}

.select,
.searchSelect {
  box-sizing: border-box;
  width: 100%;
}

.dropdownWrapper,
.searchDropdownWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .dropdown {
    appearance: none;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    color: $section_dropDownFont;
    flex: 1 1;
    font-size: 1.5rem;
    font-weight: $default_fontBolderWeight;
    height: 100%;
    margin-left: 0;
    padding: 0;
    text-align: center;
    width: 100%;

    &.searchDropdown {
      color: $search_dropDownFont;
      font-weight: $default_fontBoldWeight;
    }

    &:focus {
      outline: none;
    }
  }
}

.optionsWrapper,
.searchOptionsWrapper {
  display: flex;
  flex: 1;
  max-height: 20rem;
  overflow: auto;

  .options {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    input {
      appearance: none;
      background-color: $section_dropDownBackground;
      border: none;
      box-shadow: $reusable_dropDownInputShadow;
      box-sizing: border-box;
      flex: 1 1;
      font-size: 1.5rem;
      padding: 1rem 2rem 1rem 0;
      width: 100%;
    }
  }
}

:global(.dark) {
  .dropdownWrapper,
  .searchDropdownWrapper {
    .dropdown {
      color: $section_dropDownLightFont;

      &.searchDropdown {
        color: $search_dropDownFont;
      }
    }
  }
}

:global(.white) {
  .searchDropdownWrapper {
    .dropdown {
      background: $section_whiteElementBackground;
    }
  }
}
