@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

// going desktop styles
:global(.going) {
  &:global(.desktop) {
    .place {
      margin-bottom: 5rem;

      .header {
        h1 {
          font-size: 4rem;
          max-width: $leftColumnWidth;
        }
      }

      .twoColumns {
        .placeDetails {
          & > div:first-of-type {
            border-radius: $default_borderRadiusMain;
          }
        }

        .fixedBox {
          .placeEventsBox {
            .boxContent {
              border-radius: $default_borderRadiusMain;
            }
          }
        }
      }
    }
  }
}

// empik desktop styles
:global(.empik) {
  &:global(.desktop) {
    .place {
      .header {
        h1 {
          font-size: 4.5rem;
        }
      }

      .twoColumns {
        .placeDetails {
          & > div:first-of-type {
            border-radius: 1rem 0 1rem 0;
          }

          &:after {
            display: block;
          }
        }
      }

      .sectionWrapper {
        display: flex;
        justify-content: space-around;

        .list {
          width: 70%;
        }
      }

      .similarEventsSection {
        border-radius: 1rem 0 1rem 0;
        box-sizing: border-box;
        padding: 0.75rem 5rem;
        transform: none;
        width: 100%;
      }
    }
  }
}

// shared desktop styles
:global(.desktop) {
  .place {
    .twoColumns {
      flex-direction: row-reverse;
      justify-content: space-between;

      .fixedBox {
        display: flex;
        flex: 0 1 $rightColumnWidth;
        flex-flow: column nowrap;
        margin-left: 1rem;

        .placeEventsBox {
          position: sticky;
          top: 1.5rem;
        }
      }

      .placeDetails {
        display: flex;
        flex: 0 1 $leftColumnWidth;
        flex-flow: column nowrap;
        min-width: 50%;

        & > div:first-of-type {
          width: 100%;
        }
      }
    }

    .sectionTitle {
      font-size: 3rem;
      margin: 5rem 0 2rem;
    }
  }
}

// going base styles
:global(.going) {
  .place {
    .header {
      h1 {
        font-size: 1.5rem;
        line-height: 1;
      }
    }

    .twoColumns {
      .fixedBox {
        .placeEventsBox {
          .boxContent {
            border-radius: 0 0 1rem 1rem;

            .infoText {
              font-size: 1.5rem;
              font-weight: 500;
              margin-bottom: 1rem;
              text-transform: uppercase;
            }

            .shortName {
              display: block;
              font-size: 1rem;
              margin-bottom: 4rem;
              width: 100%;

              .locationIcon {
                background: $eventBox_locationIcon no-repeat 0 center;
                border: none;
                height: auto;
                margin-right: 0.25rem;
                padding: 1rem 0.9rem;
                width: auto;
              }

              .address {
                border-bottom: $default_borderUnderline
                  $place_similarPlacesBorder;
                font-size: 1rem;
                font-weight: $default_fontBoldWeight;
                padding-bottom: 0.15rem;
              }
            }

            .button {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

// empik base styles
:global(.empik) {
  .place {
    .header {
      h1 {
        font-size: 2rem;
      }
    }
  }

  .twoColumns {
    .fixedBox {
      .placeEventsBox {
        .boxContent {
          border-radius: 0 0 0.5rem 0;
        }
      }
    }
  }
}

// shared base styles
.place {
  margin-bottom: 3.125rem;
  width: 100%;

  .header {
    h1 {
      font-weight: $default_fontBoldWeight;
    }
  }

  .titleSpacer {
    background: transparent;
    height: 1.5rem;
    width: 100%;
  }

  .tagBar {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0 2rem 0;
  }

  .twoColumns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fixedBox {
      width: 100%;

      .placeEventsBox {
        margin-bottom: 2.625rem;

        .boxImageWrapper {
          border-radius: 1rem 1rem 0 0;
          box-shadow: $place_boxImageShadow;

          & > div {
            border-radius: 1rem;
          }
        }

        .boxContent {
          box-shadow: $place_boxContentShadow;
          display: flex;
          flex-direction: column;
          padding: $default_boxPadding;

          .shortName {
            align-items: baseline;
            display: flex;
            font-size: 0.85rem;
            font-weight: $default_fontBoldWeight;
            margin-bottom: 1rem;
            position: relative;
            text-transform: capitalize;

            .address {
              display: inline;
              font-size: 1.1875rem;
              margin-left: 0.5rem;
              padding: 0;
              width: 100%;
            }
          }

          .infoText {
            font-size: 1.25rem;
            font-weight: $default_fontBoldWeight;
            padding-bottom: 1rem;
          }

          .button {
            @include primaryButton;

            font-weight: bold;
            text-align: center;
          }
        }
      }
    }

    .placeDetails {
      width: 100%;
    }

    .fakeMap {
      background: $place_fakeMapBackground;
      border-radius: 0 1rem 0 1rem;
      height: 25rem;
    }
  }

  h3 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
    padding: 2rem 0;
  }

  .sectionTitle {
    font-size: 1.5rem;
    font-weight: $default_fontBoldWeight;
    margin: 3rem 0 1rem;
  }

  .similarEventsSection {
    background: $event_similarEventsSectionBackground;
    box-sizing: border-box;
    padding: 1.25rem;
    transform: translateX(-1.25rem);
    width: calc(100% + 2.5rem);
  }
}
