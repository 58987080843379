@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';

.cookieBar {
  align-items: center;
  background: $cookieBar_background;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 1rem;
  position: fixed;
  right: 0;
  z-index: 99;

  .accept {
    button {
      @include primaryButton();
    }
  }
}
