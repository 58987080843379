@import '@Styles/defs/colors.scss';

:global(.going) {
  .scroller {
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 2.75rem;
  }

  .title {
    &,
    a {
      font-weight: 500;
    }
  }

  .mobileWrapper {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

.desktopWrapper {
  margin-bottom: 0.75rem;
  max-height: 6.5rem;
  overflow: hidden;
}

.loader {
  background: $events_titleLoaderBackground;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 1.875rem;
  position: absolute;
  right: 0;
  top: 0;
}

.scroller {
  box-sizing: border-box;
  font-size: 1.875rem;
  line-height: 2rem;
  max-height: inherit;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 32px;
  width: calc(100% + 32px);
}

.title {
  display: block;
  font-size: inherit;
  font-weight: bold;
  hyphens: auto;
  line-height: inherit;
  margin: 0;
  padding: 0.125rem 0;
  word-break: break-word;
}

.mobileWrapper {
  font-size: 1.25rem;
  line-height: 1.375rem;
  margin-bottom: 1.5rem;
}
