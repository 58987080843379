@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin hangar {
  :global(.hangar) {
    font-family: $default_hangarFontMedium;

    .formWrapper {
      h2.pageTitle {
        display: block;
        font-size: 3.75rem;
        margin-bottom: 3.75rem;
      }

      .header {
        font-size: 1.875rem;
        margin-bottom: 1.81rem;

        &:before {
          display: none;
        }
      }

      .submitButton {
        @include hangarDefaultButton;
        margin-top: 2.06rem;
      }

      .extraField {
        display: block;
        font-size: 1.25rem;
      }

      .label {
        margin-bottom: 1.75rem;
      }
    }
  }
}
