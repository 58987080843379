@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/colors.scss';

:global(.desktop) {
  .fakeHeader {
    height: 3.5rem;
  }

  .fakeButton {
    height: 3.5rem;
  }

  .content {
    margin-top: 15rem;
  }
}

.content {
  margin-bottom: 5rem;
  margin-top: 14rem;
  position: relative;
  text-align: center;
  width: 100%;
}

.fakeHeader {
  @include fakeDivCommons();

  height: 3rem;
  margin: auto;
  width: 80%;
}

.fakeButton {
  @include fakeDivCommons();

  height: 2.75rem;
  margin: 3rem auto;
  max-width: 50%;
}
