@import '@Styles/defs/colors.scss';
@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/props.scss';

.wrapper {
  margin-top: 1rem;
}

.invoiceLegalText {
  font-size: 0.875rem;
  margin-top: 0.875rem;

  button {
    @include reset();

    color: $clientData_moreButtonLink;
    font-weight: $default_fontBoldWeight;
  }
}

.cities {
  display: flex;
  flex-wrap: wrap;

  .label {
    width: 28%;

    &:last-child {
      margin-left: 2%;
      width: 70%;
    }
  }
}

.label {
  display: inline-block;
  font-size: 0.875rem;
  position: relative;
  width: 100%;
}

.checkbox {
  display: flex;

  label {
    font-size: 0.875rem;
  }
}
