@import '@Styles/defs/colors.scss';

.root {
  .mainButton {
    color: $following_button_color;
    margin-top: 1rem;
  }
}

.iconMargin {
  margin-right: 0.5rem;
}

.dialog {
  .content {
    padding: 2rem;

    .header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .align {
        align-items: inherit;
        display: inherit;
      }

      .icon {
        margin-right: 0.5rem;
      }
    }

    .form {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      margin: 2rem 0 1rem;

      .input {
        flex: 3;
      }

      .submitButton {
        flex: 1;
      }
    }
  }

  .footer {
    background-color: $following_dialog_bg;
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem 1rem 0 1rem;
    gap: 1rem;

    .imageContainer {
      display: flex;
      flex: 1;

      img {
        width: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }

    .captionContainer {
      align-items: center;
      justify-content: center;
      display: flex;
      flex: 2;

      p {
        font-size: 0.875rem;
      }
    }
  }

  &.mobile {
    .content {
      .form {
        flex-flow: column;

        .input {
          flex: 1;
        }

        .submitButton {
          flex: 1;
        }
      }
    }

    .footer {
      flex-flow: column-reverse;
      padding: 2rem 2rem 0 2rem;

      .imageContainer {
        max-height: 9rem;

        img {
          object-position: bottom right;
        }
      }
    }
  }
}
