@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

.artistTile {
  @include cardDesktop;
  margin-right: 1.5rem;
  max-width: 6.625rem;
  min-width: 6.625rem;
  text-align: center;
  user-select: none;

  .avatarContainer {
    appearance: none;
    background-color: transparent;
    border-radius: 50%;
    height: 6.625rem;
    overflow: hidden;
    position: relative;
    width: 100%;

    .avatar {
      border-radius: 50%; /* for Safari */
      height: 100%;
      object-fit: cover;
      object-position: center center;
      user-select: none;
      width: 100%;
    }

    &.loading {
      background-color: $reusable_imageBackground;
    }
  }

  .artistName {
    color: $artistTile_textColor;
    margin: 1rem auto 0;

    &.dark {
      color: $artistTile_textColorDark;
    }
  }
}

:global(.dark) {
  .artistTile {
    .artistName {
      color: $artistTile_textColorDark;
    }
  }
}
