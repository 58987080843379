@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .triangleWrapper {
    .square {
      bottom: -2.25rem;
    }
  }
}

.triangleWrapper {
  height: 10.875rem;
  position: relative;

  .triangle {
    height: inherit;
    overflow: hidden;
    position: relative;

    .shadow {
      background: $eventBox_triangleShadowBackground;
      border-radius: 0.5rem;
      bottom: 100%;
      box-shadow: $eventBox_triangleShadowVertical;
      height: 200%;
      position: absolute;
      right: 0;
      transform: rotate(-25deg) translateY(-0.0625rem);
      transform-origin: right bottom;
      width: 200%;

      &.light {
        background: $eventBox_triangleShadowBackgroundLight;
      }
    }
  }

  .fakeShadow {
    box-shadow: $eventBox_triangleShadowHorizontal;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1rem;
    z-index: -1;
  }

  .square {
    background: $eventBox_triangleSquareBackground;
    bottom: -1rem;
    height: 3.75rem;
    left: 0;
    position: absolute;
    width: 3.75rem;

    &:before {
      background-image: url('~@Styles/images/empik-apostrophe-white.svg');
      background-position: center center;
      background-repeat: no-repeat;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

.withMarginBottom {
  margin-bottom: 3rem;
}
