@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

$buyForm_shadow: none;
$default_darkViolet: #9f5aff;

:global(.desktop) {
  .clientData {
    .formPanel {
      padding: 2.375rem 1.875rem;

      .names {
        .namesLabel {
          width: calc(50% - 0.625rem);

          &:nth-child(2) {
            margin-bottom: unset;
            margin-right: 0.625rem;
          }

          &:last-child {
            margin-left: 0.625rem;
          }
        }
      }
    }
  }
}

.clientData {
  @include disabledClass();

  margin-top: 3.75rem;
  width: 100%;

  .header {
    font-size: 1.75rem;
    margin-bottom: 2rem;

    &:before {
      content: attr(data-number) '. ';
    }
  }

  .formPanel {
    background: $default_white;
    border: none;
    box-shadow: $buyForm_shadow;
    box-sizing: border-box;
    padding: 1.75rem 1.25rem;
    width: 100%;

    .label {
      display: inline-block;
      font-size: 0.875rem;
      position: relative;
      width: 100%;
    }
  }
}
