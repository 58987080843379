@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  .carousel {
    border-radius: 0.5rem;
  }
}

.carousel {
  background: transparent;
  overflow: hidden;
}

.carouselSlidesWrapper {
  background-color: $reusable_imageBackground;
  display: flex;
  flex-flow: row nowrap;
  height: calc(
    #{$carousel_desktopHeightRatio} / #{$carousel_desktopWidthRatio} * 100%
  );
  max-height: calc(
    #{$carousel_desktopHeightRatio} / #{$carousel_desktopWidthRatio} * 100%
  );
  max-width: $default_desktopWidth;
  min-height: calc(
    #{$carousel_desktopHeightRatio} / #{$carousel_desktopWidthRatio} * 100%
  );
  min-width: 100%;
  overflow-x: hidden;
  position: relative;

  &.mobile {
    height: calc(
      #{$carousel_mobileHeightRatio} / #{$carousel_mobileWidthRatio} * 100%
    );
  }

  &.loading {
    animation: backgroundBlinking 0.8s infinite;
    animation-timing-function: ease-in-out;
  }
}
