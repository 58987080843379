@import '@Styles/defs/mixins.scss';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.going) {
  &:global(.desktop) {
    .borderContainer {
      border: none;
      border-bottom: $default_solidBorder $ticketsBox_Border;
      border-radius: 0;
      margin: 0;

      &:last-child {
        border-bottom: none;
      }

      .singleTicketContainer {
        grid-template-columns: 1fr 4fr 6fr 1fr 1fr;
        padding: 1.5rem 0;

        &.withoutDetails {
          grid-template-columns: 1fr 6fr 6fr 1fr;
        }

        .ticketIcon {
          font-size: 1.75rem;
        }

        .ticketKind {
          font-size: 1.75rem;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .singleTicketContainerPrice {
          .priceContainer {
            .priceTextContainer {
              .priceText {
                font-size: 1.5rem;
              }
              .price {
                font-size: 2.875rem;
                white-space: nowrap;
              }
              .currency {
                font-size: 1.5rem;
              }
            }
          }

          .ticketsInformationText {
            font-size: 1rem;
            font-weight: 500;
          }
        }

        .button {
          font-size: 1.125rem;
          font-weight: 500;
          margin-left: 0;
          padding: 1rem;
          width: auto;
        }
      }

      .descriptionContainer {
        color: $ticketsBox_defaultText;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 1.5;
        padding: 0 2rem 1rem;

        p {
          margin-bottom: 2rem;
        }

        h3 {
          display: none;
        }
      }
    }
  }

  .borderContainer {
    border: none;
    border-radius: $default_borderRadiusMain;
    margin-bottom: 2rem;

    .singleTicketContainer {
      border: none;
      border-radius: $default_borderRadiusTop;
    }

    .detailsTitle {
      border-radius: 0 0 1rem 1rem;
    }

    .borderRadius {
      border-radius: 0;
    }

    .descriptionContainer {
      border-radius: 0 0 1rem 1rem;
      padding-top: 1rem;
    }
  }

  .dateContainer {
    border-radius: 0.5rem;
  }
}

:global(.desktop) {
  .borderContainer {
    border: solid 0.063rem $ticketsBox_Border;
  }

  .singleTicketContainer {
    align-items: center;
    border: 0.0625rem solid $ticketsBox_Border;
    box-shadow: $default_shadow;
    display: grid;
    font-size: 1.75rem;
    grid-column-gap: 0.4rem;
    grid-template-columns: 1fr 4fr 5fr 3fr 2fr;
    padding: 1rem 1.6rem;

    &.withoutDetails {
      grid-template-columns: 1fr 3fr 5fr 3fr;
    }

    .ticketKind {
      font-size: 1.375rem;
      margin-left: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .placeName {
        font-size: 1rem;
        margin-top: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .singleTicketContainerPrice {
      justify-content: flex-end;
      text-align: center;
    }

    .ticketsInformationText {
      font-size: 1rem;
      order: 1;
    }

    .price {
      font-size: 2rem;
    }
  }

  .priceText {
    margin-right: 0.3rem;
  }

  .priceTextContainer {
    font-size: 1.2rem;
  }

  .ticketIcon {
    color: $ticketsBox_iconColor;
    display: inherit;
    font-size: 1.25rem;
  }

  .dateContainer {
    border: $ticketsBox_dateContainerBorder;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.875rem;
    text-align: center;

    .day {
      font-size: 1.5rem;
    }

    .month {
      font-size: 0.75rem;
    }
  }

  .priceContainer {
    order: 2;
    width: 55%;
  }

  .button {
    margin-left: 0.5rem;
    padding: 0.5rem 0.875rem;
    width: 8.125rem;
  }

  .detailsTitle {
    display: none;
  }

  .mobileDisplayNone {
    display: inherit;
    margin-left: 1rem;
  }
}

.button {
  @include primaryButton();
  display: inline-block;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.borderContainer {
  border-radius: 0.1875rem;
  margin: 1rem 0;
}

.borderContainer:last-of-type {
  margin-bottom: 0;
}

.detailsTitle {
  align-items: center;
  background-color: $ticketsBox_ButtonText;
  border-top: solid 0.063rem $ticketsBox_Border;
  color: $ticketsBox_defaultText;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  justify-content: center;
  padding: 0.875rem;
  padding-top: 0.5rem;
}

.descriptionContainer {
  background-color: $ticketsBox_ButtonText;
  font-size: 1rem;
  font-weight: 100;
  padding: 0 1rem 1rem 1rem;
}

.spaceUpRectangleSquareContainer {
  background-color: $ticketsBox_BackgroundMain;
  border-radius: 0 0 1rem 0;
  height: 2rem;
  top: 0;
  width: 100%;
}

.singleTicketContainer {
  background-color: $ticketsBox_ButtonText;
  font-size: 1rem;
  padding: 1.25rem;

  .ticketKind {
    font-size: 1.125rem;

    .placeName {
      color: $ticketsBox_placeName;
      font-size: 0.75rem;
      margin-top: 0.625rem;
    }
  }
}

.singleTicketContainerPrice {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  margin: 0.875rem 0 1.25rem;

  .priceText {
    color: $ticketsBox_defaultText;
    font-weight: normal;
  }

  .ticketsInformationText {
    color: $ticketsBox_ButtonBackground;
  }
}

.dateContainer {
  display: none;
}

.mobileDisplayNone {
  display: none;
}

.imageContainer {
  padding-top: 1rem;
  width: 100%;
}

.disabledButton {
  background: $eventBox_buyButtonBackgroundDisabled;
  border: none;
  color: $eventBox_buyButtonColorDisabled;
  cursor: inherit;
  &:hover {
    background: $eventBox_buyButtonBackgroundDisabled;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}
