@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

// going desktop styles
:global(.going),
:global(.sbm) {
  &:global(.desktop) {
    .buyForm {
      .twoColumns {
        .fixedBox {
          padding-top: 4.0625rem;
        }
      }

      .buyWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 3.75rem;
      }
    }
  }
}

// shared desktop styles
:global(.desktop) {
  .buyForm {
    .twoColumns {
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 5.625rem 0 5rem;

      .fixedBox {
        display: flex;
        flex: 0 1 $rightColumnWidth;
        flex-flow: column nowrap;
        margin-left: 2rem;
      }

      .form {
        display: flex;
        flex: 0 1 $leftColumnWidth;
        flex-flow: column nowrap;
        min-width: 50%;

        .buttonsWrapper {
          flex-direction: row-reverse;
          justify-content: space-between;

          .buttonWrapper {
            width: 47.5%;

            &.fullWidth {
              width: 100%;
            }
          }
        }
      }
    }

    .buyWrapper {
      margin-top: 3.75rem;
    }
  }
}

// going base styles
:global(.going),
:global(.sbm) {
  .buyForm {
    .twoColumns {
      .form {
        .mobileSummary {
          .header {
            color: $buyForm_headerFont;
            font-size: 1.75rem;
            font-weight: 500;
            padding-bottom: 2rem;
            text-transform: lowercase;
          }
        }
      }
    }

    .buyWrapper {
      margin-top: 0;
    }
  }
}

// empik base styles
:global(.empik) {
  .buyForm {
    .buyWrapper {
      margin-top: 1.5rem;
    }
  }
}

// shared base styles
.buyForm {
  padding: 0;

  .twoColumns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.75rem 0 5rem;

    .form {
      width: 100%;

      .buttonsWrapper {
        display: flex;
        flex-direction: column;

        .buttonWrapper {
          width: 100%;

          &.selectedPool {
            height: auto;
          }

          &.noSelectedPool {
            height: 0;
          }

          .basketButton {
            background-color: $basket_button;
            border-color: $basket_button;
            color: $basket_buttonText;

            &:hover {
              background-color: $basket_button;
            }
          }
        }
      }

      form {
        > div {
          .onsiteAdditionalFields {
            background-color: $default_white;
            box-shadow: $default_shadowSmallLight;
            margin-top: 2rem;
            padding: 2.375rem 1.875rem;
          }
        }
      }

      .mobileSummary {
        margin-top: 3.75rem;

        .header {
          font-size: 1.75rem;
          font-weight: 700;
          padding-bottom: 2rem;
        }
      }
    }

    .fixedBox {
      padding-bottom: 10rem;
      padding-top: 4.125rem;
      width: 100%;
    }
  }

  .buyButton {
    @include primaryButton();
    @include disabled(0.5);

    font-size: 1.25rem;
    font-weight: 500;
    padding: 1.25rem 0;
    transition: $default_animationTime;
    width: 100%;

    &.selectedPool {
      border-width: 0.0625rem;
      height: auto;
      margin-bottom: 1.375rem;
    }

    &.noSelectedPool {
      border-width: 0;
      height: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .buyWrapper {
    flex-direction: column;

    .errorContainer {
      background-color: $default_sundown;
      color: $default_red;
      font-size: 0.875rem;
      margin-bottom: 1.5rem;
      padding: 1rem;
      text-align: center;
    }
  }
}
