@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

:global(.desktop) {
  .readMoreContainer {
    padding-top: unset;

    .readMoreLink {
      width: unset;
    }
  }
}

.readMoreContainer {
  display: block;
  margin: 1rem auto;

  .readMoreLink {
    border-bottom: $default_borderUnderline $reusable_borderUnderlineColor;
    box-sizing: border-box;
    color: $reusable_readMoreColor;
    font-size: 0.875rem;
    font-weight: bold;
    height: 1.5rem;
    padding-bottom: 0.15rem;
    text-align: center;
    width: 100%;

    &:hover {
      border: hidden;
    }
  }
}

:global(.dark) {
  .readMoreLink {
    color: $reusable_readMoreColorDarkBg;
  }
}
