@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  .formPanel {
    border-radius: 1rem;
  }

  .header {
    text-transform: lowercase;
  }
}

.header {
  font-size: 1.75rem;
  font-weight: $default_fontBoldWeight;
  margin-bottom: 2rem;

  &:before {
    content: attr(data-number) '. ';
  }
}

.formPanel {
  border: none;
  box-shadow: $clientData_formPanelShadow;
  box-sizing: border-box;
  margin-bottom: 2rem;
  padding: 1.75rem 1.25rem;
  width: 100%;

  .checkbox {
    @include checkbox;
    margin-bottom: 1.5rem;

    a {
      font-weight: $default_fontBoldWeight;
    }

    .selector {
      flex: 0 0 1.5rem;
    }

    .error {
      bottom: -1.5rem;
      color: $formField_errorFont;
      font-size: 0.75rem;
      left: 0;
      position: absolute;
    }
  }

  .description {
    font-size: 0.875rem;
    font-weight: $default_fontWeight;
    margin: 0.5rem 0;

    &.smallText {
      font-size: 0.75rem;
    }
  }
}

.contactFormButton {
  border-bottom: solid 0.125rem $default_darkMain;
  color: $default_black;
  font-weight: $default_fontBoldWeight;
  text-align: left;
}

.submitButton {
  @include primaryButton();
  @include disabled(0.5);

  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1.75rem;
  width: 100%;
}
