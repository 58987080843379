@import '@Styles/defs/animations.scss';
@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';

@mixin reset {
  -webkit-appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

@mixin center {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $default_desktopWidth;
  padding: 0 1.25rem;
}

@mixin cardDeckMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
}

@mixin cardDeckDesktop {
  flex-flow: row wrap;
  justify-content: left;
}

@mixin cardMobile {
  background-clip: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  min-width: 0;
  position: relative;
  user-select: none;
  word-wrap: break-word;
}

@mixin cardDesktop {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  margin-right: 1rem;
}

@mixin cardHeader {
  margin: 0;
  padding: 0;
}

@mixin cardBody {
  flex: 1 1 auto;
  padding: $card_contentPadding;
}

@mixin cardFooter {
  padding: 0 1.5rem 1.5rem;
}

@mixin responsivCard($minWidth, $coverHeight, $title, $subtitle, $text) {
  min-width: $minWidth;

  .cardHeader {
    .cover {
      height: $coverHeight;
    }
  }

  .cardBody {
    h2.cardTitle {
      font-size: $title;
    }

    .cardData {
      h3 {
        font-size: $subtitle;
      }

      h4 {
        font-size: $subtitle;
      }

      p {
        font-size: $text;
      }
    }
  }
}

@mixin chevronDesktop {
  border-style: solid;
  border-width: 0.25rem 0.25rem 0 0;
  content: '';
  display: inline-block;
  float: right;
  height: 0.25rem;
  position: relative;
  top: 0.5rem;
  transition: $chevron_animation;
  vertical-align: top;
  width: 0.25rem;
}

@mixin chevronMobile {
  border-style: solid;
  border-width: 0.2rem 0.2rem 0 0;
  content: '';
  display: inline-block;
  float: right;
  height: 0.2rem;
  position: relative;
  top: 0.2rem;
  transition: $chevron_animation;
  vertical-align: top;
  width: 0.2rem;
}

@mixin chevronArrow {
  box-sizing: border-box;
  display: block;
  height: 2rem;
  position: relative;
  width: 2rem;

  &::after {
    border-bottom: 0.675rem solid $chevron_color;
    border-right: 0.675rem solid $chevron_color;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 2rem;
    position: absolute;
    transform: rotate(-45deg);
    width: 2rem;
  }

  &.left {
    transform: rotateY(180deg);
  }
}

@mixin lineLimit($lines: 3, $height: 1.5) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-height: $height;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin topBottomMargins {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin fakeDivCommons {
  animation: backgroundBlinking 0.8s infinite;
  animation-timing-function: ease-in-out;
  background-color: $fake_loaderBackground;
  border-radius: 0.1875rem;
}

@mixin borderButton($color: $button_borderButtonMain) {
  background: none;
  border: $lineSize solid $color;
  border-radius: 0.25rem;
  color: $color;
  cursor: pointer;
  padding: 0.875rem 3rem;
}

@mixin primaryButton {
  background: $button_primaryButtonBackground;
  border: $lineSize solid $button_primaryButtonBorder;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: $button_primaryButtonFont;
  cursor: pointer;
  padding: 0.875rem 3rem;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }

  &:hover {
    background: $button_primaryButtonHoverBackground;
  }

  &:disabled {
    background-color: $button_background_disabled;
  }
}

@mixin disabledStyles($opacity: 0.6) {
  cursor: initial;
  opacity: $opacity;
  pointer-events: none;
}

@mixin disabledClass($opacity: 0.6) {
  transition: opacity $default_animationTime;

  &.disabled {
    @include disabledStyles($opacity);
  }
}

@mixin disabled($opacity: 0.6) {
  transition: opacity $default_animationTime;

  &:disabled {
    @include disabledStyles($opacity);
  }
}

@mixin closeModal {
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  height: 1rem;
  overflow: hidden;
  position: fixed;
  right: 2rem;
  text-indent: -9999999px;
  top: 1rem;
  width: 1rem;

  &:before,
  &:after {
    background: $modal_closeButtonBackground;
    bottom: 0;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    transform-origin: center;
    width: 1px;
  }

  &:after {
    transform: rotate(135deg);
  }
}

@mixin checkbox {
  display: flex;
  flex-direction: column;
  position: relative;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  input[type='checkbox'] {
    left: #{$infinity}px;
    opacity: 0;
    position: absolute;

    &:checked + .selector {
      background: $checkbox_background;
      border-color: $checkbox_selectedBorder;
      position: relative;

      &:after {
        border: solid $checkbox_border;
        border-width: 0 0.1875rem 0.1875rem 0;
        content: '';
        font-size: 1rem;
        height: 0.625rem;
        left: 0.4375rem;
        position: absolute;
        top: 0.125rem;
        transform: rotate(45deg);
        width: 0.3125rem;
      }
    }
  }

  .selector {
    border: $default_solidBorder $checkbox_selectorBorder;
    box-sizing: border-box;
    display: block;
    height: 1.5rem;
    margin-right: 0.75rem;
    width: 1.5rem;
  }
}

@mixin sliderContainer {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  left: 0;
  list-style-type: none;
  margin: 0 auto;
  -ms-overflow-style: none;
  overflow-x: hidden;
  position: relative;
  scroll-snap-type: x mandatory;
  top: 0;
  width: 100%;

  &.nativeScroll {
    overflow-x: scroll;

    & ~ .arrow {
      visibility: hidden;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    scroll-snap-align: start;
    z-index: 0;

    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  }
}

@mixin sliderContainerMobile {
  overflow-x: scroll;
  scroll-padding: 1.5rem;
  scroll-snap-align: center;
  transform: translateX(-1.5rem);
  width: calc(100% + 3rem);

  li {
    &:first-child {
      margin-left: 1.5rem;
    }

    &:last-child {
      background-color: transparent;
      box-sizing: content-box;
      padding-right: 1.5rem;
    }
  }
}

@mixin arrowButton {
  background: $card_sliderArrows;
  border-radius: 0.25rem;
  box-shadow: $default_shadowSmallLight;
  box-sizing: border-box;
  cursor: pointer;
  height: 3.5rem;
  position: absolute;
  transition: $default_animationTime ease;
  width: 3.5rem;
  z-index: 999;

  &.left {
    box-shadow: $default_shadowSmallLightInverted;
    transform: rotate(180deg);
  }

  @include arrow;

  &:hover {
    background: $card_sliderArrowsHover;
  }
}

@mixin arrow {
  &::before {
    border-color: $card_sliderDarkArrows;
    border-style: solid;
    border-width: 0.2rem 0.2rem 0 0;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 0.6rem;
    left: 1.6rem;
    position: relative;
    top: 1.4rem;
    transform: rotate(45deg);
    width: 0.6rem;
  }

  &::after {
    border-color: $card_sliderDarkArrows;
    border-style: solid;
    border-width: 0 0 0.2rem 0;
    box-sizing: border-box;
    content: '';
    float: left;
    height: 0.8rem;
    left: 1.4rem;
    position: relative;
    top: 0.4rem;
    width: 0.8rem;
  }
}

@mixin gradientOverlay($direction: bottom, $start: 0%, $opacity: 0.75) {
  &::before {
    background-image: linear-gradient(
      to $direction,
      rgba(0, 0, 0, 0) $start,
      rgba(0, 0, 0, $opacity)
    );
    bottom: 0;
    content: '';
    left: 0;
    mix-blend-mode: overlay;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

@mixin hangarDefaultButton {
  background-image: $default_hangarButtonBackground;
  background-size: cover;
  border: $default_hangarBorder $default_hangarButtonBorder;
  border-radius: 0;
  color: $default_hangarButtonTextColor;
  text-transform: none;
}
