@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

:global(.going) {
  &:global(.desktop) {
    .selectionWrapper {
      .contentWrapper {
        max-width: 85%;
        padding: 0 10% 0 5%;

        .selectionHeader {
          margin: -3rem 0 0;
          max-width: 100%;
          padding: 2.5rem 4rem 2.25rem;
        }

        .selectionBody {
          max-width: 80%;
          padding: 0 15% 0 5%;
        }
      }
    }
  }

  .selectionWrapper {
    .contentWrapper {
      .selectionHeader {
        margin: -1rem -0.5rem 0 -1.5rem;
        padding: 1.5rem 1.5rem 1.25rem;
      }
    }

    .sectionHeader {
      text-transform: lowercase;
    }
  }
}

:global(.desktop) {
  .selectionWrapper {
    .contentWrapper {
      .selectionHeader {
        max-width: 80%;
        padding: 2.5rem 4rem;

        & > div {
          & > h2 {
            font-size: 4rem;
            font-weight: 600;
            margin: 0 auto;
            width: fit-content;
          }
        }
      }

      .selectionBody {
        margin: 0 auto;
        max-width: 80%;
      }
    }

    .sectionHeader {
      font-size: 3rem;
      margin: 5rem 0 2rem;
    }
  }
}

.selectionWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .imageWrapper {
    border-radius: $card_borderRadius;
    width: 100%;
  }

  .contentWrapper {
    margin: 0 auto;
    position: relative;
    width: 100%;

    .selectionHeader {
      background: $selection_titleBackground;
      margin: 0 auto;
      padding: 1.5rem;
      position: relative;

      & > div {
        margin: 0;

        & > h2 {
          font-size: 1.5rem;
          font-weight: 600;
          margin: 0 auto;
          width: fit-content;
        }
      }
    }

    .selectionBody {
      & > div {
        border-radius: 0 0 1rem 1rem;
      }
    }
  }

  .sectionHeader {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 3rem 0 1rem;
  }

  .elementsWrapper {
    display: flex;
    flex-direction: column;
  }
}
