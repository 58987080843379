@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin default {
  :global(.tablet) {
    .info {
      align-items: center;
    }
  }

  :global(.desktop),
  :global(.bigDesktop) {
    .button {
      font-size: 2rem;
    }

    .info {
      width: 50%;
    }
  }

  .wrapper {
    flex-grow: 1;
    width: 100%;

    .timeSlots {
      display: grid;
      grid-gap: 2.5rem;
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
      list-style: none;
      width: 100%;

      .button {
        background: $tickets_changeDateTimeSlotButtonBackground;
        border-color: $tickets_changeDateTimeSlotButtonBorder;
        color: $tickets_changeDateTimeSlotButtonColor;
        font-size: 1.125rem;
        height: 100%;
        margin: 0 !important;
        outline: none;
        padding: 1rem 0;
        width: 100%;

        &:hover {
          background-color: $tickets_changeDateTimeSlotButtonBackgroundHover;
          border-color: transparent;
          color: $tickets_changeDateTimeSlotButtonBackground;
          cursor: pointer;
        }

        &.selected {
          background: $tickets_changeDateTimeSlotButtonBackgroundHover;
          border: transparent;
          box-shadow: none;
          color: $tickets_changeDateTimeSlotButtonBackground;
          outline: none;
        }
      }
    }
  }
}
