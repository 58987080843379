@import '@Styles/defs/colors.scss';
@import '@Styles/defs/props.scss';
@import '@Styles/defs/mixins.scss';

@mixin default {
  :global(.going) {
    .header {
      font-weight: 500;
      text-transform: lowercase;
    }
  }

  .header {
    font-size: 1.75rem;
    margin-bottom: 2rem;

    &:before {
      content: attr(data-number) '. ';
    }
  }

  .noResults {
    color: $pools_noResultsFont;
    font-size: 2.5rem;
  }

  .pool {
    align-items: center;
    background: $pools_poolBackground;
    border-radius: borderRadius(0, 0, 0, 0);
    box-shadow: $pools_poolShadow;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
    padding: 1.25rem;
    width: 100%;

    &.disabled {
      @include disabledStyles;
    }
  }

  .submitButton {
    @include primaryButton();
    @include disabled(0.5);

    font-size: 1.125rem;
    font-weight: bold;
    margin-top: 3.75rem;
    text-align: center;
    width: 100%;
  }
  .clientDatepicker {
    display: none;
  }

  .dateInput {
    border: 0.0625rem solid #ebddff;
    display: flex;
    font-size: 1.25rem;
    font-size: 1rem;
    justify-content: space-between;
    margin-bottom: 1.2rem;
    padding: 0.81rem;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
    .text {
      margin-top: 0.31rem;
    }
    .icon {
      background-image: url('~@Styles/images/clients/calendar.svg');
      height: 2.25rem;
      width: 2.25rem;
    }
  }

  :global(.desktop),
  :global(.bigDesktop) {
    .dateInput {
      width: 23.25rem;
    }
  }
}
